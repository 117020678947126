import { colors } from '../../../../style/Constants';
import { DivComponent, PComponent } from '../../../../style/Global';

export const VIDEO_PLAYER_SIZE = { width: 476, height: 290 };

export const MediaOffsetSubcontainer = DivComponent({
  display: 'grid',
  gridTemplateColumns: `${VIDEO_PLAYER_SIZE.width}px 134px`,
  gridColumnGap: 16,
  paddingTop: 25
});

export const VideoControlContainer = DivComponent({
  position: 'relative',
  display: 'grid',
  gridTemplateRows: `${VIDEO_PLAYER_SIZE.height}px auto`
});

export const VideoPlayerDiv = DivComponent({
  ...VIDEO_PLAYER_SIZE,
  backgroundColor: 'black',
  position: 'relative',
  zIndex: 1
});

export const GreyLine = DivComponent({
  height: 2,
  backgroundColor: colors.superLightGreyText,
  marginTop: 15,
  marginBottom: 5
});

export const BigNumber = PComponent(({ lightGrey }) => ({
  color: lightGrey ? colors.greyText : colors.darkGreyText,
  fontSize: 25,
  fontWeight: 600,
  textAlign: 'right',
  lineHeight: 1
}));

export const BigNumberTitle = PComponent({
  color: colors.greyText,
  fontSize: 12,
  fontWeight: 600,
  paddingTop: 10,
  paddingBottom: 4
});
