export const uppercaseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const copyTextToClipboard = text => {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return clipboardData.setData('Text', text);
  }
  else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
    textarea.style.opacity = 0;
    document.body.appendChild(textarea);
    textarea.select();

    try {
      return document.execCommand('copy');  // Security exception may be thrown by some browsers.
    }
    catch (e) {
      console.warn('Copy to clipboard failed.', e);
      return false;
    }
    finally {
      document.body.removeChild(textarea);
    }
  }
};

export const limitStringLength = (string, length = 40) => {
  if (string.length < length) return string;
  return string.slice(0, length) + '...';
};

export const sortArrayAlphabeticallyByObjectProperty = (arr, property) => {
  return [ ...arr ].sort((a, b) => compareStrings(a[property], b[property]));
};

const compareStrings = (aString, bString) => {
  if (aString < bString) return -1;
  if (aString > bString) return 1;
  return 0;
};
