import React from 'react';
import { Svg } from '../../../../../style/Global';
import { colors } from '../../../../../style/Constants';

export const Crosshair = () => (
  <Svg height='20px' width='20px' viewBox='0 0 100 100'>
    <path d='M45 0 L45 45 L0 45 L0 55 L45 55 L45 100 L55 100 L55 55 L100 55 L100 45 L55 45 L55 0 L45 0 Z' fill={colors.white} stroke='black' strokeWidth='5' />
  </Svg>
);

export const TopLeftCorner = () => (
  <Svg height='10px' width='10px' viewBox='0 0 100 100' position='absolute' top={0} left={0}>
    <path d='M0 0 L0 100 L20 100 L20 20 L100 20 L100 0 L0 0 Z' fill={colors.white} stroke='black' strokeWidth='10' />
  </Svg>
);

export const TopRightCorner = () => (
  <Svg height='10px' width='10px' viewBox='0 0 100 100' position='absolute' top={0} right={0} transform='rotate(90)'>
    <path d='M0 0 L0 100 L20 100 L20 20 L100 20 L100 0 L0 0 Z' fill={colors.white} stroke='black' strokeWidth='10' />
  </Svg>
);

export const BottomRightCorner = () => (
  <Svg height='10px' width='10px' viewBox='0 0 100 100' position='absolute' bottom={0} right={0} transform='rotate(180)'>
    <path d='M0 0 L0 100 L20 100 L20 20 L100 20 L100 0 L0 0 Z' fill={colors.white} stroke='black' strokeWidth='10' />
  </Svg>
);

export const BottomLeftCorner = () => (
  <Svg height='10px' width='10px' viewBox='0 0 100 100' position='absolute' bottom={0} left={0} transform='rotate(270)'>
    <path d='M0 0 L0 100 L20 100 L20 20 L100 20 L100 0 L0 0 Z' fill={colors.white} stroke='black' strokeWidth='10' />
  </Svg>
);
