import difference from 'lodash.difference';

const QUERY_PARAMATERS = {};
export const getQueryParamaters = () => QUERY_PARAMATERS;

export const removeQueryParamaters = params => {
  if (Array.isArray(params)) return params.forEach(param => delete QUERY_PARAMATERS[param]);
  delete QUERY_PARAMATERS[params];
};

const queryHelper = queryString => {
  const searchParams = new URLSearchParams(queryString);
  const allowedParams = findAllowedQueryParams(findCurrentRoute(window.location.pathname));
  searchParams.forEach((v, k) => {
    if (!allowedParams.includes(k)) return;
    return QUERY_PARAMATERS[k] = v;
  })
};

const symbols = { 'id': '[a-z0-9]{24}' };

export const routeParamMap = {
  '/': {},
  '/videos': { params: ['videoPage', 'label', 'status'] },
  '/media-mapping': { params: ['mediaMappingPage', 'videoPlayer', 'mediaName'] },
  '/media-mapping/add': { parent: '/media-mapping' },
  '/media-mapping/<id>/preview': { params: ['viewMode', 'device'], parent: '/media-mapping' },
  '/media-mapping/<id>/overlay-settings': { parent: '/media-mapping' },
  '/media-mapping/<id>/media-offset': { parent: '/media-mapping' },
  '/media-mapping/<id>/embed-video': { parent: '/media-mapping' },
  '/media-mapping/<id>/delete': { parent: '/media-mapping' },
  '/media-mapping/<id>/edit': { parent: '/media-mapping' },
  '/settings': {},
  '/settings/account': {},
  '/settings/overlay-defaults': {},
  '/settings/keys': {},
  '/report': {},
  '/report/standard': {},
  '/report/bespoke': {}
}

const getFinalRoutePattern = routePattern => {
  for (let symbol in symbols) routePattern = routePattern.replace(/\<[a-zA-Z0-9]+\>/i, symbols[symbol]);
  return `^${routePattern}$`;
}

export const removeQueryParamsBasedOnRouteAvailableParams = () => {
  const currentRoute = findCurrentRoute(window.location.pathname, true);
  const currentParams = Object.keys(getQueryParamaters());
  if (!currentRoute) return removeQueryParamaters(currentParams);
  const availableParams = currentRoute.params;
  const unavailableParams = difference(currentParams, availableParams);
  unavailableParams.forEach(param => delete QUERY_PARAMATERS[param]);
}

export const findCurrentRoute = (url, useParent) => {
  for (let routePattern in routeParamMap) {
    const resolvedRoutePattern = getFinalRoutePattern(routePattern);
    if (!new RegExp(resolvedRoutePattern).test(url)) continue;
    if (useParent) return routeParamMap[routeParamMap[routePattern].parent];
    return routeParamMap[routePattern];
  }
}

const findAllowedQueryParams = (currentRoute, currentParams = []) => {
  if (!currentRoute) return;
  if (currentRoute.params) currentParams = [...currentParams, ...currentRoute.params];
  if (currentRoute.parent) return findAllowedQueryParams(findCurrentRoute(currentRoute.parent), currentParams);
  return currentParams;
}

export const getQueryString = (queryParams = {}, keysToRemove, traverseParents) => {
  const newParams = queryParams ? Object.keys(queryParams) : [];

  if(keysToRemove) removeQueryParamaters(keysToRemove);

  const url = window.location.pathname;
  const currentRoute = findCurrentRoute(url, traverseParents);
  const allowedParams = findAllowedQueryParams(currentRoute) || [];

  const keys = Object.keys(getQueryParamaters());

  const previousQueryList = keys.filter(key => !(queryParams[key] || !allowedParams.includes(key))).map(key => {
    return `${key}=${QUERY_PARAMATERS[key]}`;
  });
  const newAdditionsQueryList = newParams.filter(param => allowedParams.includes(param)).map(param => {
    return `${param}=${queryParams[param]}`;
  });

  if (!previousQueryList.length && !newAdditionsQueryList.length) return '';
  return `?${[...previousQueryList, ...newAdditionsQueryList].join('&')}`;
}

export default queryHelper;
