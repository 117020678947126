export const addToInProgressEditsCount = componentContext => {
  if(!componentContext.state._hasBeenEdited) {
    window.Tagflix.inProgressEditsCount++;
    componentContext.setState({ _hasBeenEdited: true });
  }
}

export const subtractFromInProgressEditsCount = () => {
  if(window.Tagflix.inProgressEditsCount <= 0) return;
  window.Tagflix.inProgressEditsCount--;
}
