import { colors } from '../../../style/Constants';
import { DivComponent, SpanComponent, ExtendComponentStyles } from '../../../style/Global';

export const RowDiv = DivComponent(({ isEven, padding, justifyContent = 'flex-start', flexDirection='row', recentlySaved, deleted, cursor, noFilterResults, uploadProgressPercent }) => ({
  width: '100%',
  display: 'flex',
  borderBottom: `1px solid ${colors.lightGreyBorder}`,
  padding,
  justifyContent,
  flexDirection,
  cursor,
  backgroundImage:
    uploadProgressPercent && uploadProgressPercent !== 100
    ? `linear-gradient(to right, ${colors.lightGreen}, ${colors.lightGreen} ${uploadProgressPercent}%, rgba(0,0,0,0) ${uploadProgressPercent}%, rgba(0,0,0,0))`
    : uploadProgressPercent === 100
      ? `linear-gradient(${colors.green}, ${colors.green})`
      : '',
  backgroundColor: determineRowColor({ recentlySaved, deleted, isEven, noFilterResults }),
  transition: 'background-color 500ms ease'
}));

export const UpperRow = DivComponent({
  width: '100%',
  display: 'flex',
  justifyContent: 'spaceBetween'
});

export const LowerRow = ExtendComponentStyles(UpperRow)({ paddingTop: 3 });

export const ScrollTrack = DivComponent(({ bottomBorder }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 16,
  height: '100%',
  borderBottom: bottomBorder ? `1px solid ${colors.lightGreyBorder}` : 'none',
  backgroundColor: '#f6f6f6',
  boxShadow: 'inset 1px 0 0 0 #e8e8e8, inset -1px 0 0 0 #e8e8e8',
  transition: 'none'
}));

export const StaticMessageContainer = DivComponent({
  minHeight: '200px',
  maxHeight: 'calc(100vh - 390px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: `1px solid ${colors.lightGreyBorder}`,
  backgroundColor: colors.superLightGrey
});

export const StaticMessage = DivComponent({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const ExtraPages = SpanComponent({
  display: 'flex',
  alignItems: 'center',
  height: '35px',
  color: colors.lightGreyText,
  padding: '0 7.5px'
});

const determineRowColor = ({ recentlySaved, deleted, isEven }) => {
  if (deleted) return colors.deletedPink;
  if (recentlySaved) return colors.green;
  if (isEven) return colors.white;
  return colors.superLightGrey;
};
