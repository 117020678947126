const syncMediaMockData = {
  // offset: 1000,
  fps: 30,
  // videoUrl: 'https://www.youtube.com/watch?v=XoiEkEuCWog',
  // videoPlayer: 'youtube',
  // videoUrl: 'https://www.facebook.com/tagflix/videos/726059504245620',
  // videoPlayer: 'facebook',
  sourceWidth: 1278,
  sourceHeight: 540,
  totalFrames: 7241,
  point: {
    w: 0.134,
    h: 0.31678486997635935,
    x: 0.214,
    y: 0.3333333333333333
  },
  product: {
    name: 'Bebe Rexha\'s chain earrings',
    imageUrl: 'https://cdn.tagflix.com/Image/tx3/node/point/257cd0e7-213d-4bb6-b9c0-93a0ff7e5140.png'
  },
  frame: {
    time: '7:0',
    frame: 210
  }
};

export default syncMediaMockData;
