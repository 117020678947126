import { observable, action } from 'mobx';
import API from '../../../util/ApiUtil';

const emptyMessage = { isError: false, text: '' };
class AccountStore {
  @observable message = emptyMessage;
  @action setMessage = message => this.message = message;
  @action clearMessage = () => this.message = emptyMessage;

  @action changePassword = async (newPassword, oldPassword) => {
    const response = await API.put('user/me', { data: { newPassword, oldPassword } });
    if (response.status === 200) return this.setMessage({ text: 'Password has been successfully changed.' });
    this.setMessage({
      isError: true,
      text: 'Current password was incorrect. Please try again.'
    });
  };
}

export default AccountStore;
