import React, { Component } from 'react';
import Auth from '../../components/shared/AuthCmp';
import { inject } from 'mobx-react';

@inject('app')
class SettingsReroute extends Component {
  componentDidMount() {
    this.props.app.setRedirectPathname('/settings/account');
  }
  render() { return <React.Fragment />; }
}

export default Auth(SettingsReroute);
