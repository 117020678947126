import { observable, action } from 'mobx';
import API from '../../util/ApiUtil';

class ReportStore {
  @observable reportRequestStatus = {
    'powerbi-standard': {
      error: false,
      errorMessage: ''
    },
    'powerbi-bespoke': {
      error: false,
      errorMesage: ''
    }
  }

  @action getPowerBiToken = async (publisher, forceRefresh = false) => {
    const { dashboards } = publisher;
    if (!dashboards.length) return;

    for (let i = 0; i < dashboards.length; i++) {
      const report = dashboards[i];
      if (report.token && !forceRefresh) return;
      const response = await API.post(
        'portal/plugin/powerbi/token',
        { data: { groupId: report.config.groupId, reportId: report.config.reportId } }
      );
      if (response.status === 200) {
        this.reportRequestStatus[report.name].error = false;
        return publisher.dashboards[i].token = response.data;
      };
      if (response.status === 401) {
        this.reportRequestStatus[report.name].error = true;
        this.reportRequestStatus[report.name].errorMessage = 'Sorry, report could not be loaded at this time. Unable to authenticate power bi token. Please try again later or contact your account manager if problem persists.';
      }
    }
  }
}

export default ReportStore;
