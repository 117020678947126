import styled from '@emotion/styled';
import { filterPropsForStyling } from './StyleUtil';

export const globalStylesLiveHere = 'This is just a reference to auto import these styles';

const htmlElements = [
  'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Header',
  'Div', 'P', 'I', 'Span', 'Img', 'Svg',
  'Form', 'Input', 'Ul', 'Footer', 'Label', 'Button', 'Li', 'Nav'
];
let toExport = { ExtendComponentStyles: component => styled(component) };
for(const element of htmlElements){
  toExport[`${element}`] = styled[element.toLowerCase()](props => ({ ...filterPropsForStyling(props) }));
  toExport[`${element}Component`] = config => {
    let callback = config;
    if(typeof config === 'object') callback = { ...filterPropsForStyling(config) };
    return styled[element.toLowerCase()](callback);
  };
};

module.exports = toExport;
