import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Icon from '../../../components/shared/FontAwesomeIconCmp';
import { colors } from '../../../style/Constants';
import { Red, SmallText, RedUnderline, Bold } from '../../../style/Typography';
import { Div, P, Span, DivComponent } from '../../../style/Global';
import { LightBeigeBox } from '../../../style/StyledCmps';
import { filterPropsForStyling } from '../../../style/StyleUtil';
import { RowDiv } from '../../../components/shared/table/Helpers';
import Helper from '../../../util/TagflixStorageUtil/helpers';
import Table from '../../../components/shared/table/TableCmp';
import LoadingCmp from '../LoadingCmp';
import { getQueryParamaters, getQueryString } from '../../../components/app/QueryParamsHelper';
const { determineCdnSrcUrl } = Helper;

export const mobileDevices = [{ deviceName: 'Galaxy S5', width: 360 }, { deviceName: 'Pixel 2', width: 411 }, { deviceName: 'iPhone 5', width: 320 }, { deviceName: 'iPhone 6/7/8', width: 375 }, { deviceName: 'iPhone 6/7/8 Plus', width: 414 }, { deviceName: 'iPhone X', width: 375 }];

export class PreviewVideoModal extends Component {

  state = {
    isDesktopPreviewSelected: true,
    isMobileDeviceDropdownOpen: false,
    previewVideoWidth: 620,
    currentMobileDevice: mobileDevices[0].deviceName
  };

  componentDidMount() {
    this.handleQueryParams(getQueryParamaters());
  }

  componentWillUpdate(prevProps, prevState) {
    if (prevState.isDesktopPreviewSelected === true && getQueryParamaters().viewMode === 'desktop') return;
    if (getQueryParamaters().device && prevState.currentMobileDevice !== getQueryParamaters().device) return this.handleQueryParams(getQueryParamaters());
    if (!prevState.isDesktopPreviewSelected && getQueryParamaters().viewMode === 'mobile') return;
    this.handleQueryParams(getQueryParamaters());
  };

  handleQueryParams = params => {
    if (params.viewMode === 'mobile') {
      this.setIsDesktopPreviewSelected(false);
      this.onMobileDeviceSelect(mobileDevices.find(device => device.deviceName === params.device) || mobileDevices[0]);
    }
    if (params.viewMode === 'desktop') {
      this.setIsDesktopPreviewSelected(true);
    }
    if (params.device) {
      if (this.state.isDesktopPreviewSelected) this.setIsDesktopPreviewSelected(false);
      this.onMobileDeviceSelect(mobileDevices.find(device => device.deviceName === params.device));
    }
  }

  setIsDesktopPreviewSelected = boolean => {
    this.setState({ isDesktopPreviewSelected: boolean, previewVideoWidth: boolean ? 620 : mobileDevices.find(obj => obj.deviceName === this.state.currentMobileDevice).width });
  };

  onMobileDeviceSelect = mobileDevice => {
    this.setState({ isMobileDeviceDropdownOpen: false, previewVideoWidth: mobileDevice.width, currentMobileDevice: mobileDevice.deviceName });
  };

  toggleMobileDeviceDropdownOpen = () => {
    this.setState({ isMobileDeviceDropdownOpen: !this.state.isMobileDeviceDropdownOpen });
  };

  render() {
    const { publisherId, closeModal, location } = this.props;
    const { videoId, id } = this.props.mapping;

    return (
      <Div position='relative' width={620}>
        <PreviewDeviceSelection location={location} setIsDesktopPreviewSelected={this.setIsDesktopPreviewSelected} isDesktopPreviewSelected={this.state.isDesktopPreviewSelected} />
        <PreviewViewport
          location={location}
          isDesktopPreviewSelected={this.state.isDesktopPreviewSelected}
          onMobileDeviceSelect={this.onMobileDeviceSelect}
          isMobileDeviceDropdownOpen={this.state.isMobileDeviceDropdownOpen}
          toggleMobileDeviceDropdownOpen={this.toggleMobileDeviceDropdownOpen}
          currentMobileDevice={this.state.currentMobileDevice}
          currentMobileDeviceWidth={this.state.previewVideoWidth}
        />
        {!this.state.isDesktopPreviewSelected && <MobileDeviceCursorWarning />}
        <PreviewVideo
          publisherId={publisherId}
          videoId={videoId}
          isDesktopPreviewSelected={this.state.isDesktopPreviewSelected}
          forceMobile={!this.state.isDesktopPreviewSelected && true}
          width={this.state.previewVideoWidth}
          currentMobileDevice={this.state.currentMobileDevice}
        />
        <SmallText marginTop={'10px'}>
          Recent changes you make may take up to <Bold>20 minutes</Bold> to reflect in preview above and is for demonstration purposes only.
          The implementation on your website may differ slightly due to <Link to={`/media-mapping/${id}/embed-video${getQueryString()}`}><RedUnderline onClick={closeModal}>embed settings</RedUnderline></Link> and your selected resolution.
        </SmallText>
      </Div>
    );
  };
};

export default withRouter(PreviewVideoModal);

export const PreviewViewport = ({ location, isDesktopPreviewSelected, onMobileDeviceSelect, isMobileDeviceDropdownOpen, toggleMobileDeviceDropdownOpen, currentMobileDevice, currentMobileDeviceWidth }) => {

  const determineSpanSpacing = () => {
    switch (currentMobileDevice) {
      case 'Galaxy S5': return 220;
      case 'Pixel 2': return 195;
      case 'iPhone 5': return 210;
      case 'iPhone 6/7/8': return 240;
      case 'iPhone 6/7/8 Plus': return 270;
      case 'iPhone X': return 210;
      default: return 245;
    };
  };

  if (isDesktopPreviewSelected) return <Div marginBottom='10px' ><p>The preview viewport is 620 pixels wide and 360 pixels tall.</p></Div>;
  return (
    <Div marginBottom='10px'>
      The preview viewport is <Div marginTop='-30px' display='inline-block' >
        <SelectMobileDeviceDropdown
          location={location}
          currentMobileDevice={currentMobileDevice}
          currentMobileDeviceWidth={currentMobileDeviceWidth}
          onMobileDeviceSelect={onMobileDeviceSelect}
          isMobileDeviceDropdownOpen={isMobileDeviceDropdownOpen}
          toggleMobileDeviceDropdownOpen={toggleMobileDeviceDropdownOpen}
        /></Div>
      <Span marginLeft={determineSpanSpacing()}>and 360 pixels tall.</Span>
    </Div>
  );
};

export const PreviewDeviceSelection = ({ isDesktopPreviewSelected }) => {
  if (isDesktopPreviewSelected) return (
    <Div marginBottom='10px'>
      <SelectableInputDevice
        width='90px'
        borderBottom={`3px solid ${colors.red}`}>
        <Red>
          <Icon name='fa-desktop' color={colors.red} /> Desktop
        </Red>
      </SelectableInputDevice>
      <Link to={getQueryString({ viewMode: 'mobile' })}>
        <SelectableInputDevice width={33} borderBottom={`3px solid ${colors.lightGrey}`} >
          <Icon name='fa-mobile-alt' color={colors.grey} />
        </SelectableInputDevice>
      </Link>
    </Div>
  );
  return (
    <Div marginBottom='10px'>
      <Link to={getQueryString({ viewMode: 'desktop' })}>
        <SelectableInputDevice width={33} borderBottom={`3px solid ${colors.lightGrey}`} >
          <Icon name='fa-desktop' color={colors.grey} />
        </SelectableInputDevice>
      </Link>
      <SelectableInputDevice width={68} borderBottom={`3px solid ${colors.red}`} >
        <Red><Icon name='fa-mobile' color={colors.red} /> Mobile</Red>
      </SelectableInputDevice>
    </Div>
  );
};

export const SelectMobileDeviceDropdown = ({ location, isMobileDeviceDropdownOpen, toggleMobileDeviceDropdownOpen, currentMobileDevice, currentMobileDeviceWidth }) => {

  const determineDropdownCaratCss = () => {
    if (isMobileDeviceDropdownOpen) return { position: 'absolute', right: 20 };
    return { margin: '0 0 0 8px' };
  };

  return (
    <SelectMobileDeviceContainer isMobileDeviceDropdownOpen={isMobileDeviceDropdownOpen} >
      <SelectMobileDeviceTitleDiv onClick={toggleMobileDeviceDropdownOpen}>
        <Div>
          {isMobileDeviceDropdownOpen ?
            <Bold><Span>{`${currentMobileDeviceWidth} pixels wide (${currentMobileDevice})`}</Span></Bold>
            :
            <RedUnderline>{`${currentMobileDeviceWidth} pixels wide (${currentMobileDevice})`}</RedUnderline>
          }
        </Div>
        <Icon {...determineDropdownCaratCss()} name='fa-caret-down' color={colors.red} lg />
      </SelectMobileDeviceTitleDiv>
      {isMobileDeviceDropdownOpen &&
        <MobileDeviceList location={location} toggleMobileDeviceDropdownOpen={toggleMobileDeviceDropdownOpen} />
      }
    </SelectMobileDeviceContainer>
  );
};

export const MobileDeviceList = ({ location, toggleMobileDeviceDropdownOpen }) => (
  <Div width='100%'>
    <Table
      contentHeight={180}
      tableRecords={mobileDevices}
      row={(mobileDevice, i) => <Row
        mobileDevice={mobileDevice} i={i} key={i} location={location}
        toggleMobileDeviceDropdownOpen={toggleMobileDeviceDropdownOpen}
      />}
    />
  </Div>
);

const Row = ({ toggleMobileDeviceDropdownOpen, i, mobileDevice }) => {
  return (
    <Link to={getQueryString({ device: mobileDevice.deviceName })} >
      <RowDiv
        border='solid green 2px'
        justifyContent='space-between'
        padding={10}
        cursor='pointer'
        key={i}
        onClick={toggleMobileDeviceDropdownOpen}
      >
        <P><Red>{mobileDevice.deviceName}</Red></P>
      </RowDiv>
    </Link>
  );
};

export const MobileDeviceCursorWarning = () => {
  return <LightBeigeBox margin={'5px 0 8px 0'}><Div display='flex' width='583px' margin='0 auto' ><Icon sm color={colors.greyText} margin='0 4.5px 0 0 ' name='fa-info-circle' /><SmallText>Mobile devices do not have mouse cursor hover events, so the preview below uses your mouse cursor clicks to simulate finger taps.</SmallText></Div></LightBeigeBox>;
};

export class PreviewVideo extends Component {

  state = { isLoading: true };

  componentDidMount() {
    this.timerHandle = setTimeout(() => this.setState({ isLoading: false }), 20000);
  }

  componentWillUnmount() {
    if (this.timerHandle) return clearInterval(this.timerHandle);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.width !== this.props.width) {
      const iframe = document.getElementById('preview-iframe');
      iframe.src = iframe.src;
      clearInterval(this.timerHandle);
      this.setState({ isLoading: true }, () => {
        return this.timerHandle = setTimeout(() => this.setState({ isLoading: false }), 20000);
      });
    }
  }

  render() {
    const { width, isDesktopPreviewSelected, videoId, publisherId } = this.props;

    return (
      <div style={{ position: 'relative', width, backgroundColor: colors.lightGrey, margin: '0 auto', height: '0', paddingBottom: '56.25%' }}>
        <Div
          display='flex'
          justifyContent='center'
          flexDirection='column'
          alignItems='center'
          margin='auto'
          position='absolute'
          backgroundColor='black'
          height='100%'
          width='100%'
          top={0}
          left={0}
        >
          <LoadingCmp
            isLoading={this.state.isLoading}
            title={this.state.isLoading ? 'Initializing Player...' : 'Sorry, cannot load your video at this time.'}
            message={this.state.isLoading ? 'Please wait.' : 'Please check your media mappings and player settings.'}
            textColor='white'
            spinnerColor='white'
          />
        </Div>
        <iframe
          id='preview-iframe'
          style={isDesktopPreviewSelected ? { position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' } : { position: 'absolute', top: '0', left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto', width, height: '100%' }}
          frameBorder='0'
          type='text/html'
          allowFullScreen={true}
          allow='autoplay'
          webkitallowfullscreen='true'
          mozallowfullscreen='true'
          src={isDesktopPreviewSelected ? `${determineCdnSrcUrl(publisherId)}?publisherVideoId=${videoId}` : `${determineCdnSrcUrl(publisherId)}?publisherVideoId=${videoId}&forceMobile=true`}>
        </iframe>
      </div>
    );
  }
}

export const SelectMobileDeviceContainer = DivComponent(({ isMobileDeviceDropdownOpen }) => ({
  display: 'inline-block',
  position: 'absolute',
  zIndex: 100,
  marginTop: '-22px',
  width: 300,
  backgroundColor: isMobileDeviceDropdownOpen ? colors.white : 'transparent',
  boxShadow: isMobileDeviceDropdownOpen ? '0.5px 0.5px 5px 0.5px rgba(52, 48, 77, 0.2)' : 'none'
}));

export const SelectMobileDeviceTitleDiv = DivComponent({
  display: 'flex',
  alignItems: 'start',
  minHeight: 40,
  paddingLeft: 8,
  paddingTop: 10,
  cursor: 'pointer'
});

export const SelectableInputDevice = DivComponent(props => ({
  display: 'inline-block',
  textAlign: 'center',
  marginRight: '5px',
  cursor: 'pointer',
  paddingBottom: '5px',
  ...filterPropsForStyling(props)
}));
