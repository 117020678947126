import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Redirect, withRouter } from 'react-router-dom';
import { getUserToken } from '../../util/PersistedStateUtil';

const AuthCmp = Page =>
  @inject('app')
  @observer
  class AuthClass extends Component {
    constructor(props) {
      super()
      if (!getUserToken()) {
        const { logoutUser, setRedirectPathname } = props.app;
        setRedirectPathname(`${props.location.pathname}${props.location.search}`);
        logoutUser();
      }
    }

    render() {
      return this.props.app.isLoggedIn ? <Page {...this.props} /> : <Redirect to='/' />;
    }
  };

export default Page => withRouter(AuthCmp(Page));
