import jwtDecode from 'jwt-decode';

export const userTokenKey = 'user-token';
const selectedPublisherKey = 'selected-publisher-id';
const cookiePolicyKey = 'cookie-policy-clicked';
const publisherCapabilitiesKey = 'publisher-capabilities';
const lastMenuOptionKey = 'last-menu-option';

export const getUserToken = () => {
  const token = getLocally(userTokenKey);
  if (!token || tokenIsExpired(token)) return;
  return token;
};
export const tokenIsExpired = token => {
  const exp = jwtDecode(token).exp;
  const now = Date.now() / 1000;
  return exp < now;
};
export const saveUserToken = userToken => storeLocally(userTokenKey, userToken);
export const removeSavedUserData = () => {
  clearLocally(userTokenKey);
  clearLocally(selectedPublisherKey);
};

export const savePublisherCapabilities = publisherCapabilities => storeLocally(publisherCapabilitiesKey, JSON.stringify(publisherCapabilities));
export const getPublisherCapabilities = () => JSON.parse(getLocally(publisherCapabilitiesKey));

export const saveLastMenuOption = pathname => storeLocally(lastMenuOptionKey, pathname, sessionStorage);
export const clearLastMenuOption = () => clearLocally(lastMenuOptionKey, sessionStorage);
export const getLastMenuOption = () => {
  const lastOption = getLocally(lastMenuOptionKey, sessionStorage);
  clearLastMenuOption();
  return lastOption;
}

export const getSelectedPublisherId = () => getLocally(selectedPublisherKey);
export const saveSelectedPublisherId = selectedPublisherId => storeLocally(selectedPublisherKey, selectedPublisherId);

export const userAcceptsCookiePolicy = () => storeLocally(cookiePolicyKey, true);
export const userHasAcceptedCookiePolicy = () => getLocally(cookiePolicyKey);

export const storeLocally = (key, value, storageEngine = localStorage) => {
  try {
    storageEngine.setItem(key, value);
  }
  catch(e) {
    console.error(`Could not set ${key} to localStorage`, e);
  };
};

export const clearLocally = (key, storageEngine = localStorage) => {
  try {
    storageEngine.removeItem(key);
  }
  catch(e) {
    console.error(`Could not clear ${key} from localStorage`, e);
  };
};

export const getLocally = (key, storageEngine = localStorage) => {
  try {
    return storageEngine.getItem(key);
  }
  catch(e) {
    console.error(`Could not get ${key} from localStorage`, e);
  };
};

// TODO: Remove this in 6 months time (2019-04-18)
if(!getPublisherCapabilities()){
  localStorage.clear();
  sessionStorage.clear();
};
