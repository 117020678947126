import { observable, action } from 'mobx';
import API, { errorMessageHelper } from '../../../../util/ApiUtil';

class BrightcoveStore {
  @observable isLoading = false;
  @observable isLinked = false;
  @observable hasCodeBeenEntered = false;

  @observable brightcovePlayerEmbedIsValid = false;
  @observable brightcovePlayerEmbedCode = null;
  @observable brightcovePlayerError = null;
  @observable brightcovePlayerName = null;
  @observable brightcovePlayerEmbedId = null;
  @observable brightcovePlayerAccountId = null;
  @observable brightcovePlayerId = null;
  @observable brightcovePlayerConfigIsValid = false;

  @observable brightcoveVideoEmbedId = null;
  @observable brightcoveVideoEmbedCode = null;
  @observable brightcoveVideoEmbedError = null;
  @observable brightcoveVideoEmbedIsValid = false;

  @action clearPlayerEmbedCode = () => {
    this.brightcovePlayerEmbedIsValid = false;
    this.brightcovePlayerEmbedCode = null;
    this.brightcovePlayerError = false;
    this.brightcovePlayerName = null;
    this.brightcovePlayerEmbedId = null;
    this.brightcovePlayerAccountId = null;
    this.brightcovePlayerId = null;
    this.brightcovePlayerConfigIsValid = false;
    this.hasCodeBeenEntered = false;
  };

  @action clearVideoEmbedCode = () => {
    this.brightcoveVideoEmbedId = null;
    this.brightcoveVideoEmbedCode = null;
    this.brightcoveVideoEmbedError = null;
    this.brightcoveVideoEmbedIsValid = false;
    this.hasCodeBeenEntered = false;
  };

  @action clearVideoEmbedCodeAndResetPlayerConfig = () => {
    this.clearVideoEmbedCode();
    this.brightcovePlayerConfigIsValid = false;
  };

  @action resetEmbedValues = () => {
    this.clearPlayerEmbedCode();
    this.clearVideoEmbedCode();
  };

  @action onPlayerEmbedAdd = async e => {
    const inputElement = e.currentTarget;
    const embedCode = e.target.value;
    const data = { playerEmbedCode: embedCode };
    this.isLoading = true;
    const response = await API.post('portal/plugin/brightcove/configuration', { data: { pubId: API.publisherId(), ...data } });

    if(response && response.status === 200) {
      this.brightcovePlayerEmbedCode = response.data;
      this.brightcovePlayerEmbedIsValid = true;
      this.hasCodeBeenEntered = false;
      this.isLoading = false;
      return;
    };

    inputElement.value = '';
    this.brightcovePlayerError = errorMessageHelper(response.error);
    this.brightcovePlayerEmbedIsValid = false;
    this.brightcovePlayerEmbedCode = null;
    this.hasCodeBeenEntered = true;
    this.isLoading = false;
  };

  @action onIframeEmbedAdd = async e => {
    const embedCode = e.target.value;
    const inputElement = e.currentTarget;
    this.isLoading = true;

    const response = await API.post('portal/plugin/brightcove/embed-code', { data: { pubId: API.publisherId(), code: embedCode } });

    if(response && response.status === 200) {
      const { videoId } = response.data;
      this.isLoading = false;
      this.brightcoveVideoEmbedCode = embedCode;
      this.brightcoveVideoEmbedId = videoId;
      this.brightcoveVideoEmbedIsValid = true;
      this.hasCodeBeenEntered = false;
      return;
    };
    inputElement.value = '';
    this.brightcoveVideoEmbedError = errorMessageHelper(response.error);
    this.brightcoveVideoEmbedIsValid = false;
    this.hasCodeBeenEntered = true;
    this.isLoading = false;
  };

  @action linkPlayer = async () => {
    this.isLoading = true;
    const response = await API.post('portal/plugin/brightcove/link-player', { data: { pubId: API.publisherId() } });

    if(response && response.status === 200) {
      const { playerId, accountId, embedId, playerName } = response.data;
      this.brightcovePlayerId = playerId;
      this.brightcovePlayerAccountId = accountId;
      this.brightcovePlayerEmbedId = embedId;
      this.brightcovePlayerName = playerName;
      this.isLoading = false;
      this.isLinked = true;
      return;
    };
    this.isLoading = false;
  };

  @action unlinkPlayer =  async () => {
    this.isLoading = true;
     const response = await API.post('portal/plugin/brightcove/unlink-player', { data: { pubId: API.publisherId() } });

    if(response && response.status === 200) {
      const { isLinked } = response.data;
      this.isLoading = false;
      this.isLinked = isLinked;
      return;
    };
    this.isLoading = false;
  };

  @action checkIfPlayerConfigurationIsValid = async () => {
    this.isLoading = true;
     const response = await API.post('portal/plugin/brightcove/configuration/check', { data: { pubId: API.publisherId() } });

    if(response && response.status === 200) {
      this.brightcovePlayerConfigIsValid = response.data.isValid;
      this.isLoading = false;
      return;
    };

    this.brightcovePlayerConfigIsValid = false;
    this.isLoading = false;
  };

};

export default BrightcoveStore;
