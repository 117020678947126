import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import qs from 'qs';
import { Div, H5, P } from '../../../style/Global';
import config from '../../../config/config';
import { Red } from '../../../style/Typography';
import { flexCenter, flexSpaceBetween } from '../../../style/Constants';
import { BorderedInput } from '../../../style/FormStyle';
import InfoBubble from '../../../components/shared/InfoBubbleCmp';
import CodeCopyableTextArea from '../../../components/shared/CodeCopyableTextAreaCmp';
import Auth from '../../../components/shared/AuthCmp';
import Helper from '../../../util/TagflixStorageUtil/helpers';
import ToggleCmp from '../../../components/shared/ToggleCmp';
const { determineCdnSrcUrl } = Helper;

@inject('mediaMappings', 'app')
@observer
class EmbedVideoModal extends Component {

  state = {
    startAtSecond: 0,
    autoPlay: false,
    muted: false,
    loop: false,
    maintainAspectRatio: true
  };

  toggleLoop = () => this.setState({ loop: !this.state.loop })

  toggleMaintainAspectRatio = () => {
    this.setState({ maintainAspectRatio: !this.state.maintainAspectRatio })
  };

  toggleAutoPlay = () => {
    if (this.state.autoPlay) return this.setState({ autoPlay: false });
    this.setState({ autoPlay: true, muted: true });
  }

  toggleMuted = () => {
    if (this.state.muted) return this.setState({ muted: false, autoPlay: false });
    this.setState({ muted: true });
  }

  setStartAtSecond = e => {
    const startAtSecond = e.target.value;
    if (isNaN(Number(startAtSecond))) return;
    this.setState({ startAtSecond });
  }

  embedCode = publisherVideoId => {
    let publisherId = this.props.app.publisher.id;
    let src = determineCdnSrcUrl(publisherId);
    const { maintainAspectRatio } = this.state;
    const iframeStyles = maintainAspectRatio ? maintainAspectRatioCode.iframeStyles : '';
    const queryStringParams = { publisherVideoId };
    for (let key in this.state) {
      const value = this.state[key];
      if (key === 'maintainAspectRatio') continue;
      if (value) queryStringParams[key] = value;
    };
    src+= `?${qs.stringify(queryStringParams)}`;
    const iframeTag =  `<iframe ${iframeStyles} frameborder="0" type="text/html" webkitallowfullscreen mozallowfullscreen allow="autoplay; fullscreen" src="${src}"></iframe>`;
    if (maintainAspectRatio) return [ maintainAspectRatioCode.openingTag, iframeTag, maintainAspectRatioCode.closingTag ].join('');
    return iframeTag;
  }

  render() {
    const { startAtSecond, autoPlay, muted, loop, maintainAspectRatio } = this.state;
    const publisherVideoId = this.props.mapping.videoId;
    return (
      <div>
        <P>Use the settings below to customise your Tagflix enabled video before copying the html generated code. Not all options available for all players. <Red cursor='pointer'><a href={config.documentationUrl.integration} target='_blank'>More information »</a></Red></P>
        <H5 paddingTop={20}>Initial starting point the video should play from</H5>
        <Div {...flexSpaceBetween} padding='10px 0 0 10px'>
          <BorderedInput value={startAtSecond} onChange={this.setStartAtSecond}/>
          <P paddingLeft={10}>seconds</P>
        </Div>
        <ToggleOption text='Auto play video on load?' enabled={autoPlay} toggle={this.toggleAutoPlay}>
          <InfoBubble height={68}>Auto play forces the video to start muted as mandated by browsers like Chrome.</InfoBubble>
        </ToggleOption>
        <ToggleOption text='Video volume muted on play?' enabled={muted} toggle={this.toggleMuted} />
        <ToggleOption text='Loop the video when reaching the end?' enabled={loop} toggle={this.toggleLoop} />
        <ToggleOption text='Maintain aspect ratio?' enabled={maintainAspectRatio} toggle={this.toggleMaintainAspectRatio}>
          <InfoBubble height={68}>Iframe will be wrapped in a 16:9 aspect ratio container. If your site already takes this into account, disable this option.</InfoBubble>
        </ToggleOption>
        <H5 paddingTop={35} paddingBottom={10}>HTML Embed Code</H5>
        <CodeCopyableTextArea width='400px' height='288px' margin='0 0 0 10px' padding='6px 6px 30px 6px' clickToCopyStyles={{ bottom: 0, left: '33%' }} code={this.embedCode(publisherVideoId)} copyable />
      </div>
    );
  }
};

const ToggleOption = ({ text, enabled, toggle, children }) => (
  <Div {...flexSpaceBetween} paddingTop={20}>
    <Div {...flexCenter}>
      <H5>{text}</H5>
      { children }
    </Div>
    <Div {...flexCenter}>
      <ToggleCmp checked={enabled} onChange={toggle} icons={false} />
      <P textAlign='right' width={60} marginLeft={-5}>{ enabled ? 'Enabled' : 'Disabled' }</P>
    </Div>
  </Div>
);

const maintainAspectRatioCode = {
  openingTag: '<div style="position:relative;width:100%;height:0;padding-bottom:56.25%;">',
  iframeStyles: 'style="position:absolute;top:0;left:0;width:100%;height:100%;"',
  closingTag: '</div>'
};

export default Auth(EmbedVideoModal);
