import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Div, P, Span, Svg } from '../../style/Global';
import { limitStringLength } from '../../util/TextUtil';
import Auth from '../../components/shared/AuthCmp';
import slugify from 'slugify';
import { Bold, Italic, Red, Grey, RedUnderline } from '../../style/Typography';
import { Spacer } from '../../style/StyledCmps';
import { Id } from './PagesStyle';
import Table from '../../components/shared/table/TableCmp';
import { RowDiv, UpperRow, LowerRow } from '../../components/shared/table/Helpers';
import Icon from '../../components/shared/FontAwesomeIconCmp';
import WhiteButton from '../../components/shared/WhiteButtonCmp';
import AddMappingModal from './modals/AddMappingModal';
import DeleteMappingModal from './modals/DeleteMappingModal';
import EmbedVideoModal from './modals/EmbedVideoModal';
import SyncMediaOffset from './modals/syncMediaOffsetModal/SyncMediaOffsetCmp';
import NoFallbackMappingModal from './modals/NoFallbackMappingModal';
import { colors } from '../../style/Constants';
import PreviewVideoModal from './modals/PreviewVideoModal';
import { UpdateDefaultSettingsForMediaMapping } from '../settings/overlay/OverlayDefaultsModals';
import { getPlayerStore } from './MediaMappingHelpers';
import API from '../../util/ApiUtil';
import EditMappingModal from './modals/EditMappingModal';
import { DEFAULT_RESULTS_PER_PAGE } from './MediaMappingStore';
import { getQueryParamaters, getQueryString, removeQueryParamaters } from '../../components/app/QueryParamsHelper';

@inject('mediaMappings', 'modal', 'app', 'flowplayerStore', 'brightcoveStore', 'jwplayerStore')
@observer
export class MediaMappingPage extends Component {
  constructor(props) {
    super();
    this.state = { currentPublisherId: props.app.publisher.id, pageNumber: 1 };
    this.openModal = this.openModal.bind(this);
  };

  async updateMappingTable(pageNumber) {
    const queryParamaters = getQueryParamaters();
    const queryParamaterMediaName = queryParamaters.mediaName && queryParamaters.mediaName.toLowerCase();
    const queryParamaterVideoPlayerName = queryParamaters.videoPlayer && queryParamaters.videoPlayer.toLowerCase();

    const newParams = this.props.mediaMappings.filterRecords({}, queryParamaterMediaName, queryParamaterVideoPlayerName);
    const { filterParams } = newParams;

    const { action, id } = this.props.match.params;
    const adjustedPageNumber = Math.abs(parseInt(pageNumber, 10) || 1);
    const skipToIndex = (adjustedPageNumber - 1) * DEFAULT_RESULTS_PER_PAGE || 0;
    this.setState({ currentPublisherId: this.props.app.publisher.id });
    await this.props.mediaMappings.tableQuery({ skip: skipToIndex, ...filterParams });

    this.handleUrlQueryAction(action, id);
  };

  componentDidMount() {
    const pageNumber = getQueryParamaters().mediaMappingPage || 1;
    this.setState({ currentPublisherId: this.props.app.publisher.id, pageNumber }, async () => await this.updateMappingTable(pageNumber));
  }

  componentDidUpdate(prevProps, prevState) {
    const { action, id } = this.props.match.params;
    let pageNumber = getQueryParamaters().mediaMappingPage;
    if (!pageNumber) pageNumber = 1;

    if (this.props.app.publisher.id !== prevState.currentPublisherId) {
      removeQueryParamaters(Object.keys(getQueryParamaters()));
      return this.setState({ currentPublisherId: this.props.app.publisher.id }, async () => await this.updateMappingTable());
    }
    if (id !== prevProps.match.params.id) return this.handleUrlQueryAction(action, id);
    if (action !== prevProps.match.params.action) return this.handleUrlQueryAction(action, id);
    if (this.state.pageNumber !== pageNumber) return this.setState({ pageNumber }, async () => await this.updateMappingTable(pageNumber));
  }

  componentWillUnmount() {
    const { app } = this.props;
    removeQueryParamaters(Object.keys(getQueryParamaters()));
    app.closePopups();
  }

  handleUrlQueryAction = (action, id) => {
    const { modal, mediaMappings } = this.props;
    const mapping = mediaMappings.mappings.find(mapping => mapping.id === id);

    if (!id && modal.modals.length > 0) return modal.close();
    if (!mapping && id !== 'add') return;

    if (id === 'add') return this.openModal('Add mapping', {});

    switch (action) {
      case 'edit': return this.openModal('Change player or mapping', mapping);
      case 'media-offset': return this.openModal('Synchronise media offset', mapping);
      case 'overlay-settings': return this.openModal('Overlay settings', mapping);
      case 'embed-video': return this.openModal('Embed video', mapping);
      case 'preview': return this.openModal('Preview Overlay', mapping);
      case 'delete': return this.openModal('Delete mapping', mapping);
      default: return
    }
  }

  setErrorMessage = (errorMessage, internalMessage) => {
    const { modal } = this.props;
    const currentModal = modal.findCurrentModal();
    currentModal._notice = { type: 'error', message: errorMessage };
    if (internalMessage) console.error(internalMessage);
  };

  slugifyVideoId = individualModal => {
    individualModal.internalState.videoId = slugify(individualModal.internalState.videoId).substr(0, 50);
  }

  translateErrorMessage = errorMessage => {
    let translated;
    switch (errorMessage) {
      case 'cannot map fallback to self':
        translated = 'You cannot fallback to the same video id.';
        break;
      case 'cannot use `facebook` player mapping for fallback':
        translated = 'You cannot fallback to another facebook video.';
        break;
      case 'invalid `fallbackVideoId` for publisher':
        translated = 'The video id used for the fallback does not exist.';
        break;
      default: return errorMessage;
    }
    return `${translated} Please use a different video id.`;
  };

  saveMapping = async individualModal => {
    this.slugifyVideoId(individualModal);
    const { mediaMappings } = this.props;
    const { changePlayerOrMapping } = individualModal;

    const {
      mediaName,
      videoPlayer,
      videoId,
      tagflixMediaId,
      youtubeVideoId,
      facebookVideoId,
      facebookUserId,
      fallbackMapping
    } = individualModal.internalState;
    const mediaMapping = {
      publisherVideoId: videoId, // required along with pubId
      mediaId: tagflixMediaId, // one of this or video name required
      videoName: mediaName,
      player: videoPlayer
    };

    const { flowplayerStore, brightcoveStore, jwplayerStore } = this.props;

    switch (videoPlayer) {
      case 'youtube':
        mediaMapping.youtubeVideoId = youtubeVideoId;
        break;
      case 'facebook':
        mediaMapping.facebookVideoId = `${facebookUserId}|${facebookVideoId}`;
        if (fallbackMapping.enabled) {
          if (!fallbackMapping.videoId) return this.setErrorMessage('Incomplete data. Please input a video id for the fallback mapping.');
          mediaMapping.fallbackVideoId = fallbackMapping.videoId;
        } else {
          mediaMapping.fallbackVideoId = null;
        }
        break;
      case 'flowplayer':
        if (flowplayerStore.flowplayerEmbedCode) mediaMapping.flowplayerEmbedCode = flowplayerStore.flowplayerEmbedCode;
        break;
      case 'brightcove':
        if (!brightcoveStore.isLinked) return this.setErrorMessage('Cannot use brightcove without first linking.');
        if (brightcoveStore.brightcoveVideoEmbedCode) mediaMapping.brightcoveEmbedCode = brightcoveStore.brightcoveVideoEmbedCode;
        break;
      case 'jwplayer':
        mediaMapping.jwplayerEmbedCode = jwplayerStore.jwplayerEmbedCode;
        break;
      default: return this.setErrorMessage('Something went wrong, please contact your account manager.', `Video player is not recognized: ${videoPlayer}`);
    };

    const errorMessage = () => this.setErrorMessage('All fields required, please try again.');

    if (getPlayerStore(videoPlayer) && !getPlayerStore(videoPlayer)[`${videoPlayer}VideoEmbedIsValid`]) return errorMessage();

    for (let i in mediaMapping) {
      if (mediaMapping[i] === null) continue;
      if (!mediaMapping[i]) return errorMessage();
    };

    const response = await API[changePlayerOrMapping ? 'put' : 'post']('portal/media', { data: { ...mediaMapping, pubId: API.publisherId() } });

    if (response.error) return this.props.mediaMappings.errorMessage = this.translateErrorMessage(response.error);

    if (!changePlayerOrMapping) await mediaMappings.tableQuery({ skip: (mediaMappings.pagination.last.page - 1) * 10 });
    mediaMappings.markVideoIdSaved(videoId, mediaMapping);
    this.props.modal.close();
  };

  openModal(title, mapping = {}) {
    const { modal, app } = this.props;
    const publisherId = app.publisher.id;
    const { publisherOverlayDefaults } = app;
    const modalProps = { title };
    switch (title) {
      case 'Add mapping':
        modalProps.component = () => <AddMappingModal individualModal={modal.findCurrentModal()} />;
        modalProps.globalButtons = [{ text: 'Save', icon: 'fa-check', func: this.saveMapping }];
        break;
      case 'Change player or mapping':
        modalProps.component = () => <EditMappingModal mapping={mapping} closeModal={modal.close} saveMapping={this.saveMapping} changePlayerOrMapping individualModal={modal.findCurrentModal()} />;
        modalProps.globalButtons = [{ text: 'Close', icon: 'fa-check', func: modal.close }];
        break;
      case 'Synchronise media offset':
        modalProps.component = () => <SyncMediaOffset mapping={mapping} />;
        modalProps.width = 676;
        break;
      case 'Overlay settings':
        modalProps.component = () => <UpdateDefaultSettingsForMediaMapping mapping={mapping} publisherOverlayDefaults={publisherOverlayDefaults} />;
        break;
      case 'Embed video':
        modalProps.component = () => <EmbedVideoModal mapping={mapping} modal={modal} />;
        modalProps.width = 470;
        modalProps.globalButtons = [{ text: 'Close', icon: 'fa-check', func: modal.close }];
        break;
      case 'Delete mapping':
        modalProps.component = () => <DeleteMappingModal mapping={mapping} openChangeMappingModal={() => this.openModal('Change player or mapping', mapping)} />;
        break;
      case 'Preview Overlay':
        modalProps.component = () => <PreviewVideoModal mapping={mapping} publisherId={publisherId} publisherOverlayDefaults={publisherOverlayDefaults} closeModal={modal.close} />;
        modalProps.width = 670;
        break;
      case 'No fallback mapping':
        modalProps.component = NoFallbackMappingModal;
        break;
      default: break;
    };
    modal.open(modalProps);
  };

  render() {
    const { mediaMappings, app, match } = this.props;

    return (
      <React.Fragment>
        <Div padding={30}>
          <WhiteButton icon='fa-plus' text='Add Mapping' route={`/media-mapping/add${getQueryString()}`} />
          <Spacer height={15} />
          <Table
            app={app}
            title='Your media identifier'
            headerHeight={220}
            pubId={this.state.currentPublisherId}
            tableRecords={mediaMappings.mappings}
            store={mediaMappings}
            row={(mapping, i) => <Row
              app={app} pageNumber={match.params.pageNumber}
              mapping={mapping} i={i} key={i}
              recentlySavedVideoIds={mediaMappings.recentlySavedVideoIds}
              deletedVideoIds={mediaMappings.deletedVideoIds}
              openModal={this.openModal}
              dismiss={mediaMappings.dismissHighlight}
            />}
          />
        </Div>
      </React.Fragment>
    );
  };
};

@observer
class Row extends Component {
  state = { active: false };

  activate = () => this.setState({ active: true });
  deactivate = () => this.setState({ active: false });

  render() {
    const { mapping, recentlySavedVideoIds, deletedVideoIds, i, openModal, dismiss, app, pageNumber } = this.props;
    const showWarning = mapping.videoPlayer === 'facebook' && !mapping.fallbackVideoId;
    const recentlySaved = recentlySavedVideoIds.includes(mapping.videoId);
    const deleted = deletedVideoIds.includes(mapping.videoId);
    const { title } = deleted ? highlightedRowText.deleted : highlightedRowText.saved;
    const { description } = deleted ? highlightedRowText.deleted : highlightedRowText.saved;
    const publisherCapabilities = app.publisherOverlayDefaults;
    return (
      <RowDiv
        isEven={i % 2}
        recentlySaved={recentlySaved}
        deleted={deleted}
        justifyContent='space-between'
        padding='10px'
        onMouseEnter={this.activate}
        onMouseLeave={this.deactivate}
      >
        <Div>
          <UpperRow>{limitStringLength(mapping.videoName, 76)}</UpperRow>
          <LowerRow>
            <Id>{mapping.videoId}</Id>
          </LowerRow>
        </Div>
        {
          recentlySaved || deleted
            ?
              <HighlightedRowText title={title} description={description} dismiss={dismiss} id={mapping.videoId} />
            :
            this.state.active
              ?
                <RowButtons mapping={mapping} openModal={openModal} pageNumber={pageNumber} showWarning={showWarning} publisherCapabilities={publisherCapabilities} />
              :
                <HoverForActions showWarning={showWarning} />
        }
      </RowDiv>
    );
  };
};

const RowButtons = ({ mapping, openModal, showWarning, publisherCapabilities }) => {

  let { videoPlayer, isOverlayEnabled, id } = mapping;
  return (
    <Div display='flex' alignItems='center' justifyContent='flex-end' minWidth={130}>
      {
        showWarning && isOverlayEnabled &&
        <Icon height={68} margin='5px 0 0 0' name='fa-exclamation-triangle' cursor='pointer' color={colors.grey} onClick={() => openModal('No fallback mapping')} />
      }
      <Div marginLeft={15}>
        {
          !isOverlayEnabled
            ?
              <DisabledButton link={`/media-mapping/${id}/edit${getQueryString()}`} />
            :
              <Link to={`/media-mapping/${id}/edit${getQueryString()}`}>
                {
                videoPlayer === 'brightcove' ?
                  <Svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g fill='#EE0D4E' fillRule='evenodd' cursor='pointer'><path d='M22 13.808l-.027.23-.072.202-1.713 3.04-2.006 3.544-.157.267-.196.22-.218.202-.223.156-.288.138-.256.092-.315.073-.288.028H8.794l-.158-.028-.157-.036-.132-.101-.124-.092-.098-.101-.065-.165L8 21.31v-.358l.092-.193 1.941-3.48 1.75-3.141.164-.23.19-.23.223-.192.255-.193.25-.129.289-.1.288-.065h7.929l.117.064.162.065.099.091.125.138.063.129.036.165zM22 11.192l-.027-.23-.072-.202-1.713-3.049-2.006-3.563-.157-.23-.196-.23-.218-.201-.223-.166-.288-.128-.256-.101-.315-.064L16.241 3H8.794l-.158.028-.157.036-.132.065-.124.091-.098.138-.065.165-.06.13v.394l.092.193 1.941 3.471 1.75 3.15.164.23.19.23.223.192.255.166.25.128.289.101.288.064.315.028h7.447l.167-.028.117-.036.162-.065.099-.1.125-.13.063-.128.036-.156zM7.196 4.449l3.529 6.9.124.268.089.315.062.344v.601l-.062.315-.089.296-.124.277L9.02 16.99l-1.856 3.608-.119.162-.182.134-.12.076-.15.029h-.155l-.151-.029-.125-.038-.15-.095-.089-.105-.093-.134L2.3 13.698l-.15-.315-.094-.296-.026-.305L2 12.476l.03-.334.058-.315.094-.267.12-.277L4.218 7.57 5.86 4.449l.12-.21.15-.134.156-.067.15-.038h.156l.12.038.15.067.12.076.125.134z' /></g></Svg>
                  :
                  videoPlayer === 'jwplayer' ?
                    <Svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g fill='#EE0D4E' fillRule='evenodd' cursor='pointer'><path d='M2.982 12.3c-.056.183-.146.172-.191 0l-.09-.314s-.124-.453-.338-.993a1.723 1.723 0 0 0-.247-.497.692.692 0 0 0-.697-.173.616.616 0 0 0-.416.508c-.011.194.011.4.056.594.169 1.166.439 2.322.81 3.455.236.508.843.735 1.371.519.012-.011.034-.011.045-.022.473-.335.81-.81.956-1.35.259-.604 1.585-4.114 1.585-4.114.135-.345.237-.335.225.043 0 0-.067 3.434-.078 5a9.012 9.012 0 0 0 .213 2.192c.101.378.326.713.641.961.315.227.709.335 1.102.292.191-.033.382-.087.551-.184.247-.14.461-.313.63-.54a9.276 9.276 0 0 0 .978-1.576c.64-1.21 2.294-4.946 2.294-4.946.146-.346.258-.313.247.054 0 0-.202 6.285-.214 8.682 0 .594.113 1.188.338 1.75.202.528.607.96 1.135 1.198a2.064 2.064 0 0 0 1.8-.097c.415-.249.753-.584.989-.994.337-.55.618-1.134.843-1.728.495-1.23.91-2.505 1.316-3.768.404-1.264 3.901-13.26 4.126-14.286a.926.926 0 0 0 .034-.346.718.718 0 0 0-.405-.55.804.804 0 0 0-1.034.356c-.192.378-4.97 11.5-4.97 11.5-.146.345-.27.324-.27-.054 0 0 .011-3.866.034-7.073.01-1.739-.877-2.71-2.125-2.905a2.404 2.404 0 0 0-1.653.357c-.506.345-.911.799-1.18 1.339-.383.701-2.722 7.267-2.722 7.267-.124.345-.236.335-.247-.033 0 0-.068-2.818-.135-3.487-.101-1.015-.349-2.7-1.72-2.786-1.293-.076-1.867 1.447-2.204 2.397-.214.626-1.305 4.028-1.305 4.028l-.078.324' /></g></Svg>
                    :
                    <Icon name={videoPlayer === 'flowplayer' ? 'far fa-play-circle' : `fab fa-${videoPlayer}`} color={colors.red} cursor='pointer' lg
                    />
              }
              </Link>
        }
      </Div>
      {
        isOverlayEnabled &&
        <Div marginLeft={15}>
          <Link to={`/media-mapping/${id}/media-offset${getQueryString()}`}>
            <Icon name='far fa-clock' color={colors.red} cursor='pointer' lg />
          </Link>
        </Div>
      }
      {
        isOverlayEnabled && publisherCapabilities.primaryColor &&

        <Div marginLeft={15}>
          <Link to={`/media-mapping/${id}/overlay-settings${getQueryString()}`}>
            <Icon name='fas fa-sliders-h' color={colors.red} cursor='pointer' lg />
          </Link>
        </Div>
      }
      <Div marginLeft={15}>
        <Link to={`/media-mapping/${id}/embed-video${getQueryString()}`}>
          <Icon name='fa-code' color={colors.red} cursor='pointer' lg />
        </Link>
      </Div>
      <Div></Div>
      <Div marginLeft={15}>
        <Link to={`/media-mapping/${id}/preview${getQueryString({ viewMode: 'desktop' })}`}>
          <Icon name='fa-image' color={colors.red} cursor='pointer' lg />
        </Link>
      </Div>
      <Div marginLeft={15} marginRight={5}>
        <Link to={`/media-mapping/${id}/delete${getQueryString()}`}>
          <Icon name='fa-times' color={colors.red} cursor='pointer' lg />
        </Link>
      </Div>
    </Div>
  );
};

const highlightedRowText = {
  deleted: {
    title: 'Deleted!',
    description: 'This mapping has been deleted.'
  },
  saved: {
    title: 'Saved!',
    description: 'Changes may take up to 20 minutes to reflect on your site.'
  }
};

const HighlightedRowText = ({ title, description, dismiss, id }) => (
  <Div display='flex' alignItems='center' minWidth={130}>
    <Div textAlign='right'>
      <P fontSize={11}><Bold><Italic>{title}</Italic></Bold></P>
      <P fontSize={8}>{description}</P>
    </Div>
    <P fontSize={11} paddingLeft={5}>(<Span onClick={() => dismiss(id)}><RedUnderline><Bold>dismiss</Bold></RedUnderline></Span>)</P>
  </Div>
);

const HoverForActions = ({ showWarning }) => (
  <Div display='flex' justifyContent='flex-end' alignItems='center' alignSelf='center' minWidth={130}>
    {showWarning && <Icon name='fa-exclamation-triangle' color={colors.grey} />}
    <P fontSize={11} paddingRight={4} paddingLeft={12}>
      <Red><Italic>hover for actions</Italic></Red>
    </P>
  </Div>
);

const DisabledButton = ({ link }) => {
  return (
    <Grey>
      <Div fontSize={colors.SmallText} display='flex'>
        <P paddingRight={4}><Bold>Disabled</Bold></P>
        <Link to={link} >
          <P>(<RedUnderline>enable</RedUnderline>)</P>
        </Link>
      </Div>
    </Grey>
  );
}

export default Auth(MediaMappingPage);
