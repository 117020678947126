import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Div, P, Button, Span } from '../../../../style/Global';
import { Bold, RedUnderline, Italic } from '../../../../style/Typography';
import { colors, fontSizes, fontWeight } from '../../../../style/Constants';
import { MessageCmp } from '../../../../style/StyledCmps';
import { CheckboxComponent } from '../../../../components/shared/CheckboxCmp';
import CodeCopyableTextArea from '../../../../components/shared/CodeCopyableTextAreaCmp';
import Icon from '../../../../components/shared/FontAwesomeIconCmp';
import EmbedPropertiesContainer from '../../SharedComponents/EmbedPropertiesContainerCmp';
import IframeEmbedCode from '../../SharedComponents/IframeEmbedCmp';
import config from '../../../../config/config';
import LoadingCmp from '../../LoadingCmp';

export const BrightcoveConfiguration = inject('brightcoveStore', 'mediaMappings')(observer(({ brightcoveStore, mediaMappings }) => {
  if (!brightcoveStore.isLinked) return <BrightcovePreIntegrationActivation brightcoveStore={brightcoveStore} mediaMappings={mediaMappings} />;
  return <BrightcovePostIntegrationActivation brightcoveStore={brightcoveStore} mediaMappings={mediaMappings} />;
}));

@inject('brightcoveStore')
@observer
export class BrightcovePreIntegrationActivation extends Component {
  render() {
    const { brightcoveStore } = this.props;

    return (
      <Div width='100%'>
        <P marginTop={5}><Bold>Activate Integration (Step {brightcoveStore.brightcovePlayerEmbedIsValid ? '2' : '1'} of 2)</Bold></P>
        <P marginTop={10}>
          To start using brightcove, you need to create a custom player.
          Paste your brightcove player embed code below to begin. <RedUnderline cursor='pointer'>
            <a href={config.documentationUrl.brightcove} target='_blank'>More information »</a>
          </RedUnderline>
        </P>
        <BrightcovePlayerEmbedCodeContainer />
      </Div>
    );
  };
};

export const BrightcovePlayerEmbedCodeContainer = inject('brightcoveStore', 'modal')(observer(({ brightcoveStore, modal }) => {
  const currentModal = modal.findCurrentModal();
  if (brightcoveStore.isLoading) return (
    <Div marginTop={20}>
      <LoadingCmp
        title={!brightcoveStore.brightcovePlayerEmbedIsValid ? 'Checking brightcove player configuration...' : 'Linking player...'}
        message={!brightcoveStore.brightcovePlayerEmbedIsValid ? 'Tagflix can view published player configuration, but cannot write to it.' : 'Please wait.'} />
    </Div>
  );
  if (!brightcoveStore.brightcovePlayerEmbedIsValid) return (
    <IframeEmbedCode
      currentModal={currentModal}
      currentStore={brightcoveStore}
      addEmbedCode={brightcoveStore.onPlayerEmbedAdd}
      errorMessage={brightcoveStore.brightcovePlayerError}
      embedIsValidString={'brightcovePlayerEmbedIsValid'}
      title='brightcove player'
    />
  );
  return <BrightcovePlayerEmbedCode />;
}));

@inject('brightcoveStore', 'mediaMappings')
@observer
export class BrightcovePlayerEmbedCode extends Component {

  state = { checked: false };

  onCheckboxClick = () => {
    this.setState({ checked: !this.state.checked });
  };

  render() {
    const { brightcoveStore, mediaMappings } = this.props;
    return (
      <Div>
        <CodeCopyableTextArea height={128} clickToCopyStyles={{ bottom: 0, left: '32.5%' }} padding='6px 20px 30px 6px' margin='10px 0' code={brightcoveStore.brightcovePlayerEmbedCode} copyable />
        <CheckboxComponent checked={this.state.checked} onCheckboxClick={this.onCheckboxClick}>
          <P flex='80%' marginLeft='10px'>
            I have created a custom player for tagflix within brightcove and copied the code above.
            My custom player does not include additional customizations, plugins or modifications which might interfere with this integration.
          </P>
          <LinkBrightcovePlayerBtn checked={this.state.checked} setErrorMessage={mediaMappings.setErrorMessage} linkPlayer={brightcoveStore.linkPlayer} />
        </CheckboxComponent>
      </Div>
    );
  };
};

export const LinkBrightcovePlayerBtn = ({ checked, linkPlayer, setErrorMessage }) => (
  <Button
    border={`solid 1px ${colors.borderGrey}`}
    background={checked ? 'white' : colors.lightGrey}
    width='175px'
    height='38px'
    margin='auto'
    marginTop='10px'
    display='flex'
    justifyContent='center'
    alignItems='center'
    cursor='pointer'
    onClick={() => {
      if(!checked) return;
      linkPlayer();
      setErrorMessage();
    }}>
    <Icon name='fa-link' color={checked ? colors.red : 'graytext'} me />
    <P fontSize={12} color={checked ? colors.darkGreyText : colors.greyText} paddingLeft={8}>Link Brightcove Player</P>
  </Button>
);

@inject('brightcoveStore', 'mediaMappings', 'modal')
@observer
export class BrightcovePostIntegrationActivation extends Component {

  async componentDidMount() {
    const { brightcoveStore } = this.props;
    await brightcoveStore.checkIfPlayerConfigurationIsValid();
  };

  render() {
    const { brightcoveStore, modal } = this.props;
    const currentModal = modal.findCurrentModal();

    return (
      <Div width='100%'>
        <Div display='flex' flexDirection='column' width={401}>
          <P marginBottom='10px'>
            Your brightcove integration will only work correctly if you have setup your custom player without additional customizations. <RedUnderline cursor='pointer'>
              <a href={config.documentationUrl.brightcove} target='_blank'>More information »</a>
            </RedUnderline>
          </P>
        </Div>
        <BrightcovePlayerCheckerCmp>
          {(brightcoveStore.isLoading) ?
            <Div marginTop={20}>
              <LoadingCmp title={!brightcoveStore.brightcovePlayerConfigIsValid ? 'Checking brightcove player configuration...' : 'Checking embed code...'} message={!brightcoveStore.brightcovePlayerConfigIsValid ? 'Tagflix can view published player configuration, but cannot write to it.' : 'Please wait.'} />
            </Div>
            :
            (!brightcoveStore.isLoading && !brightcoveStore.brightcoveVideoEmbedIsValid) ?
              <IframeEmbedCode
                currentModal={currentModal}
                currentStore={brightcoveStore}
                addEmbedCode={brightcoveStore.onIframeEmbedAdd}
                errorMessage={brightcoveStore.brightcoveVideoEmbedError}
                embedIsValidString={'brightcoveVideoEmbedIsValid'}
                title='brightcove video iframe' />
              :
              <EmbedPropertiesContainer title='Brightcove' embedPropertiesList={[
                { name: 'Account Id', value: brightcoveStore.brightcovePlayerAccountId },
                { name: 'Video Id', value: brightcoveStore.brightcoveVideoEmbedId },
                { name: 'Player Id', value: brightcoveStore.brightcovePlayerId },
                { name: 'Embed Id', value: brightcoveStore.brightcovePlayerEmbedId ? brightcoveStore.brightcovePlayerEmbedId : 'default' }
              ]} resetEmbedValues={brightcoveStore.clearVideoEmbedCode} />
          }
        </BrightcovePlayerCheckerCmp>
      </Div>
    );
  };
};

export const BrightcovePlayerCheckerCmp = inject('brightcoveStore', 'mediaMappings')(observer(({ brightcoveStore, children, mediaMappings }) => {
  if (brightcoveStore.isLoading) return <Div>{children}</Div>;
  return (
    <React.Fragment>
      <Div width={411} height={27} border={`solid ${colors.grey} 1px`} marginBottom={2} backgroundColor={colors.lightGrey} display='flex' justifyContent='flex-end' alignItems='center'>
        <P height={17} fontSize={fontSizes.small}>
          <Span marginRight={5} fontWeight={fontWeight.semiBold}>{ brightcoveStore.brightcovePlayerName }</Span>
          <Italic marginRight={10} color={colors.greyText}>({ brightcoveStore.brightcovePlayerId })</Italic>
        </P>
        <BrightcovePlayerVerifiedColorBox brightcovePlayerConfigIsValid={brightcoveStore.brightcovePlayerConfigIsValid} />
      </Div>
      <Div width='100%' textAlign='right'>
        <RedUnderline fontSize={fontSizes.small}
          onClick={() => {
            mediaMappings.setErrorMessage(null);
            brightcoveStore.resetEmbedValues();
            brightcoveStore.unlinkPlayer();
          }} cursor='pointer'
        >Unlink player and start over</RedUnderline>
      </Div>
      {children}
      {!brightcoveStore.brightcovePlayerConfigIsValid &&
        <MessageCmp type='error' margin='10px 0 20px 0' width={411} height={86} padding={9}>
          <Div lineHeight={1.4} width={393} height={68}>
            <Span fontWeight={fontWeight.semiBold}><Italic>The player</Italic></Span> configuration appears to be have changed since linking.
            Either revert the change and again and <RedUnderline onClick={brightcoveStore.checkIfPlayerConfigurationIsValid}>check configuration</RedUnderline> again or unlink the player and start over.
            Updated player settings in brightcove studio may take up to 20 minutes to propagate.
          </Div>
        </MessageCmp>
      }
    </React.Fragment>
  );
}));

export const BrightcovePlayerVerifiedColorBox = observer(({ brightcovePlayerConfigIsValid }) => {
  return (
    <Div padding={5} height={27} backgroundColor={brightcovePlayerConfigIsValid ? colors.darkGrassGreen : colors.warningRed}>
      <P fontSize={fontSizes.small}>
        <Icon color={colors.white} name={brightcovePlayerConfigIsValid ? 'fa-check-circle' : 'fa-times-circle'} me />
        <Span color={colors.white} marginLeft={3}>{brightcovePlayerConfigIsValid ? 'Linked' : 'Broken'}</Span>
      </P>
    </Div>
  );
});
