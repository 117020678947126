import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Div, DivComponent } from '../../../../../style/Global';
import Slider from 'react-slider';
import { frameNumberToTimestamp } from '../SyncMediaOffsetUtil';
import { Bold, TinyText, Grey, LightGrey, Red } from '../../../../../style/Typography';
import { colors } from '../../../../../style/Constants';
import Icon from '../../../../../components/shared/FontAwesomeIconCmp';
import { BigNumber } from '../SyncMediaOffsetStyle';
import './ScrubberControl.css';
import EditableComponent from '../../../../../components/shared/EditableComponent';

@inject('mediaOffset')
@observer
class ScrubberControl extends Component {

  render() {
    const { mediaOffset } = this.props;
    const {
      notchValues,
      scrubberMinValue,
      scrubberMaxValue,
      resetScrubberButtonEnabled,
      resetScrubberMinValue,
      resetScrubberMaxValue,
      ticks,
      fps
    } = mediaOffset;

    return (
      <Div marginTop={28} width='100%'>
        <SliderContainer>
          <ResetScrubber direction='backward' enabled={resetScrubberButtonEnabled.backward} onClick={resetScrubberMinValue} margin='0 10px 0 0' />
          <SliderBoundary addStyle={{ right: -1 }}/>
          <Scrubber mediaOffset={mediaOffset} scrubberControlContext={this} />
          <SliderBoundary addStyle={{ left: -1 }}/>
          <ResetScrubber direction='forward' enabled={resetScrubberButtonEnabled.forward} onClick={resetScrubberMaxValue} margin='0 0 0 10px' />
          <SliderTrackLines mediaOffset={mediaOffset} />
        </SliderContainer>

        <SpaceBetweenDiv>
          <TinyText padding='2px 0'><LightGrey>{frameNumberToTimestamp(scrubberMinValue, fps)}</LightGrey></TinyText>
          { ticks.show && <TinyText padding='2px 0' textAlign='center'><Bold>{ticks.mode}</Bold></TinyText> }
          <TinyText padding='2px 0'><LightGrey>{frameNumberToTimestamp(scrubberMaxValue, fps)}</LightGrey></TinyText>
        </SpaceBetweenDiv>

        <SpaceBetweenDiv>
          <BigNumber>{frameNumberToTimestamp(notchValues[1], fps)}</BigNumber>
          <Div display='flex'>
            <TinyText paddingRight={6} paddingTop={7}><Grey>frame</Grey></TinyText>
            <BigNumber>{notchValues[1]}</BigNumber>
          </Div>
        </SpaceBetweenDiv>
      </Div>
    );
  }
};

const SLIDER_WIDTH = 440;

@observer
class Scrubber extends EditableComponent {
  render () {
    const { mediaOffset } = this.props;
    const {
      notchValues,
      onSliderValueChange,
      afterSliderValueChange,
      scrubberMinValue,
      scrubberMaxValue,
      fps
    } = mediaOffset;
    return (
      <Slider
        value={[ ...notchValues ]}
        key={1}
        onChange={values => {
          this.addToInProgressEditsCount();
          onSliderValueChange(values);
        }}
        onAfterChange={values => afterSliderValueChange(values)}
        renderThumb={(props, state) => <SliderTriangle key={state.index} thumbProps={props} timestamp={frameNumberToTimestamp(state.valueNow, fps)}/> }
        min={scrubberMinValue}
        max={scrubberMaxValue}
        minDistance={1}
        snapDragDisabled={true}
      />
    );
  }
};

const ResetScrubber = ({ direction, enabled, onClick, margin }) => (
  <Div display='flex' alignItems='flex-end' onClick={onClick} margin={margin} cursor={enabled ? 'pointer' : 'default'}>
    <Icon name={`fa-step-${direction}`} color={ enabled ? colors.red : colors.lightGrey }/>
  </Div>
);

const SliderBoundary = ({ addStyle }) => (
  <Div position='relative'>
    <Div {...addStyle} position='absolute' width={3} backgroundColor={colors.greyText} height={30} zIndex={1} />
  </Div>
);

const SliderTrackLines = observer(({ mediaOffset }) => {
  const { notchValues, ticks, scrubberMinValue, fps } = mediaOffset;
  return (
    <Div position='absolute' top={7} height={13} width={SLIDER_WIDTH} margin='auto' borderBottom={`1px solid ${colors.greyText}`}>
      <DottedLine/>
      {
        ticks.show
          &&
          <Ticks notchValues={notchValues} ticks={ticks} scrubberMinValue={scrubberMinValue} fps={fps} />
      }
    </Div>
  );
});

const SliderTriangle = ({ thumbProps, timestamp }) => (
  <Div {...thumbProps} position='relative'>
    <TinyText position='absolute' textAlign='center' top={-32} left={-20} className={'timestamp-text'}>
      <Bold ><Red>{timestamp}</Red></Bold>
    </TinyText>
  </Div>
);

const Ticks = ({ ticks, scrubberMinValue, fps }) => {
  const tickWidth = (SLIDER_WIDTH / ticks.numberOfTicks).toFixed(3);
  const leftOffset = getLeftOffsetForTicks({ ticks, tickWidth, scrubberMinValue, fps });
  const arr = new Array(Math.ceil(ticks.numberOfTicks)).fill();
  const tickCmps = arr.map((el, i) => <Tick left={getLeftPositionForTicks(tickWidth, leftOffset, i)} key={i}/>);
  return <Div position='relative' marginTop={12}>{tickCmps}</Div>;
};

const getLeftPositionForTicks = (tickWidth, leftOffset, i) => Math.round(i * tickWidth + leftOffset) - 1;

const getLeftOffsetForTicks = ({ ticks, tickWidth, scrubberMinValue, fps }) => {
  if (ticks.mode === 'frames') return -1;
  const scrubberMinValueFramesOffset = scrubberMinValue % Math.round(fps);
  const percentOfTickWidthOffset =  1 - scrubberMinValueFramesOffset / fps;
  return tickWidth * percentOfTickWidthOffset;
};

const Tick = DivComponent(({ left }) => ({
  position: 'absolute',
  left,
  width: 1,
  height: 5,
  backgroundColor: colors.lightGreyText
}));

const SpaceBetweenDiv = DivComponent({
  display: 'flex',
  justifyContent: 'space-between'
});

const SliderContainer = DivComponent({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'stretch',
  position: 'relative',
  height: 30
});

const DottedLine = DivComponent({
  height: 1,
  backgroundImage: `linear-gradient(to right, ${colors.lightGreyText} 33%, rgba(255,255,255,0) 0%)`,
  backgroundPosition: 'bottom',
  backgroundSize: '3px 1px',
  backgroundRepeat: 'repeat-x'
});

export default ScrubberControl;
