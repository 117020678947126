import { DivComponent } from '../../../style/Global';
import { colors } from '../../../style/Constants';

export const DEFAULT_MODAL_WIDTH = 470;
const BOX_HEADER_HEIGHT = 60;
export const BOX_BODY_PADDING = 25;

export const ModalContainer = DivComponent({
  display: 'flex',
  position: 'fixed',
  top: 0,
  bottom: 0,
  width: '100vw',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 4
});

export const ModalBackground = DivComponent({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'black',
  opacity: 0.2
});

export const ModalBox = DivComponent(({ width, margin }) => ({
  position: 'relative',
  width,
  margin: margin || '10px 0',
  backgroundColor: colors.white,
  opacity: 1,
  zIndex: 3,
  boxShadow: '0 20px 40px 0 rgba(52, 47, 76, 0.2), 0 4px 8px 0 rgba(40, 49, 67, 0.4)'
}));

export const BoxHeader = DivComponent(({ width, zIndex }) => ({
  backgroundColor: colors.lightPink,
  width: width || '100%',
  height: BOX_HEADER_HEIGHT,
  padding: 25,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex
}));

export const TabsContainer = DivComponent(({ width, zIndex }) => ({
  backgroundColor: colors.white,
  width: width || '100%',
  display: 'flex',
  alignItems: 'center',
  zIndex
}));

export const Tab = DivComponent(({ active }) => ({
  padding: '7px 10px 7px 15px',
  fontSize: 15,
  cursor: 'pointer',
  zIndex: 5,
  color: active && colors.red,
  borderBottom: active && `${colors.red} solid 2px`
}));

export const BoxFooter = DivComponent(({ width, zIndex }) => ({
  boxShadow: '0.5px 0.5px 5px 0 rgba(210, 210, 210, 0.5)',
  borderTop: `${colors.lightGrey} 1px solid`,
  backgroundColor: '#ffffff',
  width: width || '100%',
  alignItems: 'center',
  zIndex
}));
