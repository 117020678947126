import { colors } from '../../style/Constants';
import { DivComponent, PComponent } from '../../style/Global';
import { filterPropsForStyling } from '../../style/StyleUtil';

export const RedSentenceDiv = DivComponent(props => ({
  display: 'flex',
  alignItems: 'center',
  ...filterPropsForStyling(props)
}));

export const RedSentence = PComponent({
  paddingLeft: 10,
  color: colors.red,
  fontStyle: 'italic',
  fontWeight: '600'
});

export const Id = PComponent({
  color: colors.lightGreyText,
  textAlign: 'left'
});
