import React from 'react';
import Icon from '../../components/shared/FontAwesomeIconCmp';
import { Div, P } from '../../style/Global';
import { colors, fontSizes, fontWeight } from '../../style/Constants';

const LoadingCmp = ({ title, message, isLoading = true, textColor = colors.darkGreyText, spinnerColor = colors.greyText }) => {
  return (
    <Div textAlign='center'>
      { isLoading && <Icon name='fa-circle-notch fa-spin' color={spinnerColor} size={3} margin='0 0 15px 0' /> }
      <P color={textColor} marginBottom={4} fontWeight={fontWeight.semiBold} fontSize={fontSizes.p}>{title}</P>
      <P fontSize='11px' fontWeight={fontWeight.light} color={textColor}>{message}</P>
    </Div>
  );
};

export default LoadingCmp;
