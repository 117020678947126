import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { P, H4, Div, DivComponent } from '../../style/Global';
import { Italic, Red, Grey } from '../../style/Typography';
import { colors } from '../../style/Constants';
import Icon from '../shared/FontAwesomeIconCmp';
import Table from '../shared/table/TableCmp';
import { RowDiv } from '../shared/table/Helpers';
import { preventPopupDismissalPropagation } from '../shared/helpers';

@inject('app', 'mediaMappings', 'team')
@observer
class SelectPublisherDropdown extends Component {
  state = { isPublisherDropdownOpen: false, hovering: false };

  togglePublisherList = e => {
    const { app } = this.props;
    preventPopupDismissalPropagation(e);
    app.popupToStore({ name: 'selectPublisherDropdown', popupToStore: this.togglePublisherList });
    this.setState({ isPublisherDropdownOpen: !this.state.isPublisherDropdownOpen });
  }

  onHover = () => this.setState({ hovering: true });
  onUnhover = () => this.setState({ hovering: false });

  createPublisherRow = (publishers, currentPublisher, setPublisher) => {
    if (currentPublisher) return selectNewPublisher(currentPublisher, publishers, setPublisher);
    showPublishers(publishers, setPublisher);
  };

  onPublisherSelect = (publisher, e) => {
    const { app } = this.props;
    preventPopupDismissalPropagation(e);
    app.setPublisher(publisher);
    this.togglePublisherList(e);
  };

  render() {
    const { publisher, publishers } = this.props.app;
    const { isPublisherDropdownOpen, hovering } = this.state;

    return (
      <React.Fragment>
        <SelectPublisherContainer onClick={preventPopupDismissalPropagation} isPublisherDropdownOpen={isPublisherDropdownOpen} onMouseOver={this.onHover} onMouseOut={this.onUnhover}>
          <PublisherTitleDiv onClick={this.togglePublisherList}>
            <H4 zIndex={5}>{publisher.name}</H4>
            { publisher.name && <Icon name='fa-caret-down' zIndex={5} color={hovering ? colors.red : colors.darkGreyText} lg/> }
          </PublisherTitleDiv>
          { this.state.isPublisherDropdownOpen &&
          <PublisherList currentPublisher={publisher} publishers={publishers} onPublisherSelect={this.onPublisherSelect} />
          }
        </SelectPublisherContainer>
      </React.Fragment>
    );
  };
};

export const PublisherList = ({ publishers, currentPublisher, onPublisherSelect }) => (
  <Div width='100%' borderTop={`1px solid ${colors.lightGreyBorder}`} backgroundColor={colors.white} zIndex={4}>
    <Table
      contentHeight={180}
      tableRecords={publishers.filter(publisher => publisher.id !== currentPublisher.id)}
      row={(publisher, i) => <Row
        publisher={publisher} i={i} key={i}
        onPublisherSelect={onPublisherSelect}
      />}
    />
  </Div>
);

class Row extends Component {
  render() {
    const { publisher, i, onPublisherSelect } = this.props;
    return (
      <RowDiv
        isEven={i%2}
        justifyContent='space-between'
        padding={10}
        cursor='pointer'
        key={i}
        onClick={e => onPublisherSelect(publisher, e)}
      >
        <P><Red>{publisher.name}</Red></P>
        <P paddingRight={10} fontSize={11}><Grey><Italic>{publisher.userRole}</Italic></Grey></P>
      </RowDiv>
    );
  };
};

const SelectPublisherContainer = DivComponent(({ isPublisherDropdownOpen }) => ({
  width: 226,
  height: isPublisherDropdownOpen ? 220 : 40,
  marginBottom: isPublisherDropdownOpen ? -175 : 5,
  marginLeft: -10,
  position: 'relative',
  zIndex: 2,
  backgroundColor: isPublisherDropdownOpen ? colors.white : 'transparent',
  boxShadow: isPublisherDropdownOpen ? '0.5px 0.5px 5px 0.5px rgba(52, 48, 77, 0.2)' : 'none'
}));

const PublisherTitleDiv = DivComponent({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 40,
  padding: '0 10px',
  cursor: 'pointer'
});

export default SelectPublisherDropdown;
