import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import emailValidator from 'email-validator';
import { P, Div } from '../../../style/Global';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';
import { MessageCmp } from '../../../style/StyledCmps';
import { UnderlinedInput, WhiteButtonContainer } from '../../../style/FormStyle';

@inject('app')
@observer
class EnterEmail extends Component {
  constructor() {
    super();
    this.state = { email: '', errorMessage: '' };
    this.onEmailChange = e => this.setState({ email: e.target.value });
    this.setErrorMessage = errorMessage => this.setState({ errorMessage });
    this.clearErrorMessage = () => this.setState({ errorMessage: '' });
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = async e => {
    e.preventDefault();
    const { email } = this.state;
    const { app } = this.props;
    if (!emailValidator.validate(email)) {
      return this.setErrorMessage('Email address must be valid. Please try again.');
    }
    this.clearErrorMessage();
    await app.requestPasswordResetEmail(email);
  }

  render() {
    const { email, errorMessage } = this.state;
    return (
      <form>
        <P paddingBottom={15}>To reset your password, enter your portal email address.</P>
        <UnderlinedInput value={email} onChange={this.onEmailChange} />
        { errorMessage && <MessageCmp type='error'><p>{errorMessage}</p></MessageCmp> }
        <WhiteButtonContainer>
          <WhiteButton icon='far fa-envelope-open' text='Reset password' onClick={this.onSubmit} />
        </WhiteButtonContainer>
      </form>
    );
  }
}

@inject('modal')
@observer
class PleaseCheckEmail extends Component {
  render() {
    const { modal } = this.props;
    return (
      <Div>
        <P>Please check your email to proceed with the password reset.</P>
        <WhiteButtonContainer>
          <WhiteButton icon='fa-check' text='Ok' closeModal={modal.close} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

const forgotPasswordModals = {
  enterEmail: {
    title: 'Forgot Password',
    component: EnterEmail
  },
  success: {
    title: 'Forgot Password',
    component: PleaseCheckEmail
  }
};

export default forgotPasswordModals;
