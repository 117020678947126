import './style/css/reset.css';
import './style/css/global.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import App from './components/app/AppCmp';
import API from './util/ApiUtil';
import { stores } from './util/StoresUtil';

API.connectToApp(stores.app);

window.Tagflix = { inProgressEditsCount: 0 };

const Root = () => (
  <Provider {...stores}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
