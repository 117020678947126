const removeThesePropsForStyling = [ 'children', 'theme', 'key' ];

export const filterPropsForStyling = props => {
  const result = {};
  for (let prop in props) {
    if (removeThesePropsForStyling.includes(prop)) continue;
    prop = convertKebabToCamelCase(prop);
    result[prop] = props[prop];
  }
  return result;
};

const convertKebabToCamelCase = str => {
  const words = str.split('-');
  if (words.length === 1) return str;
  let camelCaseWord = '';
  words.forEach((word, i) => {
    if (i === 0) return camelCaseWord += word.toLowerCase();
    camelCaseWord += word[0].toUpperCase();
    camelCaseWord += word.slice(1).toLowerCase();
  });
  return camelCaseWord;
};
