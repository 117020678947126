import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Toggle from 'react-toggle';
import { Div, P } from '../../../../style/Global';
import { Bold, Italic, Red } from '../../../../style/Typography';
import { MessageCmp } from '../../../../style/StyledCmps';
import { VideoUrlText } from '../../MediaMappingStyle';
import EditableComponent from '../../../../components/shared/EditableComponent';
import { VideoUrlInputCmp, SearchMedia } from '../MappingModalHelpers';

@inject('mediaMappings')
@observer
export class FacebookCmp extends EditableComponent {
  constructor(props) {
    super();
    if (!props.individualModal.internalState.facebookVideoId) props.individualModal.internalState.facebookVideoId = '';
    if (!props.individualModal.internalState.facebookUserId) props.individualModal.internalState.facebookUserId = '';
    if (!props.individualModal.internalState.fallbackMapping) props.individualModal.internalState.fallbackMapping = {
      enabled: false,
      videoId: null,
      error: null
    }
    this.toggleIsFallbackEnabled = this.toggleIsFallbackEnabled.bind(this);
    this.updateFallbackVideoId = this.updateFallbackVideoId.bind(this);
  }

  onFacebookVideoIdChange = e => {
    const { individualModal, setErrorMessage } = this.props;
    this.setInternalModalState(individualModal, { facebookVideoId: e.target.value }, () => setErrorMessage(null));
  };

  onFacebookUserIdChange = e => {
    const { individualModal, setErrorMessage } = this.props;
    this.setInternalModalState(individualModal, { facebookUserId: e.target.value }, () => setErrorMessage(null));
  };

  setFallbackMapping = fallbackMapping => {
    const { individualModal } = this.props;
    this.setInternalModalState(individualModal, { fallbackMapping });
  };

  toggleIsFallbackEnabled() {
    const { fallbackMapping } = this.props.individualModal.internalState;
    const { setErrorMessage } = this.props;
    const errorMessage = this.props.mediaMappings.errorMessage;
    this.addToInProgressEditsCount();
    if (errorMessage) setErrorMessage('');
    this.setFallbackMapping({ ...fallbackMapping, enabled: !fallbackMapping.enabled });
  }

  updateFallbackVideoId(fallbackVideoId) {
    this.addToInProgressEditsCount();
    const { fallbackMapping } = { ...this.props.individualModal.internalState };
    this.setFallbackMapping({ ...fallbackMapping, videoId: fallbackVideoId });
  }

  render() {
    const { activeVideoId, facebookVideoId, facebookUserId, videoId, fallbackMapping } = this.props.individualModal.internalState;
    const { setActiveVideoId } = this.props;

    return (
      <Div display='flex' flexWrap='wrap'>
        <VideoUrlText paddingTop={5}>https://www.facebook.com/</VideoUrlText>
        {
          facebookUserId || activeVideoId === 'facebookUserId'
            ?
              <VideoUrlInputCmp value={facebookUserId} onChange={this.onFacebookUserIdChange} isActive={activeVideoId === 'facebookUserId'} />
            :
              <VideoUrlText onClick={() => setActiveVideoId(this.setInternalModalState, 'facebookUserId', this.props.individualModal)} margin='4px 2px 0 2px'><Red cursor='pointer'><Bold> {'{click to set}'} </Bold></Red></VideoUrlText>
        }
        <VideoUrlText paddingTop={5}>/videos/</VideoUrlText>
        {
          facebookVideoId || activeVideoId === 'facebookVideoId'
            ?
              <VideoUrlInputCmp value={facebookVideoId} onChange={this.onFacebookVideoIdChange} isActive={activeVideoId === 'facebookVideoId'} />
            :
              <VideoUrlText onClick={() => setActiveVideoId(this.setInternalModalState, 'facebookVideoId', this.props.individualModal)} margin='4px 2px 0 2px'><Red cursor='pointer'><Bold> {'{click to set}'} </Bold></Red></VideoUrlText>
        }
        <FallbackVideoMapping
          thisVideoId={videoId}
          fallbackMapping={fallbackMapping}
          toggleIsFallbackEnabled={this.toggleIsFallbackEnabled}
          updateFallbackVideoId={this.updateFallbackVideoId}
        />
      </Div>
    );
  }
}

@inject('mediaMappings')
@observer
export class FallbackVideoMapping extends Component {
  constructor() {
    super();
    this.state = { videoIdQueryList: [] };
    this.searchVideoIds = this.searchVideoIds.bind(this);
    this.chooseVideoId = this.chooseVideoId.bind(this);
  }

  async searchVideoIds(e) {
    const { updateFallbackVideoId, thisVideoId, mediaMappings } = this.props;
    const query = e.target.value;
    updateFallbackVideoId(query);
    if (query === '') return this.setState({ videoIdQueryList: [] });
    const videoIdQueryList = await mediaMappings.searchPublisherVideoIds(query, thisVideoId);
    this.setState({ videoIdQueryList });
  }

  chooseVideoId(media) {
    this.props.updateFallbackVideoId(media.videoId);
    this.setState({ videoIdQueryList: [] });
  }

  render() {
    const { fallbackMapping, toggleIsFallbackEnabled } = this.props;
    const { videoIdQueryList } = this.state;
    return (
      <Div>
        <P paddingTop={30} paddingBottom={20}><Bold>Mobile Compatibility Fallback</Bold></P>
        <Div display='flex' alignItems='center' paddingBottom={5}>
          <Toggle checked={fallbackMapping.enabled} onChange={toggleIsFallbackEnabled} icons={false} />
          <p>{fallbackMapping.enabled ? <Bold>Enabled</Bold> : 'Disabled'}</p>
        </Div>
        {
          fallbackMapping.enabled
            ?
              <Div paddingTop={15}>
                <P paddingBottom={5}><Bold>Which video id should we fallback to for mobile devices?</Bold></P>
                <SearchMedia
                  value={fallbackMapping.videoId || ''}
                  onChange={this.searchVideoIds}
                  mediaQueryList={videoIdQueryList}
                  fallbackQuery={this.chooseVideoId}
                  mediaPropertyToDisplay='videoId'
                />
              </Div>
            :
              <MessageCmp type='error' margin='10px 0 0 0'>
                <P fontSize={12}><Bold><Italic>Warning: </Italic></Bold>
                The facebook player does not support inline play on many popular mobile browsers which is required to show the Tagflix overlay.
                We support the capability to fallback to another player as a workaround. If you do not set a fallback mapping, your video will not display the overlay on these mobile devices.
                </P>
              </MessageCmp>
        }
      </Div>
    );
  }
}
