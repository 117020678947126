import { filterPropsForStyling } from './StyleUtil';
import { colors, fontSizes } from './Constants';
import { PComponent, SpanComponent, ExtendComponentStyles } from './Global';

export const Thin = PComponent({ fontWeight: 300 });

export const TinyText = PComponent(props => ({
  fontSize: fontSizes.tiny,
  ...filterPropsForStyling(props)
}));

export const TinyThinText = PComponent(props => ({
  fontSize: fontSizes.tiny,
  fontWeight: 300,
  ...filterPropsForStyling(props)
}));

export const SmallText = PComponent(props => ({
  fontSize: fontSizes.small,
  ...filterPropsForStyling(props)
}));

export const Monospace = PComponent({ fontFamily: '"Courier New", Courier, monospace' });

export const Red = SpanComponent(props => ({
  color: colors.red,
  ...filterPropsForStyling(props)
}));

export const RedUnderline = SpanComponent(props => ({
  textDecoration: 'underline',
  color: colors.red,
  cursor: 'pointer',
  ...filterPropsForStyling(props)
}));

export const Grey = SpanComponent({ color: colors.greyText });

export const LightGrey = SpanComponent({ color: colors.lightGreyText });

export const SuperLightGrey = SpanComponent({ color: colors.superLightGreyText });

export const LightGreyText = SpanComponent({ color: colors.lightGreyText });

export const DarkPink = SpanComponent({ color: colors.darkPink });

export const TinyThinGreyText = ExtendComponentStyles(TinyThinText)({ color: colors.greyText });

export const DarkGrassGreen = SpanComponent( props => ({
  color: colors.darkGrassGreen,
  ...filterPropsForStyling(props)
}));

export const White = SpanComponent(props => ({
  color: colors.white,
  ...filterPropsForStyling(props)
}));

export const Italic = SpanComponent(props => ({
  fontStyle: 'italic',
  ...filterPropsForStyling(props)
}));

export const Bold = SpanComponent({ fontWeight: '600' });
