import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Div, P, H5 } from '../../../style/Global';
import { Bold, Italic, RedUnderline } from '../../../style/Typography';
import { PinkBox } from '../../../style/StyledCmps';
import { WhiteButtonContainer } from '../../../style/FormStyle';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';

@inject('keys', 'modal')
@observer
export class RefreshKeyModal extends Component {
  render() {
    const { keys, modal, keyIndex } = this.props;
    return (
      <Div>
        <H5 paddingBottom={15}>Are you sure you would like to generate a new api key?</H5>
        <PinkBox>
          <P><Bold><Italic>Warning:</Italic></Bold> the current key will immediately cease to function.</P>
        </PinkBox>
        <WhiteButtonContainer marginTop={25}>
          <Div marginRight={20} onClick={modal.close}><RedUnderline>Cancel</RedUnderline></Div>
          <WhiteButton icon='fa-refresh' text='Refresh' onClick={async () => {
            await keys.refreshKey(keyIndex);
            modal.close();
          }} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

@inject('keys', 'modal')
@observer
export class DisableAllKeysModal extends Component {
  render() {
    const { keys, modal } = this.props;
    return (
      <Div>
        <H5 paddingBottom={15}>Are you sure you would like to disable API access?</H5>
        <PinkBox>
          <P><Bold><Italic>Warning:</Italic></Bold> all active keys will immediately cease to function.</P>
        </PinkBox>
        <WhiteButtonContainer marginTop={25}>
          <Div marginRight={20} onClick={modal.close}><RedUnderline>Cancel</RedUnderline></Div>
          <WhiteButton icon='fa-trash-alt' text='Disable API Access' onClick={async () => {
            await keys.disableKeys();
            modal.close();
          }} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}
