import React, { Component } from 'react';
import Icon from '../FontAwesomeIconCmp';
import { DivComponent, UlComponent, LiComponent, SpanComponent, ButtonComponent, LabelComponent, ExtendComponentStyles } from '../../../style/Global';
import { colors } from '../../../style/Constants';

class FilterDropdown extends Component {
  constructor(props) {
    super()
    const { store, id, options, createObservableDropdown } = props;
    if(store.dropdownComponents[id] === undefined) createObservableDropdown(id, options[0]);
  }

  componentDidMount() {
    const { changeSelectedOption, id, options, queryParamaterOption } = this.props;
    if(options.find(option => option === queryParamaterOption)) changeSelectedOption(id, this.props.queryParamaterOption);
  }

  changeSelectedOptionInStore = event => {
    const { changeSelectedOption, toggleDropdowns, id } = this.props;
    changeSelectedOption(id, event.target.innerHTML);
    toggleDropdowns();
  };

  render() {

    const { store, id, options, toggleDropdowns, label } = this.props;
    const hasDropdownOptions = options.length > 0;
    const filteredOptions = options.filter(option => option !== store.dropdownComponents[id].selectedValue);

    return (
      <FlexContainer id={id} hasDropdownOptions={hasDropdownOptions}>
        <DropdownLabel>{label}</DropdownLabel>
        { store.disableForm ?
          (
            <DisabledButton type='button'>
              <Span color={colors.darkGreyText} margin='0 5px' id='filter-category'>{store.dropdownComponents[id].selectedValue}</Span>
              <Icon name='fa-chevron-down' color={colors.grey} margin='0 5px' />
            </DisabledButton>
          ) : (
            <DropdownButton onClick={() => toggleDropdowns(id)} type='button' active={store.dropdownComponents[id].rendered}>
              <Span color={colors.darkGreyText} margin='0 5px' id='filter-category'>{store.dropdownComponents[id].selectedValue}</Span>
              <Icon name='fa-chevron-down' color={colors.red} margin='0 5px' />
            </DropdownButton>
          )
        }
        { store.dropdownComponents[id].rendered &&
        <DropdownOptions>
          {filteredOptions.map( (option, index) => <Option onClick={ event => this.changeSelectedOptionInStore(event)} key={index}>{option}</Option> )}
        </DropdownOptions>
        }
      </FlexContainer>
    );
  }
}

const FlexContainer = DivComponent(({ hasDropdownOptions }) => ({
  position: 'relative',
  display: hasDropdownOptions ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '10px 0'
}));

const Label = LabelComponent({
  fontSize: '14px',
  color: colors.darkGreyText,
  textAlign: 'right'
});

const DropdownLabel = ExtendComponentStyles(Label)({ position: 'relative' });

const DropdownButton = ButtonComponent(({ active }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0px 10px',
  width: '269px',
  height: '26px',
  borderWidth: active ? '1px 1px 0px 1px' : '1px',
  borderStyle: 'solid',
  borderColor: colors.greyText,
  backgroundColor: active ? colors.lightGrey : colors.white,
  color: colors.darkGreyText,
  cursor: 'pointer'
}));

const DisabledButton = ExtendComponentStyles(DropdownButton)({
  backgroundColor: 'rgba(210, 210, 210, 0.5)',
  cursor: 'default'
});

const DropdownOptions = UlComponent({
  position: 'absolute',
  top: '36px',
  right: '10px',
  boxSizing: 'border-box',
  width: '269px',
  border: 0,
  borderWidth: '0px 1px 1px 1px',
  border: `solid ${colors.greyText}`,
  boxShadow: '0.5px 6px 10px -2px rgba(52, 48, 77, 0.2)',
  fontSize: '14px',
  color: colors.darkGreyText,
  backgroundColor: colors.white,
  zIndex: 2
});

const Option = LiComponent({
  display: 'flex',
  alignItems: 'center',
  color: colors.darkGreyText,
  height: '26px',
  padding: '0 5px',
  cursor: 'pointer',
  '&:hover': { backgroundColor: '#f9f9f9' }
});

const Span = SpanComponent(({ color, margin }) => ({ color, margin }));

export default FilterDropdown;
