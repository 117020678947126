export const createVideos = (length=0) => {
  const videosArray = [];
  for (let i = 0; i < length; i++) {
    videosArray.push(videoObj());
  }
  return videosArray;
};

function videoObj() {
  return {
    id: id(),
    videoName: videoName(),
    labels: labels(),
    status: status(),
    fileSizeBytes: 250000,
    uploadProgressPercent: uploadProgressPercent() //temporary prop to test upload progress bar
  };
}

function id() {
  let id = '';
  for (let i = 0; i < 32; i++) {
    if (i === 8 || i === 12 || i === 16 || i === 20) id += '-';
    id += alphaNum();
  }
  return id;
}

function videoName() {
  const rand = Math.random();
  if (rand < 0.125) return 'Boyz2Men - I\'ll Make Love To You';
  else if (rand < 0.25) return 'R. Kelly - Trapped In The Closet';
  else if (rand < 0.375) return 'Enya - Orinoco Flow (Sail Away)';
  else if (rand < 0.5) return 'Jimi Hendrix - Purple Haze';
  else if (rand < 0.625) return 'Tenacious D - Wonderboy';
  else if (rand < 0.75) return 'Divo - Whip It';
  else if (rand < 0.875) return 'Divo - Whip It';
  else return 'The Village People - YMCA';
}

function labels() {
  const labelsArray = [];
  const rand = Math.round(5 * Math.random());
  for (let i = 0; i < rand; i++) {
    labelsArray.push(`label ${i}`);
  }
  return labelsArray;
}

function status() {
  const options = [ 'Upload failed.', 'Uploading', 'Ready' ];
  return options[parseInt(Math.random() * options.length, 10)];
}

function uploadProgressPercent() {
  const rand = Math.random();
  const progressPercent = Math.round(100 * Math.random());
  if(rand <= 0.5) return progressPercent;
  return null;
}

function alphaNum() {
  const rand = Math.floor(36 * Math.random());
  return alphaNumMap[rand];
}

const alphaNumMap = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z'
];
