export default {
  'videoAssets': {
    text: 'Videos', icon: 'fa-video',
    route: '/videos'
  },
  'powerbi-standard': {
    text: 'Standard Report', icon: '',
    route: '/report/standard'
  },
  'powerbi-bespoke': {
    text: 'Bespoke Report', icon: '',
    route: '/report/bespoke'
  }
};

export const defaultCapabilities = {
  'mediaMapping': {
    text: 'Media Mapping', icon: 'fa-map-signs',
    route: '/media-mapping'
  },
  'support': {
    text: 'Support', icon: 'fa-question-circle',
    route: '/support'
  },
  'settings': {
    text: 'Settings',
    route: '/settings'
  }
};
