import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Icon from '../FontAwesomeIconCmp';
import LoadingBar from '../loadingBar/LoadingBarCmp';
import '../../../style/external/scroll.css';
import {
  ModalContainer,
  ModalBackground,
  BoxHeader,
  BOX_BODY_PADDING,
  TabsContainer,
  BoxFooter,
  Tab,
  DEFAULT_MODAL_WIDTH,
  ModalBox
} from './ModalStyle';
import { colors } from '../../../style/Constants';
import { H4, Div, DivComponent } from '../../../style/Global';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { preventPopupDismissalPropagation } from '../helpers';
import { WhiteButtonContainer } from '../../../style/FormStyle';
import { RedUnderline } from '../../../style/Typography';
import WhiteButtonCmp from '../WhiteButtonCmp';
import { MessageCmp } from '../../../style/StyledCmps';
import { findCurrentRoute, getQueryString, removeQueryParamsBasedOnRouteAvailableParams } from '../../app/QueryParamsHelper';
const MODAL_HEIGHT_PERCENTAGE = .7;

@inject('modal', 'app')
@observer
class ModalCmp extends Component {
  constructor() {
    super();
    this.closeOnEscKeyPress = this.closeOnEscKeyPress.bind(this);
  }

  closeOnEscKeyPress(e) {
    if (e.key === 'Escape') this.props.modal.close();
  }

  render() {
    const modalStore = this.props.modal;
    const currentlyOpenModals = modalStore.modals;

    if (modalStore && currentlyOpenModals.length > 0) return currentlyOpenModals.map((individualModal, i) => {
      const { activeTab, tabs, globalButtons, disableGlobalButtons } = individualModal;

      const currentRouteObj = findCurrentRoute(window.location.pathname);
      const currentRouteParent = currentRouteObj && currentRouteObj.parent;

      let buttonsForThisTab = [];
      let activeTabInformation;
      if (tabs && tabs.length) {
        activeTabInformation = tabs.filter(tab => tab.text === activeTab)[0];
        buttonsForThisTab = [...buttonsForThisTab, ...activeTabInformation.buttons];
      }
      if (globalButtons && globalButtons.length && !disableGlobalButtons) buttonsForThisTab = [...buttonsForThisTab, ...globalButtons];
      if (!individualModal.width) individualModal.width = DEFAULT_MODAL_WIDTH;
      const hasContentBelowScrollableSection = (individualModal._notice && individualModal._notice.message && individualModal._notice.type) || buttonsForThisTab.length;

      const dismissModalLink = !currentRouteParent ? `${this.props.location.pathname}${getQueryString()}` : `${currentRouteParent}${getQueryString({}, null, true)}`;
      individualModal.dismissModalLink = dismissModalLink;
      individualModal.dismissLinkFunction = this.props.history.push;

      return (
        <ModalContainer key={i} onClick={preventPopupDismissalPropagation} onKeyDown={this.closeOnEscKeyPress} tabIndex='0'>
          <ModalBackground onClick={() => {
            removeQueryParamsBasedOnRouteAvailableParams();
            modalStore.close();
          }}
          />
          <ModalBox>
            <LoadingBar width={individualModal.width} />
            <BoxHeader width='100%' zIndex={5} >
              <H4 fontSize={16}>{individualModal.title}</H4>
              <Icon onClick={() => {
                removeQueryParamsBasedOnRouteAvailableParams();
                modalStore.close();
              }} name='fa-times' color={colors.grey} size={2} cursor='pointer' />
            </BoxHeader>
            <Tabs tabs={tabs} activeTab={activeTab} modal={individualModal} />
            <ScrollableSection Component={individualModal.component} modal={individualModal} contentBelow={hasContentBelowScrollableSection} />
            <BoxFooter width='100%' zIndex={5}>
              <Messages notice={individualModal._notice} />
              <Buttons buttons={buttonsForThisTab} modal={individualModal} />
            </BoxFooter>
          </ModalBox>
        </ModalContainer>
      );
    });
    return null;
  };
};

export const ScrollableSection = ({ Component, modal, contentBelow }) => {
  if (!Component) return null;
  const { shouldShowScrollbar } = modal;
  const screenHeight = window.innerHeight;
  const maxModalHeight = screenHeight * MODAL_HEIGHT_PERCENTAGE;

  return (
    <Div display='flex' flexDirection='row' width={shouldShowScrollbar ? modal.width + 16 : modal.width} overFlow='auto' zIndex={4}>
      <OverlayScrollbarsComponent
        style={{
          width: '100%', maxHeight: maxModalHeight,
          position: 'relative',
          backgroundColor: colors.white,
          padding: BOX_BODY_PADDING,
          paddingBottom: contentBelow ? 5 : BOX_BODY_PADDING,
          paddingRight: shouldShowScrollbar ? '40px' : BOX_BODY_PADDING,
          opacity: 1,
          zIndex: 3
        }}
        options={{
          className: 'modal-os os-theme-thick-dark os-host-flexbox', resize: 'none',
          scrollbars: { clickScrolling: true },
          callbacks: { onOverflowChanged: args => modal.shouldShowScrollbar = args.yScrollable }
        }}
      >
        <Div marginBottom='20px'>
          <Component />
        </Div>
      </OverlayScrollbarsComponent>
    </Div>
  );
}

const Tabs = ({ tabs, activeTab, modal }) => {
  if (!tabs || !tabs.length) return null;
  return (
    <TabsContainer width='100%' zIndex={5}>
      {modal.tabs.map((tab, i) => <Tab active={activeTab === tab.text} key={i} onClick={() => modal.activeTab = tab.text}>{tab.text}</Tab>)}
    </TabsContainer>
  )
};

const Buttons = ({ buttons, modal }) => {
  modal.error = message => modal._notice = { type: 'error', message };
  modal.success = message => modal._notice = { type: 'success', message };
  if (!buttons.length) return null;
  return (
    <Div display='flex' justifyContent='flex-end' alignItems='center'>
      {
        buttons.map((button, i) => {
          return (
            <React.Fragment key={i}>
              {button.icon ?
                <Div width='auto' padding='15px 15px 15px 0'>
                  <WhiteButtonContainer marginTop='0px'>
                    <WhiteButtonCmp key={i} icon={button.icon} text={button.text} onClick={() => button.func(modal)} />
                  </WhiteButtonContainer>
                </Div>
                :
                <Div key={i} padding='15px 15px 15px 0' onClick={() => button.func(modal)}>
                  <RedUnderline>
                    {button.text}
                  </RedUnderline>
                </Div>
              }
            </React.Fragment>
          )
        })
      }
    </Div>
  );
};

const Messages = ({ notice }) => {
  if (!notice || !notice.type || !notice.message) return null;
  return (
    <Div width='100%'>
      <MessageContainer>
        <MessageCmp margin='0' type={notice.type}><p>{notice.message}</p></MessageCmp>
      </MessageContainer>
    </Div>
  );
};

const MessageContainer = DivComponent({
  zIndex: 5,
  backgroundColor: 'white'
});

export default withRouter(ModalCmp);
