import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import config from '../../config/config';
import Auth from '../../components/shared/AuthCmp';
import Icon from '../../components/shared/FontAwesomeIconCmp';
import { Div, P, H1, H2, Img } from '../../style/Global';
import { Italic } from '../../style/Typography';
import { Spacer } from '../../style/StyledCmps';
import {
  SupportDiv,
  LeftDiv,
  RightDiv,
  PinkDiv,
  IconAndLink,
  RedLink,
  PhoneNumberDiv,
  PhoneNumber,
  PhoneHours
} from './SupportStyle';
import tagflixLogo from '../../../assets/tagflix-logo.svg';

@inject('app')
@observer
class SupportPage extends Component {
  render() {
    const { accountManager } = this.props.app.publisher;
    return (
      <SupportDiv>
        <LeftDiv>
          <H2>Business development.</H2>
          <P marginTop={15}>Your account manager is</P>
          <PinkDiv>
            <Div flexGrow={1}>
              <Italic><H1>{accountManager.name}</H1></Italic>
              <Spacer height={25} />
              <LinkUnit icon='fa-envelope-open' text={accountManager.email} email/>
              <LinkUnit icon='fab fa-telegram' text='Submit zendesk ticket' href={config.supportTicketUrl} />
              <PhoneUnit icon='fa-phone-square' phone={accountManager.phone}
                text='Your timezone, business hours only.' />
            </Div>
            <Img width={107} src={tagflixLogo} />
          </PinkDiv>
        </LeftDiv>

        <RightDiv>
          <H2 margin='0 0 15px 0'>General platform and portal.</H2>
          <LinkUnit icon='fa-book' text='Online documentation' href={config.documentationUrl.integration} />
          <LinkUnit icon='fab fa-telegram' text='Submit zendesk ticket' href={config.supportTicketUrl} />
          <LinkUnit icon='fa-envelope-open' text='platform.publisher@tagflix.com' email/>
          <H2 margin='35px 0 15px 0'>API related.</H2>
          <LinkUnit icon='fa-book' text='Online documentation' href={config.documentationUrl.api} />
          <LinkUnit icon='fa-envelope-open' text='platform.api@tagflix.com' email/>
          <PhoneUnit icon='fa-phone-square' phone='+1 415 580 0846'
            text='PST timezone, business hours only.' />
        </RightDiv>
      </SupportDiv>
    );
  }
}

const LinkUnit = ({ icon, text, href, email }) => {
  let link = text;
  if (email) link = <a href={`mailto:${text}`}>{text}</a>;
  else if (href) link = <a href={href} target='_blank'>{text}</a>;
  return (
    <IconAndLink>
      <Icon name={icon} lg/>
      <RedLink>{link}</RedLink>
    </IconAndLink>
  );
};

const PhoneUnit = ({ icon, phone, text }) => (
  <PhoneNumberDiv>
    <Icon name={icon} lg/>
    <PhoneNumber>
      {phone}
      <PhoneHours>{text}</PhoneHours>
    </PhoneNumber>
  </PhoneNumberDiv>
);

export default Auth(SupportPage);
