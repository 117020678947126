import React from 'react';
import { P, Div } from '../../../style/Global';
import { fontSizes } from '../../../style/Constants';
import { Bold, RedUnderline } from '../../../style/Typography';
import { GreyBox } from '../../../style/StyledCmps';
import { EmbedProperties } from '../MediaMappingStyle';
import EditableComponent from '../../../components/shared/EditableComponent';

class EmbedPropertiesContainer extends EditableComponent {
  render() {
    const { embedPropertiesList, resetEmbedValues, title } = this.props;
    return ( <GreyBox textAlign='left' width='100%' marginTop={10}>
      <P paddingBottom={10}><Bold>Selected {title} Video</Bold></P>
      { embedPropertiesList.map(({ name, value }, index) => <EmbedProperties key={index} paddingLeft={10}>{name}: <Bold>{ value }</Bold> </EmbedProperties> )}
      <Div
          width='100%'
          textAlign='right'
          marginTop={10}>
        <RedUnderline
          fontSize={fontSizes.small}
          onClick={() => {
            this.addToInProgressEditsCount()
            resetEmbedValues();
          }}>Re-enter {title.toLowerCase()} iframe embed code
        </RedUnderline>
      </Div>
    </GreyBox>
  )}
};

export default EmbedPropertiesContainer;
