import { InputComponent, ButtonComponent, DivComponent, PComponent, ExtendComponentStyles } from './Global';
import { colors } from './Constants';

export const Input = InputComponent({
  backgroundColor: colors.white,
  color: colors.darkGreyText,
  padding: '0 4px',
  border: 'none',
  boxSizing: 'border-box',
  fontSize: 12
});

export const Button = ButtonComponent({
  padding: 0,
  border: 'none',
  boxSizing: 'border-box',
  cursor: 'pointer'
});

export const RedButton = ExtendComponentStyles(Button)({
  color: colors.white,
  fontSize: 18,
  backgroundColor: colors.red,
  padding: '12px 50px'
});

export const WhiteButton = ExtendComponentStyles(Button)(({ width, margin }) => ({
  width: width || null,
  margin: margin || null,
  color: '#bebebe',
  backgroundColor: colors.white,
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  padding: 11,
  fontWeight: 300,
  border: `1px solid ${colors.lightGreyBorder}`
}));

export const WhiteButtonContainer = DivComponent(({ marginTop = 20 }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop
}));

export const UnderlinedInput = ExtendComponentStyles(Input)(({ valid = true }) => ({
  height: 43,
  backgroundColor: colors.superLightGrey,
  borderBottom: valid ? `1px solid ${colors.grey}` : `2px solid ${colors.red}`,
  width: '100%',
  padding: '0 15px',
  fontSize: 16
}));

export const BorderedInput = ExtendComponentStyles(Input)(({ marginTop = 5, margin = 0, padding = '0 10px', width = '100%', borderColor = `${colors.grey}`, backgroundColor = colors.white }) => ({
  width,
  height: 26,
  border: `1px solid ${borderColor}`,
  padding,
  marginTop,
  margin,
  fontSize: 14,
  backgroundColor
}));

export const BorderedText = PComponent({
  width: '100%',
  backgroundColor: colors.superLightGreyText,
  padding: '3px 10px',
  border: `1px solid ${colors.grey}`,
  fontSize: 14
});

export const FieldDiv = DivComponent({
  width: '100%',
  marginTop: 15
});
