import { observable, action } from 'mobx';
import qs from 'qs';
import { limitStringLength } from '../../util/TextUtil';
import { REMOVE_VIDEO_ID_TIMEOUT } from '../../config/TimeoutConfig';
import { REQUESTED_RECORDS_LIMIT } from '../../util/ApiUtil';
import API from '../../util/ApiUtil';

class VideoStore {
  @observable videoName = '';
  @observable id = '';
  @observable videoList = [];
  @observable pagination = {};
  @observable videoToUpload = {};
  @observable labels = [];
  @observable recentlySavedVideoIds = [];
  @observable recentlyUpdatedVideoIds = [];
  @observable deletedVideoIds = [];
  @observable usedStorageBytes = 0;
  @observable pages = {};
  @observable isLoading = false;

  @action setLabels = labelsArray => this.labels = labelsArray;
  @action setVideoName = videoName => this.videoName = videoName;
  @action setVideoId = id => this.id = id;
  @action addVideoToUpload = file => this.videoToUpload = file;
  @action removeVideoToUpload = () => this.videoToUpload = {};
  @action setPagesArray = pages => { this.pages = pages; };
  @action getVideoAssets = async (options = { limit: 10, skip: 0 }) => await API.get(`portal/publisher/${API.publisherId()}/videos?${qs.stringify(options)}`);

  @action tableQuery = async options => {
    this.isLoading = true;
    const response = await this.getVideoAssets(options);
    const onlyValidRecords = validRecordFilter(response.data);
    this.videoList = onlyValidRecords.map(videoListing => ({ ...videoListing, videoName: limitStringLength(videoListing.videoName, 150) }));
    this.pagination = response.meta.pagination;
    this.isLoading = false;
  };

  @action tableFilterOptions = () => ([
    {
      input: {
        label: 'Label',
        placeholder: 'Music Video',
        icon: 'filter'
      }
    },
    {
      dropdown: {
        options: ['All', 'Upload Failed', 'Ready'],
        label: 'Status'
      }
    }
  ]);

  @action tablePagination = () => this.pagination;

  @action tableSetSkipIndex = skipIndex => this.tableQuery(this.getFilterParamsUsingEvent(skipIndex));

  @action getFilterParamsUsingEvent = skip => ({ ...this.filterParams, skip });

  @action filterRecords(event) {
    const inputValue = document.querySelector('.filter-string').value;
    let dropdownValue = document.getElementById('filter-category').innerHTML.toLowerCase();
    const indexToSkipTo = event ? event.target.attributes[0].value : 0;

    if (dropdownValue === 'upload failed') dropdownValue = 'uploading';
    const filterTemplate = this.filterRecordsTemplate({ inputValue, dropdownValue });

    return ({ skip: indexToSkipTo, filterParams: { limit: REQUESTED_RECORDS_LIMIT, ...filterTemplate } });
  }

  @action filterRecordsTemplate = (options = {}) => ({
    ...options.inputValue && { label: options.inputValue },
    ...options.dropdownValue && { status: options.dropdownValue }
  });


  @action updateVideo = async data => {
    const response = await API.put(`portal/publisher/${API.publisherId()}/video`, { data: { ...data, pubId: API.publisherId() } });
    if (response.status === 200) {
      this.addIdToRecentlyUpdatedVideoIds(data.videoId);
      setTimeout(() => this.removeVideoIdFromRecentlySavedVideoIds(data.videoId), REMOVE_VIDEO_ID_TIMEOUT);
    }
    return response.status;
  };

  @action deleteVideo = async videoId => {
    const response = await API.delete(
      `portal/publisher/${API.publisherId()}/video`,
      { data: { pubId: API.publisherId(), videoUploadId: videoId } }
    );
    if (response.status === 204) this.deletedVideoIds.push(videoId);
    this.getUsedStorageBytes();
    return response.status;
  };

  @action addIdToRecentlySavedVideoIds = videoIdToAdd => this.recentlySavedVideoIds.push(videoIdToAdd);
  @action addIdToRecentlyUpdatedVideoIds = videoIdToAdd => this.recentlyUpdatedVideoIds.push(videoIdToAdd);
  @action removeVideoIdFromRecentlySavedVideoIds = videoIdToRemove => {
    if (this.recentlySavedVideoIds.length <= 0 || !this.recentlySavedVideoIds.includes(videoIdToRemove)) return;
    this.recentlySavedVideoIds = [ ...this.recentlySavedVideoIds ].filter(id => id !== videoIdToRemove);
  };

  @action removeVideoIdFromRecentlyUpdatedVideoIds = videoIdToRemove => {
    if (this.recentlyUpdatedVideoIds.length <= 0 || !this.recentlyUpdatedVideoIds.includes(videoIdToRemove)) return;
    this.recentlyUpdatedVideoIds = [ ...this.recentlyUpdatedVideoIds ].filter(id => id !== videoIdToRemove);
  };

  @action removeVideoIdFromDeletedVideoIds = videoIdToRemove => {
    const updatedVideoList = this.videoList.filter(video => !this.deletedVideoIds.includes(video.uploadVideoId));
    this.videoList = updatedVideoList;
    this.deletedVideoIds = [ ...this.deletedVideoIds ].filter(id => id !== videoIdToRemove);
  };

  @action dismissHighlight = videoListing => {
    if (videoListing.uploadProgressPercent === 100) {
      delete videoListing.uploadProgressPercent;
      videoListing.status = 'ready';
    }
    this.removeVideoIdFromRecentlySavedVideoIds(videoListing.uploadVideoId);
    this.removeVideoIdFromRecentlyUpdatedVideoIds(videoListing.uploadVideoId);
    this.removeVideoIdFromDeletedVideoIds(videoListing.uploadVideoId);
  };

  @action clearDeletedVideoIds = () => {
    this.videoList = this.videoList.filter(video => !this.deletedVideoIds.includes(video.id));
    this.deletedVideoIds = [];
  };

  @action getUsedStorageBytes = async () => {
    const response = await this.getVideoAssets({ limit: REQUESTED_RECORDS_LIMIT, skip: this.pages.pagination.currentPage });
    this.usedStorageBytes = response.meta.usedStorageBytes;
  };

  @action clearVideoUploadData = () => {
    this.videoToUpload = {};
    this.videoName = '';
    this.labels = [];
  };

  @action updateUploadProgress = (progress, videoId) => {
    for (let i = 0; i < this.videoList.length; i++) {
      if (this.videoList[i].uploadVideoId === videoId) return this.videoList[i].uploadProgressPercent = progress;
    }
  };

  @action updateVideoState = (state, videoId) => {
    for (let i = 0; i < this.videoList.length; i++) {
      if (this.videoList[i].uploadVideoId === videoId) return this.videoList[i].status = state.message;
    }
  };

  @action populatePageWithUploadedVideo = metadata => {
    const file = {
      fileSizeBytes: metadata.videoToUpload.size,
      labels: this.labels,
      videoName: this.videoName,
      status: 'start upload',
      uploadProgressPercent: 0,
      uploadVideoId: metadata.uploadVideoId
    };
    this.videoList.unshift(file);
  };
}

const validRecordFilter = records => {
  if (!records) return;
  return records.filter(video => {
    if (video.videoName) return video;
    console.warn('Ignored invalid video asset to non-existing video:', video);
  });
};

export default VideoStore;
