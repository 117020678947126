import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import 'react-select/dist/react-select.min.css';
import '../../style/external/select.css';
import '../../style/external/toggle.css';
import routes from '../../config/RouteConfig';
import { SubRoutes } from '../../util/RouteUtil';
import { getUserToken, getSelectedPublisherId, saveLastMenuOption } from '../../util/PersistedStateUtil';
import InteriorCmp from '../interior/InteriorCmp';
import HomePage from '../../pages/home/HomePage';
import Modal from '../shared/modal/ModalCmp';
import CookiePolicy from '../shared/CookiePolicyCmp';
import { Div } from '../../style/Global';
import { TextBubble } from '../shared/InfoBubbleCmp';
import QueryParamsHelper from './QueryParamsHelper';

@inject('app')
@observer
class App extends Component {

  constructor() {
    super();
    const queryParams = window.location.search;
    const lastMenuOption = queryParams ? `${window.location.pathname}${queryParams}` : window.location.pathname;
    QueryParamsHelper(window.location.search);
    saveLastMenuOption(lastMenuOption);
  }
  async componentDidMount() {
    const { app } = this.props;
    app.addExitAppConfirmationListener();
    document.addEventListener('click', this.onClick);

    if (!getSelectedPublisherId()) return app.logoutUser();
    const userToken = getUserToken();

    if (userToken) await app.prepareUserFromUserToken(userToken);
  }

  componentWillUpdate(prevProps) {
    const { history, app, location } = this.props;
    QueryParamsHelper(window.location.search);
    if (prevProps.location.pathname === location.pathname) return;
    if (history.action !== 'POP') return;
    app.setRedirectPathname(prevProps.location.pathname);
  }

  onClick = () => {
    const { app } = this.props;
    app.closePopups()
  }

  renderRedirectComponent() {
    const { redirectToPathname } = this.props.app;
    if (redirectToPathname.shouldRedirect) {
      redirectToPathname.shouldRedirect = false;
      if (redirectToPathname.pathname) return <Redirect to={redirectToPathname.pathname} />;
    }
    return null;
  }

  render() {
    const { showCookiePolicyModal, hideCookiePolicyModal } = this.props.app;
    const desiredUrl = this.props.app.redirectToPathname.pathname;

    return (
      <Div>
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route path='/accept-invite' component={HomePage} />
          <Route path='/reset-password' component={HomePage} />
          <InteriorRoutes currentRoute={desiredUrl} />
        </Switch>
        {this.renderRedirectComponent()}
        {this.props.app.infoBubble && this.props.app.infoBubble.isHovered && <TextBubble top={this.props.app.infoBubble.y} left={this.props.app.infoBubble.x} height={this.props.app.infoBubble.height} width={this.props.app.infoBubble.width} />}
        <Modal />
        {showCookiePolicyModal && <CookiePolicy hide={hideCookiePolicyModal} />}
      </Div>
    );
  }
}

const InteriorRoutes = ({ currentRoute }) => {
  return (
    <InteriorCmp currentRoute={currentRoute}>
      {SubRoutes(routes)}
    </InteriorCmp>
  );
}

export default withRouter(App);
