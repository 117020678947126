import { observable, action } from 'mobx';
import qs from 'qs';
import { limitStringLength } from '../../util/TextUtil';
import API, { REQUESTED_RECORDS_LIMIT } from '../../util/ApiUtil';
import { REMOVE_VIDEO_ID_TIMEOUT } from '../../config/TimeoutConfig';
import { getSupportedPlayerList } from './MediaMappingHelpers';

export const DEFAULT_RESULTS_PER_PAGE = 10;

class MediaMappingStore {
  @observable mappings = [];
  @observable pagination = {};
  @observable isLoading = false;
  @observable errorMessage = null;

  @action tableFilterOptions = () => ([
    {
      input: {
        label: 'Filter',
        placeholder: 'Media name',
        icon: 'filter'
      }
    },
    {
      dropdown: {
        options: [ 'All', ...getSupportedPlayerList(), 'Disabled' ],
        label: 'Player'
      }
    }
  ]);

  @action tablePagination = () => this.pagination;

  @action tableSetSkipIndex = options => this.tableQuery(options);

  @action getMediaMappings = async (options = { limit: DEFAULT_RESULTS_PER_PAGE, skip: 0 }) => await API.get(`portal/publisher/${API.publisherId()}/media?${qs.stringify(options)}`);

  @action tableQuery = async options => {
    this.isLoading = true;
    const response = await this.getMediaMappings(options);
    if(response.status === 200) {
      const onlyValidRecords = validRecordFilter(response.data);
      this.mappings = onlyValidRecords.map(mapping => ({ ...mapping, videoName: limitStringLength(mapping.mediaName, 150) }));
      this.pagination = response.meta.pagination;
      this.isLoading = false;
    };
  };

  @action filterRecords = (event, queryMediaName, queryVideoPlayer) => {
    let mediaName;
    let videoPlayer;

    mediaName = document.querySelector('.filter-string') ? document.querySelector('.filter-string').value : null;
    videoPlayer = document.getElementById('filter-category') ? document.getElementById('filter-category').innerHTML.toLowerCase() : 'all';

    if(queryMediaName) mediaName = queryMediaName;
    if(queryVideoPlayer) videoPlayer = queryVideoPlayer;

    const indexToSkipTo = event && event.target ? event.target.attributes[0].value : 0;
    let isOverlayEnabled = true;

    if (videoPlayer === 'all') videoPlayer = '';
    if (videoPlayer === 'disabled') {
      isOverlayEnabled = false;
      videoPlayer = null;
    };

    const filterTemplate = this.filterRecordsTemplate({ mediaName, videoPlayer, isOverlayEnabled });

    return {
      filterParams: {
        limit: REQUESTED_RECORDS_LIMIT,
        ...filterTemplate
      },
      skip: indexToSkipTo
    };
  };

  @action filterRecordsTemplate = (options = {}) => ({
    mediaName: options.mediaName || null,
    videoPlayer: options.videoPlayer || null,
    isOverlayEnabled: options.isOverlayEnabled
  });

  @action setNewMappingOverlayProperties = (videoId, properties) => {
    for(let property in properties) {
      const value = properties[property];
      this.mappings.find(mapping => mapping.videoId === videoId)[property] = value;
    };
  };

  @action setErrorMessage = errorMessage => this.errorMessage = errorMessage;

  @action searchPublisherVideoIds = async (query, excludeThisVideoId) => {
    const data = { videoId: query, isOverlayEnabled: true, videoPlayer: 'youtube', limit: 5 };
    const response = await this.getMediaMappings(data);
    return response.data.filter(media => media.videoId !== excludeThisVideoId);
  };

  @action switchIsEnabledValue = ({ propertyToSwitch, newValue }) => async videoId => {
    const data = { publisherVideoId: videoId };
    data[propertyToSwitch] = newValue;
    const response = await API.put('portal/media', { data: { ...data, pubId: API.publisherId() } });
    if (response.status === 200) {
      const updatedMappings = this.mappings.map(mapping => {
        if (videoId === mapping.videoId) {
          const newMapping = { ...mapping };
          newMapping[propertyToSwitch] = newValue;
          return newMapping;
        };
        return mapping;
      });
      this.mappings = updatedMappings;
      this.markVideoIdSaved(videoId);
    };
    return response.status;
  };

  @action disableOverlay = this.switchIsEnabledValue({ propertyToSwitch: 'isOverlayEnabled', newValue: false });
  @action enableOverlay = this.switchIsEnabledValue({ propertyToSwitch: 'isOverlayEnabled', newValue: true });

  @observable deletedVideoIds = [];
  @action delete = async videoId => {
    const response = await API.delete('portal/media', { data: { publisherVideoId: videoId, pubId: API.publisherId() } });
    if (response.status === 204) this.deletedVideoIds.push(videoId);
    return response.status;
  };

  @action removeVideoIdFromDeletedVideoIds = videoIdToRemove => {
    const updatedMappings = this.mappings.filter(mapping => {
      return !this.deletedVideoIds.includes(mapping.videoId);
    });
    this.mappings = updatedMappings;
    this.deletedVideoIds = [ ...this.deletedVideoIds ].filter(id => id !== videoIdToRemove);
  };

  @action clearDeletedVideoIds = () => {
    const updatedMappings = this.mappings.filter(mapping => {
      return !this.deletedVideoIds.includes(mapping.videoId);
    });
    this.mappings = updatedMappings;
    this.deletedVideoIds = [];
  };

  @observable recentlySavedVideoIds = [];
  @action markVideoIdSaved = (videoId, mapping) => {
    this.recentlySavedVideoIds.push(videoId);
    setTimeout(() => this.removeVideoIdFromRecentlySavedVideoIds(videoId), REMOVE_VIDEO_ID_TIMEOUT);

    const mappingToUpdate = this.mappings.find(mapping => mapping.videoId === videoId);

    if (!mapping) return;
    mappingToUpdate.videoName = mapping.videoName;
    mappingToUpdate.mediaName = mapping.videoName;
    mappingToUpdate.videoPlayer = mapping.player;
  };

  @action removeVideoIdFromRecentlySavedVideoIds = videoIdToRemove => {
    if (this.recentlySavedVideoIds.length === 0 || !this.recentlySavedVideoIds.includes(videoIdToRemove)) return;
    this.recentlySavedVideoIds = [ ...this.recentlySavedVideoIds ].filter(id => id !== videoIdToRemove);
  };

  @action clearRecentlySavedVideoIds = () => this.recentlySavedVideoIds = [];

  @action dismissHighlight = videoId => {
    this.removeVideoIdFromRecentlySavedVideoIds(videoId);
    this.removeVideoIdFromDeletedVideoIds(videoId);
  };
};

const validRecordFilter = records => {
  if (!records) return;
  return records.filter(mapping => {
    if (mapping.mediaName) return mapping;
    console.warn('Ignored invalid media mapping to non-existing media:', mapping);
  });
};
export default MediaMappingStore;
