import { observable, action } from 'mobx';
import API from '../../../util/ApiUtil';

class KeysStore {
  @observable keys = [];
  @action getKeys = async () => {
    const response = await API.get('portal/api');
    if (response.status === 200) {
      const { activeKeys } = response.data;
      if (activeKeys > 0) this.enableKeys();
    }
    else {
      this.setErrorMessage('Unable to determine your API key status.');
    }
  };

  @action getApiKey = async keyIndex => await API.get(`portal/api/${keyIndex}`);
  @action deleteApiKey = async keyIndex => await API.delete(`portal/api/${keyIndex}`);

  @observable enabled = false;
  @action enableKeys = async () => {
    const responses = await Promise.all([ this.getApiKey(1), this.getApiKey(2) ]);
    const success = responses.reduce((acc, response) => acc && response.status === 200, true);
    if (success) {
      this.enabled = true;
      this.keys = responses.map(response => response.data.key);
      this.clearErrorMessage();
    }
    else {
      this.setErrorMessage('We couldn\'t generate your api keys at this stage.');
      this.enabled = false;
    };
  };

  @action disableKeys = async () => {
    const response = await this.deleteApiKey('all');
    if(response.status !== 204) return this.setErrorMessage('We couldn\'t disable your api access at this stage.');
    this.enabled = false;
  };

  @action refreshKey = async keyIndex => {
    await this.deleteApiKey(keyIndex);
    const response = await this.getApiKey(keyIndex);
    if(response.status !== 200) return this.setErrorMessage('We couldn\'t refresh your api key at this stage.');
    const newKey = response.data.key;
    const newKeys = [ ...this.keys ];
    newKeys[keyIndex - 1] = newKey;
    this.keys = newKeys;
  };

  @observable errorMessage = '';
  @action setErrorMessage = message => this.errorMessage = `${message} Please try again later.`;
  @action clearErrorMessage = () => this.errorMessage = '';
}

export default KeysStore;
