import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Icon from '../FontAwesomeIconCmp';
import { colors } from '../../../style/Constants';
import { DivComponent, SpanComponent } from '../../../style/Global';
import { REQUESTED_RECORDS_LIMIT } from '../../../util/ApiUtil';
import { PageNumberHighlighted, PageNumber } from '../Pagination/PaginationComponents';
import { ExtraPages } from './Helpers';
import { preventPopupDismissalPropagation } from '../helpers';
import { getQueryString, getQueryParamaters } from '../../app/QueryParamsHelper';

class Pagination extends Component {
  constructor() {
    super();
    this.renderPageNumber = this.renderPageNumber.bind(this);
  }

  renderPageNumber(event) {
    preventPopupDismissalPropagation(event)
    const skipToIndex = event.target.attributes[0].value;
    this.props.renderPageNumber(skipToIndex);
  }

  render() {
    const { isLoading } = this.props;
    let { pagination } = this.props;
    if (isLoading) pagination = { currentPage: 1, last: { page: 1, skip: 0 }, pages: [{ page: 1, skip: 0 }] };
    const { currentPage, last } = pagination;
    const path = this.props.match.path.split('/')[1];
    const queryParamaters = getQueryParamaters();

    return (
      <Container>
        {currentPage > 1 ?
          (
            <Link to={`${window.location.pathname}${getQueryString({
              mediaMappingPage: currentPage - 1,
              ...(queryParamaters.videoPlayer && { videoPlayer: queryParamaters.videoPlayer }) ,
              ...(queryParamaters.mediaName && { mediaName: queryParamaters.mediaName })
            })}`}>
              <PaginateDiv>
                <Icon name='fa-chevron-left' color={colors.red}></Icon>
                <PaginateLabel direction='left'>Previous</PaginateLabel>
              </PaginateDiv>
            </Link>
          ) : (
            <PaginateDiv disabled>
              <Icon name='fa-chevron-left' color={colors.lightGreyText}></Icon>
              <PaginateLabel direction='left'>Previous</PaginateLabel>
            </PaginateDiv>
          )
        }
        <PageNumbersContainer>
          {renderPageNumbers(pagination, this.renderPageNumber, path)}
        </PageNumbersContainer>
        {currentPage !== last.page ?
          (
            <Link to={`${window.location.pathname}${getQueryString({
              mediaMappingPage: currentPage + 1,
              ...(queryParamaters.videoPlayer && { videoPlayer: queryParamaters.videoPlayer }),
              ...(queryParamaters.mediaName && { mediaName: queryParamaters.mediaName })
            })}`}>
              <PaginateDiv className='nextPageButton'>
                <PaginateLabel direction='right'>Next</PaginateLabel>
                <Icon name='fa-chevron-right' color={colors.red}></Icon>
              </PaginateDiv>
            </Link>
          ) : (
            <PaginateDiv disabled>
              <PaginateLabel direction='right'>Next</PaginateLabel>
              <Icon name='fa-chevron-right' color={colors.lightGreyText}></Icon>
            </PaginateDiv>
          )
        }
      </Container>
    );
  }
}

const renderPageNumbers = pagination => {
  const { currentPage, first, last, pages } = pagination;
  const queryParamaters = getQueryParamaters();
  const totalVisiblePages = pages.length;
  const pageNumbers = [...pages];
  const shouldShowEllipsis = () => pageNumbers.length >= totalVisiblePages;
  const shouldShowRightEllipsis = () => last.page - 3 >= pageNumbers[totalVisiblePages - 1].page;
  const shouldShowLeftEllipsis = () => pageNumbers[0].page > 3;
  const addPageToStart = page => pageNumbers.unshift({ page, skip: (page * REQUESTED_RECORDS_LIMIT) - REQUESTED_RECORDS_LIMIT });
  const addPageToEnd = page => pageNumbers.push({ page, skip: (page * REQUESTED_RECORDS_LIMIT) - REQUESTED_RECORDS_LIMIT });

  if (last.page > pageNumbers.length) {
    if (shouldShowEllipsis()) {
      if (currentPage <= last.page - totalVisiblePages + 1) addPageToEnd(shouldShowRightEllipsis() ? '...' : last.page - 1);
      if (currentPage <= last.page - totalVisiblePages + 2) addPageToEnd(last.page);
      if (currentPage >= totalVisiblePages) addPageToStart(shouldShowLeftEllipsis() ? '...' : first.page + 1);
    }
    if (currentPage >= totalVisiblePages - 1) addPageToStart(first.page);
  }
  const pageNumberElements = pageNumbers.map((pageItem, index) => {
    if (pageItem.page === '...') return <ExtraPages key={index}>{pageItem.page}</ExtraPages>;
    if (pageItem.page === currentPage) return <PageNumberHighlighted key={index} data-skip={pageItem.skip}>{pageItem.page}</PageNumberHighlighted>;

    return (
      <Link to={`${window.location.pathname}${getQueryString({
        mediaMappingPage: pageItem.page,
        ...(queryParamaters.videoPlayer && { videoPlayer: queryParamaters.videoPlayer }),
        ...(queryParamaters.mediaName && { mediaName: queryParamaters.mediaName })
      })}`} key={index}>
        <PageNumber data-skip={pageItem.skip}>{pageItem.page}</PageNumber>
      </Link>
    );
  });
  return pageNumberElements;
};

const Container = DivComponent({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '35px',
  backgroundColor: `${colors.lightGrey}`
});

const PaginateDiv = DivComponent(({ disabled }) => ({
  color: disabled ? colors.lightGreyText : colors.greyText,
  cursor: disabled ? 'default' : 'pointer',
  '&:hover': { color: disabled ? colors.lightGreyText : colors.red },
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0px 15px',
  fontSize: '11px'
}));

const PaginateLabel = SpanComponent(({ direction }) => ({
  margin: direction === 'left' ? '0px 0px 0px 5px' : '0px 5px 0px 0px',
  color: 'inherit'
}));

const PageNumbersContainer = DivComponent({
  display: 'flex',
  justifyContent: 'center',
  width: '327px'
});

export default withRouter(Pagination);
