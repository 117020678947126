export const convertFileSizeUnits = (value, convertFrom, convertTo) => {
  const base = 1000,
  sizes = [ 'bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb' ],
  i = sizes.indexOf(convertTo.toLowerCase()) - sizes.indexOf(convertFrom.toLowerCase()); 
  return value / Math.pow(base, i);
};

export function formatBytes(bytes,decimals) {
  if(bytes === 0) return '0 Bytes';
  const base = 1000,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ],
    i = Math.floor(Math.log(bytes) / Math.log(base));
  return `${(bytes / Math.pow(base, i)).toFixed(dm)} ${sizes[i]}`;
} 
