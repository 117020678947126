import config from '../../config/config';

const Helper = {
  determineHeaders: (options = {}) => {
    if(!options.contentType) options.contentType = 'application/json';
    const headers = new Headers();
    headers.append('Content-Type', options.contentType);
    if(options.token) headers.append('Authorization', `Bearer ${options.token}`);
    return headers;
  },
  determineCdnSrcUrl: publisherId => {
    const domainHasPortNumber = () => /:[0-9]{4}/.test(config.publisherSolutionDomain);
    if(domainHasPortNumber()) return `${config.publisherSolutionDomain}/${publisherId}/TagflixPlayer.html`;
    if(publisherId === config.tagflixPublisherId) publisherId += `-${config.environment === 'development' ? 'uat' : config.environment}`;
    return `${config.publisherSolutionDomain}/dist/Overlay/${publisherId}/TagflixPlayer.html`;
  },
  objectToQueryString: obj => Object.keys(obj).filter(
      key => obj[key] !== '' && obj[key] !== null
    ).map((key, index) => {
      const startWith = index === 0 ? '?' : '&';
      return `${startWith}${key}=${encodeURIComponent(obj[key])}`;
  }).join(''),
  genericRequest: async function(method, url, options = {}){
    const headers = this.determineHeaders(options);

    let requestOptions = {};
    switch(method){
      case 'POST': case 'PUT': case 'DELETE':
        if(options.contentType === 'application/json') requestOptions = { body: JSON.stringify(options.params) };
      break;
      default: // GET
        if(options.params) url += this.objectToQueryString(options.params);
    }

    const response = await fetch(url, { headers, method, ...requestOptions });
    const responseOutput = { statusCode: response.status };

    if(response.status === 204) return { json: {}, ...responseOutput };

    if(!response.ok) return {
      json: { data: {}, status: { ok: false, error: response.statusText } },
      ...responseOutput
    };

    return { json: await response.json(), ...responseOutput };
  }
};

export default Helper;
