import { colors } from '../../style/Constants';
import { DivComponent, PComponent, H2Component } from '../../style/Global';

export const SupportDiv = DivComponent({
  display: 'flex',
  padding: 35
});

export const LeftDiv = DivComponent({
  flexGrow: 3,
  minWidth: 360
});

export const RightDiv = DivComponent({
  flexGrow: 2,
  minWidth: 250,
  paddingLeft: 30,
  borderLeft: `2px solid ${colors.lightGrey}`
});

export const PinkDiv = DivComponent({
  display: 'flex',
  minWidth: 360,
  maxWidth: 500,
  alignItems: 'center',
  marginTop: 15,
  padding: 20,
  backgroundColor: colors.lightPink
});

export const IconAndLink = DivComponent({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 12
});

export const RedLink = PComponent({
  paddingLeft: 10,
  color: colors.red,
  textDecoration: 'underline',
  cursor: 'pointer'
});

export const PhoneNumberDiv = DivComponent({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: 30
});

export const PhoneNumber = DivComponent({
  position: 'relative',
  marginLeft: 10
});

export const PhoneHours = PComponent({
  position: 'absolute',
  minWidth: 250,
  left: 0,
  top: 22,
  color: colors.greyText,
  fontStyle: 'italic',
  fontWeight: 300
});

export const GeneralTitle = H2Component({ marginBottom: 15 });

export const ApiTitle = H2Component({ margin: '35px 0 15px 0' });
