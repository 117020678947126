import React, { Component } from 'react';
import { Div, DivComponent } from '../../style/Global';
import Icon from './FontAwesomeIconCmp';
import { colors } from '../../style/Constants';
import { inject, observer } from 'mobx-react';
import { Italic } from '../../style/Typography';
import ReactDOM from 'react-dom';
@inject('app')
@observer
class InfoBubble extends Component {
  onMouseEnter = () => {
    if(this.props.app.infoBubble) this.props.app.infoBubble = null;
    const node = ReactDOM.findDOMNode(this).getBoundingClientRect();
    this.props.app.infoBubble = ({ x: node.x + window.pageXOffset, y: node.y + window.pageYOffset, width: this.props.width, height: this.props.height, message: this.props.children, isHovered: true });
  }

  onMouseLeave = () => { if(this.props.app.infoBubble) this.props.app.infoBubble.isHovered = false }

  render() {
    const { margin = '0 0 0 8px' } = this.props;
    const name = 'fa-info-circle';
    return (
      <Div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} borderRadius='100%' >
        <Icon name={name} color={colors.grey} margin={margin} cursor='pointer'/>
      </Div>
    );
  }
}

@inject('app')
@observer
export class TextBubble extends Component {

  onMouseEnter = () => { if(this.props.app.infoBubble) this.props.app.infoBubble.isHovered = true }

  onMouseLeave = () => { if(this.props.app.infoBubble) this.props.app.infoBubble.isHovered = false }

  render() {
    const { width, height, top, left } = this.props;
    return(
      <React.Fragment >
        <RoundedRectangle top={top - height - 22} left={left * .80} height={height} width={width}/>
        <HoverDiv top={top - 37} left={left - 43} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}/>
        <WhiteDiv top={top - 52} left={left - 8}/>
        <Triangle onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} top={top - 37} left={left}/>
        <Text onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} top={top - height - 22} left={left * .80} height={height} width={width}><Italic width='94%'>{this.props.app.infoBubble.message}</Italic></Text>
      </React.Fragment>
    )
  }
}

const RoundedRectangle = DivComponent(({ top, left, height = 68, width = 423  }) => ({
  height,
  width,
  borderRadius: 12,
  position: 'absolute',
  top,
  left,
  backgroundColor: colors.white,
  boxShadow: '0 20px 40px 0 rgba(52, 48, 77, 0.2), 0 4px 8px 0 rgba(41, 49, 67, 0.4)',
  zIndex: 10
}));

const Triangle = DivComponent(({ top, left }) => ({
  width: 30,
  height: 30,
  position: 'absolute',
  transform: 'rotate(45deg)',
  top,
  left,
  backgroundColor: colors.white,
  boxShadow: '5px 5px 10px 0 rgba(52, 48, 77, 0.01), 0 4px 8px 0 rgba(41, 49, 67, 0.4)',
  zIndex: 10
}));

const HoverDiv = DivComponent(({ top, left }) => ({
  width: 120,
  height: 50,
  top,
  left,
  position: 'absolute',
  top,
  left,
  backgroundColor: 'transparent',
  zIndex: 11
}));

const WhiteDiv = DivComponent(({ top, left }) => ({
  width: 50,
  height: 30,
  position: 'absolute',
  top,
  left,
  zIndex: 11,
  backgroundColor: colors.white
}));


const Text = DivComponent(({ height = 252, width = 423,  top, left }) => ({
  height,
  width,
  borderRadius: 12,
  position: 'absolute',
  top,
  left,
  padding: 15,
  zIndex: 11,
  color: colors.darkGreyText,
  lineHeight: 'normal'
}));

export default InfoBubble;
