import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Toggle from 'react-toggle';
import { Div, P, H5 } from '../../../style/Global';
import Auth from '../../../components/shared/AuthCmp';
import { Bold } from '../../../style/Typography';
import { SubpageDiv } from '../SettingsStyle';
import { ToggleOverlayDefaultsModal, UpdateDefaultPrimaryColor, ToggleShowVisualizationOnVideoStart } from './OverlayDefaultsModals';
import { ColorPallete } from './ColorPalleteCmp';
import { MessageCmp } from '../../../style/StyledCmps';
import Icon from '../../../components/shared/FontAwesomeIconCmp';

@inject('app', 'modal', 'overlayDefaults')
@observer
class OverlayDefaultsPage extends Component {
  constructor(props) {
    super();
    this.openToggleOverlayDefaultsModal = this.openToggleOverlayDefaultsModal.bind(this);
    this.saveToggleOverlayDefaults = this.saveToggleOverlayDefaults.bind(this);
    this.openShowVisualizationOnStartModal = this.openShowVisualizationOnStartModal.bind(this);
    this.saveShowVisualizationOnStart = this.saveShowVisualizationOnStart.bind(this);
    this.setNewPrimaryColor = this.setNewPrimaryColor.bind(this);
    this.savePrimaryColor = this.savePrimaryColor.bind(this);

    const { showPostVideoOverlay, primaryColor, hideVisualizationOnStart } = props.app.publisherOverlayDefaults;
    const { setInitialPublisherDefaults } = props.overlayDefaults;
    setInitialPublisherDefaults({ primaryColor, showPostVideoOverlay, hideVisualizationOnStart });
  };

  componentWillUnmount = () => {
    this.props.overlayDefaults.changeIsSuccessful(false);
  };

  errorMessage = modalToSave => modalToSave.error('This is an error');
  successMessage = modalToSave => modalToSave.success('successful no error here');

  // openTestModal = () => {
  //   const { modal } = this.props;

  //   modal.open({
  //     title: 'Test Modal',
  //     component: () => <TestModal />,
  //     activeTab: 'Tab 1',
  //     tabs: [
  //       {
  //         text: 'Tab 1',
  //         buttons: [
  //           { text: 'Cancel', func: this.successMessage },
  //           { text: 'Save', icon: 'fa-check', func: this.errorMessage }
  //         ]
  //       },
  //       {
  //         text: 'Tab 2',
  //         buttons: [
  //           { text: 'do something', func: () => console.log('this button says do something') }
  //         ]
  //       }
  //     ],
  //     globalButtons: [
  //       { text: 'Global', func: () => console.log('I will console log in any tab!') }
  //     ]
  //   });
  // };

  async saveToggleOverlayDefaults(modalToSave) {
    const { modal, overlayDefaults, app } = this.props;
    const { enabled } = modalToSave.internalState;
    modal.close();
    app.togglePublisherShowPostVideoOverlay();
    await overlayDefaults.toggleOverlayDefaults(enabled);
  }

  openToggleOverlayDefaultsModal = () => {
    const { modal } = this.props;
    modal.open({
      title: 'Set default behavior',
      component: () => <ToggleOverlayDefaultsModal modal={modal.findCurrentModal()} />,
      globalButtons: [
        { text: 'Cancel', func: modal.close },
        { text: 'Yes', icon: 'fa-check', func: this.saveToggleOverlayDefaults }
      ]
    });
  };

  async saveShowVisualizationOnStart(modalToSave) {
    const { modal, overlayDefaults, app } = this.props;
    const { enabled } = modalToSave.internalState;
    modal.close();
    app.togglePublisherHideVisualizationOnStart();
    await overlayDefaults.toggleHideVisualizationOnStart(enabled);
  }

  openShowVisualizationOnStartModal() {
    const { modal } = this.props;
    modal.open({
      title: 'Set default behavior',
      component: () => <ToggleShowVisualizationOnVideoStart modal={modal.findCurrentModal()} />,
      globalButtons: [
        { text: 'Cancel', func: modal.close },
        { text: 'Yes', icon: 'fa-check', func: this.saveShowVisualizationOnStart }
      ]
    });
  }

  async savePrimaryColor(modalToSave) {
    const { modal, overlayDefaults, app } = this.props;
    const { color, enabled } = modalToSave.internalState;
    modal.close();
    app.setPublisherDefaultPrimaryColor(color);
    await overlayDefaults.setNewPrimaryColor(color, enabled);
  }

  setNewPrimaryColor(color) {
    const { modal } = this.props;

    modal.open({
      title: 'Set default primary color',
      component: () => <UpdateDefaultPrimaryColor color={color} modal={modal.findCurrentModal()} />,
      globalButtons: [
        { text: 'Cancel', func: modal.close },
        { text: 'Yes', icon: 'fa-check', func: this.savePrimaryColor }
      ]
    });
  };

  render() {
    const { errorMessage, primaryColor, productEndCards, visualization, isSuccessful } = this.props.overlayDefaults;

    return (
      <SubpageDiv>
        <H5>Default Primary Color</H5>
        <Div marginTop='15px' marginLeft='18px'>
          <P>Set the color for various parts of the overlay such as the wavelet and button hover states.</P>
          {
            !primaryColor.requestActive
              ?
                <ColorPallete primaryColor={primaryColor.currentValue} setNewPrimaryColor={this.setNewPrimaryColor} marginLeft='10px' marginRight='10px' />
              :
                <Div display='flex' justifyContent='center' alignItems='center' marginTop='15px' height='50px'>
                  <Icon name='fa-circle-notch fa-spin' color={'grey'} size={2} margin='0 15px 0 0' />
                  <p><Bold> Applying...</Bold></p>
                </Div>
          }
        </Div>

        <H5 marginTop='25px'>Default Behavior</H5>
        <Div marginTop='15px' marginLeft='18px' display='flex' justifyContent='center'>
          <Div width='75%'>
            <P marginTop='5px'>Show product cards at end of video?</P>
          </Div>
          <Div display='flex' justifyContent='flex-end' width='25%'>
            <Div display='flex' alignItems='center' marginLeft='10px'>
              {
                !productEndCards.requestActive
                  ?
                    <React.Fragment>
                      <Toggle checked={productEndCards.currentValue} onChange={this.openToggleOverlayDefaultsModal} icons={false} />
                      <p>{productEndCards.currentValue ? <Bold> Enabled</Bold> : ' Disabled'}</p>
                    </React.Fragment>
                  :
                    <React.Fragment>
                      <Icon name='fa-circle-notch fa-spin' color={'grey'} size={2} margin='0 15px 0 0' />
                      <p><Bold> Applying...</Bold></p>
                    </React.Fragment>
              }
            </Div>
          </Div>
        </Div>

        <Div marginTop='15px' marginLeft='18px' display='flex' justifyContent='center'>
          <Div width='75%'>
            <P marginTop='5px'>Show visualization on start?</P>
          </Div>
          <Div display='flex' justifyContent='flex-end' width='25%'>
            <Div display='flex' alignItems='center' marginLeft='10px'>
              {
                !visualization.requestActive
                  ?
                    <React.Fragment>
                      <Toggle checked={!visualization.currentValue} onChange={this.openShowVisualizationOnStartModal} icons={false} />
                      <p>{!visualization.currentValue ? <Bold> Enabled</Bold> : ' Disabled'}</p>
                    </React.Fragment>
                  :
                    <React.Fragment>
                      <Icon name='fa-circle-notch fa-spin' color={'grey'} size={2} margin='0 15px 0 0' />
                      <p><Bold> Applying...</Bold></p>
                    </React.Fragment>
              }
            </Div>
          </Div>
        </Div>

        {/* <Button onClick={this.openTestModal}>Test Modal</Button> */}

        {isSuccessful &&
          <MessageCmp type='success'>
            <p>The default setting has been changed and existing video mappings may take up to 20 minutes to reflect on your site.</p>
          </MessageCmp>
        }

        {errorMessage &&
          <MessageCmp type='error'>
            <p><Bold>The operation</Bold> failed. Please try again later.</p>
          </MessageCmp>
        }
      </SubpageDiv>
    );
  };
};

export default Auth(OverlayDefaultsPage);
