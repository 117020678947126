import { getPublisherCapabilities } from '../../util/PersistedStateUtil';
import { stores } from '../../util/StoresUtil';

export const getSupportedPlayerList = () => {
  let supportedPlayers = [ 'Youtube', 'Facebook' ];
  const publisherCapabilities = getPublisherCapabilities();
  if (publisherCapabilities) publisherCapabilities.forEach(capability => {
    switch(capability.name) {
      case 'flowplayer':
        supportedPlayers.push('Flowplayer');
        break;
      case 'brightcove':
        supportedPlayers.push('Brightcove');
        break;
      case 'jwplayer':
        supportedPlayers.push('Jwplayer');
        break;
      default: return;
    }
  });
  return supportedPlayers;
};

export const getPlayerStore = player => {
  const { flowplayerStore, brightcoveStore } = stores;
  switch (player) {
    case 'brightcove':
      return brightcoveStore;
    case 'flowplayer':
      return flowplayerStore;
    default: return null;
  };
};
