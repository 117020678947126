import { observable, action } from 'mobx';
import API from '../../../../util/ApiUtil';

class JwplayerStore {
  @observable jwplayerLibrarySdk = null;
  @observable jwplayerLibrarySdkIsValid = true;
  @observable jwplayerEmbedCode = null;
  @observable jwplayerVideoEmbedIsValid = false;
  @observable jwplayerVideoId = null;
  @observable isLoading = false;
  @observable hasCodeBeenEntered = false;

  @action onLibraryUrlChange = async e => {
    const inputElement = e.currentTarget;
    this.jwplayerLibrarySdk = inputElement.value;
    const response = await API.post('portal/plugin/jwplayer/set-library-sdk', { data: { sdk: this.jwplayerLibrarySdk, pubId: API.publisherId() } });
    inputElement.value = '';
    if (response && response.status === 200) {
      this.hasCodeBeenEntered = false;
      return this.jwplayerLibrarySdkIsValid = true;
    };
    this.jwplayerLibrarySdkIsValid = false;
    this.hasCodeBeenEntered = true;
  };

  @action onIframeEmbedAdd = async e => {
    const embedCode = e.target.value;
    const inputElement = e.currentTarget;
    this.isLoading = true;
    const response = await API.post('portal/plugin/jwplayer/embed-code', { data: { pubId: API.publisherId(), code: embedCode } });
    if (response && response.status === 200) {
      const { videoId } = response.data;
      this.jwplayerEmbedCode = embedCode;
      this.jwplayerVideoId = videoId;
      this.jwplayerVideoEmbedIsValid = true;
      this.hasCodeBeenEntered = false;
      this.isLoading = false;
      return;
    };
    inputElement.value = '';
    this.jwplayerVideoEmbedIsValid = false;
    this.hasCodeBeenEntered = true;
    this.isLoading = false;
  };

  @action editToken = () => {
    this.jwplayerLibrarySdk = null;
    this.jwplayerLibrarySdkIsValid = false;
  };

  @action clearEmbedText = () => {
    this.jwplayerEmbedCode = null;
    this.jwplayerVideoId = '';
    this.hasCodeBeenEntered = false;
  };

  @action resetEmbedValues = () => {
    this.jwplayerEmbedCode = null;
    this.jwplayerVideoEmbedIsValid = false;
    this.jwplayerVideoId = null;
    this.hasCodeBeenEntered = false;
  };
};

export default JwplayerStore;
