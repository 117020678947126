import React from 'react';
import { inject, observer } from 'mobx-react';
import { Div, P } from '../../../../style/Global';
import config from '../../../../config/config';
import { Bold, Italic, RedUnderline } from '../../../../style/Typography';
import { BorderedInput } from '../../../../style/FormStyle';
import { GreyBox, MessageCmp } from '../../../../style/StyledCmps';
import { colors, fontSizes } from '../../../../style/Constants';
import EmbedPropertiesContainer from '../../SharedComponents/EmbedPropertiesContainerCmp';
import IframeEmbedCode from '../../SharedComponents/IframeEmbedCmp';
import LoadingCmp from '../../LoadingCmp';
import EditableComponent from '../../../../components/shared/EditableComponent';

export const FlowplayerConfiguration = inject('flowplayerStore', 'mediaMappings', 'app')(observer(({ flowplayerStore, mediaMappings, app }) => {
  if(!flowplayerStore.flowplayerToken || !flowplayerStore.flowplayerTokenIsValid) return <FlowplayerPlayerTokenPreActivation flowplayerStore={flowplayerStore} mediaMappings={mediaMappings} />;
  return <FlowplayerPlayerTokenPostActivation flowplayerStore={flowplayerStore} mediaMappings={mediaMappings} app={app} />;
}));

export class FlowplayerPlayerTokenPreActivation extends EditableComponent {
  render() {
  const { flowplayerStore, mediaMappings } = this.props;
    return (
      <Div width='100%'>
        <P marginTop={5}><Bold>Activate Integration</Bold></P>
        <P marginTop={10}>To start using flowplayer, you need to obtain your player token and provide it below. <RedUnderline cursor='pointer'><a href={config.documentationUrl.flowplayer} target='_blank'>More information »</a></RedUnderline></P>
        <GreyBox width='100%' marginTop={10}>
          <P marginBottom={4}>Paste flowplayer player token</P>
          <BorderedInput
            type='text'
            onChange={e => {
              this.addToInProgressEditsCount()
              flowplayerStore.onTokenChange(e);
              flowplayerStore.hasCodeBeenEntered = true;
              mediaMappings.setErrorMessage(null);
            }}
            marginTop={0}
            width='100%' />
        </GreyBox>
        {
          flowplayerStore.flowplayerToken && !flowplayerStore.flowplayerTokenIsValid && flowplayerStore.hasCodeBeenEntered &&
          <MessageCmp type='error' width='100%'>
            <P><Italic><Bold>The token</Bold></Italic> entered appears to be invalid.</P>
          </MessageCmp>
        }
      </Div>
    );
  };
  }


export const FlowplayerPlayerTokenPostActivation = ({ flowplayerStore, mediaMappings }) => {
  return (
    <Div width='100%' display='flex' flexDirection='column' alignItem='center'>
      <P marginTop={5} marginBottom={14}>
        Your flowplayer integration is activated with the following flowplayer player token. You can use the same token for multiple videos. <RedUnderline cursor='pointer'>
          <a href={config.documentationUrl.flowplayer} target='_blank'>More information »</a>
        </RedUnderline>
      </P>
      <BorderedInput id='flowplayerTokenInput' readOnly value={flowplayerStore.flowplayerToken}
        onChange={e => {
          flowplayerStore.onTokenChange(e);
          flowplayerStore.hasCodeBeenEntered = true;
          mediaMappings.setErrorMessage(null);
        }}
        backgroundColor={colors.lightGrey}
        marginTop={0}
        width='100%' />
      <Div
        marginBottom={0}
        marginTop={2}
        textAlign='right'>
        <RedUnderline
            fontSize={fontSizes.small}
            onClick={() => {
              flowplayerStore.editToken();
            }}
          >Change token for all videos
        </RedUnderline>
      </Div>
      <FlowplayerIframeEmbedCode />
      {
        flowplayerStore.flowplayerToken && !flowplayerStore.flowplayerTokenIsValid && flowplayerStore.hasCodeBeenEntered &&
        <MessageCmp type='error' width='100%' margin='25px 0 10px 0'>
          <P><Italic><Bold>The token</Bold></Italic> entered appears to be invalid.</P>
        </MessageCmp>
      }
    </Div>
  );
};

export const FlowplayerIframeEmbedCode = inject('flowplayerStore')(observer(({ flowplayerStore })  => {
  return (
    <React.Fragment>
      { (flowplayerStore.isLoading) ?
        <Div marginTop={20}>
          <LoadingCmp title='Checking embed code...' message='Please wait.'/>
        </Div>
      : (!flowplayerStore.flowplayerVideoId || !flowplayerStore.flowplayerVideoEmbedIsValid) ?
        <IframeEmbedCode
            title='flowplayer video'
            addEmbedCode={flowplayerStore.onIframeEmbedAdd}
            errorMessage='Embed appears to be invalid.'
            embedIsValidString={'flowplayerVideoEmbedIsValid'}
            currentStore={flowplayerStore}
            marginBottom={0}
          />
      :
        <EmbedPropertiesContainer
          title='Flowplayer'
          embedPropertiesList={[{ name: 'Video Id', value: flowplayerStore.flowplayerVideoId }, { name: 'Player Id', value: flowplayerStore.flowplayerPlayerId ? flowplayerStore.flowplayerPlayerId  : 'default' }]} resetEmbedValues={flowplayerStore.clearEmbedText}
        />
      }
    </React.Fragment>
  );
}));
