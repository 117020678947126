import React from 'react';
import { observer, inject } from 'mobx-react';
import slugify from 'slugify';
import Select from 'react-select';
import { Div, P } from '../../../style/Global';
import config from '../../../config/config';
import { Bold, Italic, RedUnderline } from '../../../style/Typography';
import { BorderedInput } from '../../../style/FormStyle';
import { Spacer, MessageCmp } from '../../../style/StyledCmps';
import GreyBoxWithArrow from './GreyBoxWithArrowCmp';
import Auth from '../../../components/shared/AuthCmp';
import { getSupportedPlayerList, getPlayerStore } from '../MediaMappingHelpers';
import EditableComponent from '../../../components/shared/EditableComponent';
import { SearchMedia, PlayerConfiguration, onMediaNameChange } from './MappingModalHelpers';

@inject('mediaMappings', 'modal', 'flowplayerStore', 'brightcoveStore', 'jwplayerStore')
@observer
class AddMappingModal extends EditableComponent {
  constructor(props) {
    super();
    this.state = { mediaQueryList: [] };
    props.individualModal.internalState = {
      mediaName: '',
      videoPlayer: 'youtube',
      videoId: '',
      tagflixMediaId: '',
      activeVideoId: ''
    };
    this.setErrorMessage = (errorMessage, internalMessage) => {
      const { mediaMappings } = this.props;
      mediaMappings.setErrorMessage(errorMessage);
      if (internalMessage) console.error(internalMessage);
    };
    this.onVideoIdChange = e => {
      const { individualModal } = this.props;
      this.setInternalModalState(individualModal, { videoId: e.target.value }, () => this.setErrorMessage(null));
    };
    this.slugifyVideoId = () => {
      const { individualModal } = this.props;
      this.setInternalModalState(individualModal, { videoId: slugify(individualModal.internalState.videoId).substr(0, 50) });
    }
    this.setVideoPlayer = videoPlayer => {
      const { individualModal } = this.props;
      this.setInternalModalState(individualModal, { videoPlayer });
    };
    this.setErrorMessage = this.setErrorMessage.bind(this);
  }

  componentDidMount() {
    const { flowplayerStore, brightcoveStore, jwplayerStore } = this.props;
    flowplayerStore.resetEmbedValues();
    brightcoveStore.clearVideoEmbedCode();
    jwplayerStore.resetEmbedValues();
  }

  componentWillUnmount() {
    const { brightcoveStore, flowplayerStore, mediaMappings } = this.props;
    brightcoveStore.clearVideoEmbedCodeAndResetPlayerConfig();
    flowplayerStore.resetEmbedValues();
    mediaMappings.setErrorMessage();
  }

  render() {
    const {
      mediaName,
      videoPlayer,
      videoId
    } = this.props.individualModal.internalState;
    const { mediaQueryList } = this.state;
    const { errorMessage } = this.props.mediaMappings;

    let dropdownOptions = [];
    getSupportedPlayerList().forEach(player => dropdownOptions.push({ value: player.toLowerCase(), label: player }));

    const { mediaMappings } = this.props;
    return (
      <Div>
        <P>To deliver the correct overlay data to your videos, Tagflix needs to know which videos you want to enable. <RedUnderline cursor='pointer'><a href={config.documentationUrl.integration} target='_blank'>More information »</a></RedUnderline></P>
        <Spacer height={15} />
        <Div>
          <P paddingBottom={10}><Bold>Enter your video id </Bold><Italic>(this can be any id your system generates)</Italic></P>
          <GreyBoxWithArrow>
            <BorderedInput type='text' value={videoId} onChange={this.onVideoIdChange} marginTop={0} onBlur={this.slugifyVideoId} />
          </GreyBoxWithArrow>
          <SearchMedia
            value={mediaName}
            onChange={e => onMediaNameChange(e, this)}
            mediaQueryList={mediaQueryList}
            parentContext={this}
            mediaPropertyToDisplay='name'
            includeMapToMediaText
          />
          <P paddingTop={30} paddingBottom={10}><Bold>Where is this video uploaded to?</Bold></P>
          <GreyBoxWithArrow>
            <Select
              value={videoPlayer}
              onChange={e => {
                this.setVideoPlayer(e.value);
                mediaMappings.setErrorMessage(null);
                if (videoPlayer === 'brightcove' || videoPlayer === 'flowplayer') getPlayerStore(videoPlayer).hasCodeBeenEntered = false;
              }}
              searchable={false} clearable={false}
              options={dropdownOptions}
            />
          </GreyBoxWithArrow>

          <Div paddingLeft='10px'>
            <PlayerConfiguration
              {...this.props.individualModal.internalState}
              individualModal={this.props.individualModal}
              setErrorMessage={this.setErrorMessage}
            />
          </Div>
        </Div>
        {errorMessage && <MessageCmp type='error' width='100%' marginTop={'10px'} marginBottom='10px' ><p>{errorMessage}</p></MessageCmp>}
      </Div>
    );
  }
}

export default Auth(AddMappingModal);
