import React from 'react';
import PropTypes from 'prop-types';
import { I, Span } from '../../style/Global';
import { colors } from '../../style/Constants';

const Icon = ({ name, color = colors.darkGreyText, size, lg, me, sm, margin, onClick, cursor, position, top, left, right, bottom }) => {
  let className = name.startsWith('far') || name.startsWith('fab') ? name : `fa ${name}`;
  if (size) className += ` fa-${size}x`;
  if (lg) className += ' fa-lg';
  if (me) className += ' fa-me';
  if (sm) className += ' fa-sm';

  const passProps = { className, margin, onClick, cursor, position, right, top, left, bottom };
  return (
    <Span color={color}>
      <FA {...passProps} />
    </Span>
  );

};

const FA = ({ className, margin, onClick, cursor, position, top, right, left, bottom }) => (
  <I className={className} position={position} top={top} left={left} bottom={bottom} right={right} ariaHidden='true' margin={margin} onClick={onClick} cursor={cursor}></I>
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOf([2, 3, 4, 5]),
  lg: PropTypes.bool,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  cursor: PropTypes.string,
  position: PropTypes.string
};

export default Icon;
