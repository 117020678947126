import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Icon from '../../components/shared/FontAwesomeIconCmp';
import { H2, H3, P } from '../../style/Global';
import { colors } from '../../style/Constants';
import { Bold, Italic, Thin, Grey, RedUnderline } from '../../style/Typography';
import { FlexColumn, AbsoluteImg, Spacer, MessageCmp } from '../../style/StyledCmps';
import { RedButton, UnderlinedInput } from '../../style/FormStyle';
import PasswordInput from '../../components/shared/PasswordInputCmp';
import LoadingBar from '../../components/shared/loadingBar/LoadingBarCmp';
import {
  HomeDiv,
  Background,
  BackgroundTop,
  BackgroundBottom,
  BkgBottomImg,
  Overlay,
  OverlayHeader,
  Logo,
  OverlayBody,
  InputTitle,
  Icons,
  RequiredWarning
} from './HomeStyle';
import forgotPasswordModals from './homeModals/ForgotPasswordModals';
import acceptInviteModals from './homeModals/AcceptInviteModals';
import galaxy from '../../../assets/galaxy-background.png';
import backdropTop from '../../../assets/login-backdrop-top.svg';
import backdropBottom from '../../../assets/login-backdrop-bottom.png';
import tagflixLogo from '../../../assets/tagflix-logo.svg';
import config from '../../config/config';
@inject('app', 'modal')
@observer
class HomePage extends Component {
  constructor() {
    super();
    this.openForgotPasswordModal = this.openForgotPasswordModal.bind(this);
    this.openAcceptInviteModal = this.openAcceptInviteModal.bind(this);
  };

  componentDidMount = async () => {
    const url = new URL(window.location);

    if(url.pathname === '/reset-password') {
      const email = url.searchParams.get('email');
      const code = url.searchParams.get('code');
      await this.props.app.checkIfPasswordResetCodeIsValid(email, code);
    }
  }

  openForgotPasswordModal() {
    const { modal } = this.props;
    modal.open(forgotPasswordModals.enterEmail);
  }

  async openAcceptInviteModal() {
    const { modal } = this.props;
    const modalToOpen = await acceptInviteModals.determineModal();
    modal.open(modalToOpen);
  }

  render() {
    const { app } = this.props;
    return (
      <HomeDiv>
        <Backdrop />
        <OverlayBox app={app} openForgotPasswordModal={this.openForgotPasswordModal} />
        <BottomText />
      </HomeDiv>
    );
  };
};

const Backdrop = () => (
  <Background>
    <BackgroundTop>
      <AbsoluteImg src={galaxy} />
      <AbsoluteImg src={backdropTop} />
    </BackgroundTop>
    <BackgroundBottom>
      <BkgBottomImg src={backdropBottom} />
    </BackgroundBottom>
  </Background>
);

@observer
class OverlayBox extends Component {
  constructor() {
    super();
    this.state = { email: '', password: '', validator: { email: true, password: true }, showPassword: false };
    this.onEmailChange = e => this.setState({ email: e.target.value });
    this.onPasswordChange = e => this.setState({ password: e.target.value });
    this.clearEmailAndPassword = () => this.setState({ email: '', password: '' });
    this.validateLogin = (email, password) => this.setState({ validator: { email, password } });
    this.toggleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
    this.login = this.login.bind(this);
  }

  login(email, password) {
    this.validateLogin(Boolean(email), Boolean(password));
    if (email && password) this.props.app.login(email, password, this.clearEmailAndPassword);
  }

  render() {
    const { app } = this.props;
    const { email, password, validator } = this.state;
    return (
      <Overlay>
        <LoadingBar show />
        <OverlayHeader>
          <Logo src={tagflixLogo} />
          <H2>Publisher Portal</H2>
        </OverlayHeader>
        <OverlayBody autoComplete='off'>
          <InputTitle>
            <H3 padding='3px 0'>Username</H3>
            {!validator.email && <RequiredWarning>*required</RequiredWarning>}
          </InputTitle>
          <UnderlinedInput type='text' value={email} onChange={this.onEmailChange} valid={validator.email} autoFocus />
          <Spacer height={25} />
          <InputTitle>
            <H3 padding='3px 0'>Password</H3>
            {!validator.password && <RequiredWarning>*required</RequiredWarning>}
          </InputTitle>
          <PasswordInput value={password} onChange={this.onPasswordChange} valid={validator.password} underlined />
          <Spacer height={5} />
          <P alignSelf='flex-end' onClick={this.props.openForgotPasswordModal}>
            <RedUnderline>Forgot Password</RedUnderline>
          </P>
          <Spacer height={10} />
          {app.loginError && <LoginErrorMessage message={app.loginErrorMessage} timer={app.loginTimeoutSecondsCount} />}
          <Spacer height={15} />
          <RedButton onClick={e => {
            e.preventDefault();
            this.login(email, password);
          }}>LOGIN</RedButton>

        </OverlayBody>
      </Overlay>
    );
  }
}

const BottomText = () => (
  <FlexColumn>
    <p>For support, <RedUnderline><a href='https://support.tagflix.com/hc/en-us/requests/new' target='_blank'>contact us</a></RedUnderline>.</p>
    <Spacer height={20} />
    <Thin><Grey>{config.environment}-{config.appVersion}</Grey></Thin>
    <Spacer height={50} />

    <Icons>
      <Icon name='fab fa-chrome' color={colors.lightGrey} size={3} />
      <Icon name='fab fa-firefox' color={colors.lightGrey} size={3} />
    </Icons>

    <Spacer height={20} />
    <Thin><Grey>Chrome and firefox supported.</Grey></Thin>
    <Spacer height={35} />
    <p>© {(new Date()).getFullYear()} Tagflix Inc</p>
  </FlexColumn>
);

const LoginErrorMessage = ({ message, timer }) => (
  <MessageCmp type='error' width='100%' margin='5px 0'>
    {
      message === 'password' ?
        <p><Bold><Italic>Username, password</Italic></Bold> combination is incorrect.</p>
        :
        <p>Too many failed attempts. Please wait {timer} seconds before next attempt.</p>
    }
  </MessageCmp>
);

export default HomePage;
