import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { DivComponent } from '../../../style/Global';
import { colors } from '../../../style/Constants';
import './LoadingBar.css';

@inject('app')
@observer
class LoadingBar extends Component {
  render() {
    const { app, width } = this.props;
    if (app.requestInProgress) return <LoadingBarDiv width={width} />;
    return null;
  };
};

const LoadingBarDiv = DivComponent(({ width = '100%' }) => ({
  position: 'absolute',
  height: '4px',
  width,
  zIndex: 6,
  backgroundColor: 'transparent',
  overflow: 'hidden',
  ':before': {
    display: 'block',
    position: 'absolute',
    content: '""',
    lineHeight: 0,
    left: '-200px',
    width: '200px',
    height: '5px',
    backgroundColor: colors.red,
    animationName: 'loading-bar-keyframes',
    animationDuration: '800ms',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite'
  }
}));

export default LoadingBar;
