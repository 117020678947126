import React from 'react';
import { Div, DivComponent } from '../../style/Global';
import { colors } from '../../style/Constants';
import Icon from './FontAwesomeIconCmp';

const CheckboxContainer = DivComponent({
  position: 'relative',
  background: 'white',
  borderRadius: '3px',
  width: '26px',
  height: '26px',
  border: `solid 1px ${colors.greyText}`
});

const Checkbox = ({ className, checked, onClick, onKeyPress })  => (
  <CheckboxContainer onClick={onClick} onKeyPress={onKeyPress} className={className}>
    { checked && <Icon className='checkmark' name='fa-check' color={colors.red} position='absolute' top={6.5} left={6.8} checked={checked} sm/> }
  </CheckboxContainer>
);

export const CheckboxComponent = ({ onCheckboxClick, checked, children }) => {
    return (
      <Div position='relative' flexWrap='wrap' display='flex' alignItems='start' o >
        <Checkbox
          flex='20%'
          checked={checked}
          onClick={onCheckboxClick}
          onKeyPress={onCheckboxClick}
        />
        { children }
      </Div>
    );
};
