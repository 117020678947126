import React from 'react';
import { observer, inject } from 'mobx-react';
import { Div } from '../../../../style/Global';
import { Bold, Red } from '../../../../style/Typography';
import { VideoUrlText } from '../../MediaMappingStyle';
import EditableComponent from '../../../../components/shared/EditableComponent';
import { VideoUrlInputCmp } from '../MappingModalHelpers';

@inject('mediaMappings')
@observer
export class YoutubeCmp extends EditableComponent {
  constructor(props) {
    super();
    if (!props.individualModal.internalState.youtubeVideoId) props.individualModal.internalState.youtubeVideoId = '';
  }

  onYoutubeVideoIdChange = e => {
    const { individualModal, setErrorMessage } = this.props;
    individualModal.internalState.youtubeVideoId = e.target.value;
    this.setInternalModalState(individualModal, { youtubeVideoId: e.target.value }, () => setErrorMessage(null));
  };

  render() {
    const { activeVideoId, youtubeVideoId } = this.props.individualModal.internalState;
    const { setActiveVideoId } = this.props;

    return (
      <Div display='flex' flexWrap='wrap'>
        <VideoUrlText paddingTop={5}>https://www.youtube.com/watch?v=</VideoUrlText>
        {
          youtubeVideoId || activeVideoId === 'youtubeVideoId'
            ?
              <VideoUrlInputCmp value={youtubeVideoId} onChange={this.onYoutubeVideoIdChange} isActive={activeVideoId === 'youtubeVideoId'} />
            :
              <VideoUrlText onClick={() => setActiveVideoId(this.setInternalModalState, 'youtubeVideoId', this.props.individualModal)} margin='4px 2px 0 2px'><Red cursor='pointer'><Bold> {'{click to set}'} </Bold></Red></VideoUrlText>
        }
      </Div>
    );
  }
}
