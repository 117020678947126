import { observable, action, computed } from 'mobx';
import jwtDecode from 'jwt-decode';
import {
  userHasAcceptedCookiePolicy,
  saveUserToken, removeSavedUserData,
  getSelectedPublisherId, saveSelectedPublisherId,
  savePublisherCapabilities, getPublisherCapabilities,
  getLastMenuOption
} from '../../util/PersistedStateUtil';
import API from '../../util/ApiUtil';
import { publisherModals } from '../../pages/home/homeModals/PublisherModals';
import capabilities, { defaultCapabilities } from '../../config/Capabilities';
import { stores } from '../../util/StoresUtil';
import intersection from 'lodash.intersection';
import { convertFileSizeUnits } from '../../util/UnitConversions';
import config from '../../config/config';
import resetPasswordModals from '../../pages/home/homeModals/ResetPasswordModals';
import forgotPasswordModals from '../../pages/home/homeModals/ForgotPasswordModals';

const emptyUser = {
  name: '',
  email: '',
  id: ''
};

const tempUser = {
  email: '',
  code: ''
}

const emptyAccountManager = {
  name: '',
  email: '',
  phone: ''
};

const emptyPublisher = {
  name: '',
  id: '',
  userRole: '',
  accountManager: emptyAccountManager
};

class AppStore {
  constructor(modal) {
    this.modal = modal;
    this.showCookiePolicyModal = !Boolean(userHasAcceptedCookiePolicy());
  }

  @observable user = emptyUser;
  @observable publisher = emptyPublisher;
  @observable loginError = false;
  @observable loginErrorMessage = '';
  @observable loginTimeoutSecondsCount = 0;
  @observable isLoggedIn = false;
  @observable openPopupsList = [];
  @observable infoBubble = null
  @observable redirectToPathname = { pathname: '/support', shouldRedirect: false };
  @observable requestCount = 0;
  @observable userToken = '';
  @observable lastUserLocationInfo = null;
  @observable showCookiePolicyModal;
  @observable selectedPublisherId = '';
  @observable publishers = [];
  @observable publisherCapabilities = [];
  @observable publisherVideoAssetsConfig = {};
  @observable publisherOverlayDefaults = {};
  @observable onReportPage = false;

  @action hideCookiePolicyModal = () => this.showCookiePolicyModal = false;

  @action login = async (email, password, clearEmailAndPassword) => {
    const response = await API.post(
      'user/login',
      { data: { email, password, app: 'portal' }, includeAuthHeader: false }
    );
    if (response.status === 200) {
      const userToken = response.data;
      saveUserToken(userToken);
      await this.prepareUserFromUserToken(userToken);
      return clearEmailAndPassword();
    }
    if (response.error) this.loginErrorHandler(response.error);
  };

  @action loginErrorHandler = error => {
    this.loginError = true;
    if (error === 'password incorrect') this.loginErrorMessage = 'password';

    const tooManyFailedAttemptsCheck = /too many failed attempts- wait (\d+)/.exec(error);
    if (tooManyFailedAttemptsCheck) {
      if (this.timeoutInterval) return;
      this.loginErrorMessage = 'timeout';
      this.loginTimeoutSecondsCount = parseInt(tooManyFailedAttemptsCheck[1], 10);
      this.timeoutInterval = setInterval(() => {
        if (this.loginTimeoutSecondsCount <= 1) {
          this.loginError = false;
          this.loginTimeoutSecondsCount = 0;
          this.loginErrorMessage = '';
          clearInterval(this.timeoutInterval);
        }
        this.loginTimeoutSecondsCount--;
      }, 1000);
    }
  };

  @action loginFromToken = async userToken => {
    this.isLoggedIn = true;
    this.userToken = userToken;
    const decodedToken = jwtDecode(userToken);
    const user = decodedToken.d;
    this.user = {
      name: `${user.firstname} ${user.surname}`,
      email: user.email,
      id: user.id
    };
    const publishers = decodedToken.p.filter(p => p.app === 'portal' && p.target);
    await this.getPublishersInfo(publishers);
    if (publishers.length === 0) this.modal.open(publisherModals.noAssignedPublishers);
  };

  @action async requestPasswordResetEmail (email) {
    const response = await API.post('user/forgot-password', { data: { email, passwordResetUrl: `${config.appUrl}/reset-password`, app: config.app }, includeAuthHeader: false })
    if(response.status === 204) return this.modal.open(forgotPasswordModals.success);
  }

  @action async checkIfPasswordResetCodeIsValid (email, code) {
    const response = await API.post('user/forgot-password/check', { data: { email, code, app: config.app }, includeAuthHeader: false });
    if(response.status === 204) {
      tempUser.email = email;
      tempUser.code = code;
      return this.modal.open(resetPasswordModals.enterPassword);
    }
    this.modal.open(resetPasswordModals.expiredCodeModal);
  }

  @action async resetPassword (newPassword) {
    const response = await API.post('user/forgot-password/reset', { data: { email: tempUser.email, code: tempUser.code, newPassword, app: config.app }, includeAuthHeader: false })
    if(response.status === 200) {
      tempUser.email = '';
      tempUser.code = '';
      return this.modal.open(resetPasswordModals.success);
    }
    return this.modal.open(resetPasswordModals.expiredCodeModal);
  }

  @action exitConfirmationListener = event => {
    const preventUnload = () => {
      event.preventDefault();
      event['returnValue'] = 'Are you sure you wish to leave? Changes you have made may not be saved.';
    };
    if (!this.isLoggedIn || window.Tagflix.inProgressEditsCount <= 0) {
      return delete event['returnValue'];
    };
    preventUnload();
    return;
  };

  @action addExitAppConfirmationListener = () => {
    window.addEventListener('beforeunload', this.exitConfirmationListener);
  };

  @action togglePublisherShowPostVideoOverlay = () => this.publisherOverlayDefaults.showPostVideoOverlay = !this.publisherOverlayDefaults.showPostVideoOverlay;
  @action togglePublisherHideVisualizationOnStart = () => this.publisherOverlayDefaults.hideVisualizationOnStart = !this.publisherOverlayDefaults.hideVisualizationOnStart;
  @action setPublisherDefaultPrimaryColor = input => this.publisherOverlayDefaults.primaryColor = input;

  @action async getPublisher(publisherId) {
    const response = await API.get(`portal/publisher/${publisherId}`);
    response.data.capabilities.forEach(capability => {
      if (capability.name === 'videoAssets') {
        capability.config.maxVideoUploadSizeBytes = convertFileSizeUnits(capability.config.maxVideoUploadSizeMb, 'Mb', 'bytes');
        capability.config.videoStorageCapacityBytes = convertFileSizeUnits(capability.config.videoStorageCapacityGb, 'Gb', 'bytes');
      };
    });
    return response;
  };

  @action getPublishersInfo = async publishersFromToken => {
    const publisherIds = publishersFromToken.map(pub => pub.target);
    const responses = await Promise.all(publisherIds.map(pubId => this.getPublisher(pubId))); //TODO: implement API.getPublishers() (plural)
    const publishers = publisherIds.map((pubId, i) => {
      const { accountManager, publisherName, capabilities, dashboards } = responses[i].data;
      return {
        id: pubId,
        name: publisherName,
        accountManager: {
          ...accountManager,
          name: `${accountManager.firstname} ${accountManager.surname}`
        },
        userRole: publishersFromToken[i].role,
        capabilities,
        dashboards
      };
    });
    this.publishers = publishers;
    if (publishers.length === 1) return this.setPublisher(publishers[0]);
    const selectedPublisherId = getSelectedPublisherId();
    if (!selectedPublisherId) return this.modal.open(publisherModals.selectPublisher);
    const selectedPublisher = publishers.find(publisher => publisher.id === selectedPublisherId);
    this.setPublisher(selectedPublisher);
  };

  @action setPublisher = async (publisher, closeModal) => {
    this.publisher = publisher;
    this.publisherCapabilities = publisher.capabilities;
    this.publisherOverlayDefaults = {};
    this.publisherVideoAssetsConfig = {};
    await stores.reportStore.getPowerBiToken(this.publisher);
    savePublisherCapabilities(this.publisherCapabilities);

    publisher.capabilities.forEach(capability => {
      if (capability.name === 'publisherSolution') this.publisherOverlayDefaults = capability.config;
      if (capability.name === 'videoAssets') this.publisherVideoAssetsConfig = capability.config;
      if (capability.name === 'flowplayer' && capability.config) stores.flowplayerStore.flowplayerToken = capability.config.playerToken;
      if (capability.name === 'jwplayer') {
        if (!capability.config || !capability.config.librarySdk) {
          stores.jwplayerStore.jwplayerLibrarySdk = null;
          return stores.jwplayerStore.jwplayerLibrarySdkIsValid = false;
        }
        stores.jwplayerStore.jwplayerLibrarySdk = capability.config.librarySdk;
        stores.jwplayerStore.jwplayerLibrarySdkIsValid = true;
      }
      if (capability.name === 'brightcove' && capability.config) {
        const { isLinked, playerId, accountId, embedId, playerName } = capability.config;
        stores.brightcoveStore.brightcovePlayerName = playerName;
        stores.brightcoveStore.brightcovePlayerEmbedId = embedId;
        stores.brightcoveStore.brightcovePlayerAccountId = accountId;
        stores.brightcoveStore.brightcovePlayerId = playerId;
        stores.brightcoveStore.isLinked = isLinked;
      }
    });
    saveSelectedPublisherId(publisher.id);
    this.loginError = false;
    if (closeModal) this.modal.close();
    this.redirectBasedOnCapabilities();
  };

  @action redirectBasedOnCapabilities() {
    try {
      const lastMenuOption = getLastMenuOption();

      const publisherCapabilities = getPublisherCapabilities();
      const dashboardNames = this.publisher && this.publisher.dashboards ? this.publisher.dashboards.map(dashboard => dashboard.name) : [];

      const publisherCapabilityNamesList = [ ...publisherCapabilities.map(capability => capability.name), ...dashboardNames ];
      const optionalPublisherCapabilityStrings = intersection(publisherCapabilityNamesList, Object.keys(capabilities));
      const optionalPublisherCapabilityRoutes = optionalPublisherCapabilityStrings.map(string => capabilities[string].route);
      const defaultRoutes = Object.keys(defaultCapabilities).map(capability => defaultCapabilities[capability].route);

      const possibleRoutes = [ ...defaultRoutes, ...optionalPublisherCapabilityRoutes ];

      let currentPathname = location.pathname;
      if (currentPathname.split('/').length > 2) currentPathname = `/${currentPathname.split('/')[1]}`;

      const currentRouteIsCapabilityRoute = possibleRoutes.includes(currentPathname);

      if(lastMenuOption && lastMenuOption !== '/') {
        const optionWithoutQueryString = lastMenuOption.split('?')[0]
        let firstPathFromLastMenuOption = optionWithoutQueryString.match(/^\/[^\/]+/)[0];
        let firstPathFromLastMenuOptionIsRoutableCapability = firstPathFromLastMenuOption  === '/report' ? possibleRoutes.includes(lastMenuOption) :  possibleRoutes.includes(firstPathFromLastMenuOption);
        if (firstPathFromLastMenuOptionIsRoutableCapability) return this.setRedirectPathname(lastMenuOption);
      }

      if (currentRouteIsCapabilityRoute) return this.setRedirectPathname(location.pathname);
      this.setRedirectPathname(possibleRoutes[0]);
    } catch (e) {
      console.error('Publisher Capabilities compromised', e);
    };
  };

  @action setRedirectPathname = pathname => {
    if (pathname === '/') pathname = '/support';
    this.redirectToPathname = { shouldRedirect: true, pathname };
  };

  @action getLastUserLocationInfoFromApi = async () => {
    const response = await API.get('user/me?include=lastLogin');
    if (response.status === 200) return response.data.lastLogin;
  };

  @action setLastUserLocationInfo = lastUserLocationInfo => {
    this.lastUserLocationInfo = lastUserLocationInfo;
  };

  @action prepareUserFromUserToken = async userToken => {
    await this.loginFromToken(userToken);
    const lastUserLocationInfo = await this.getLastUserLocationInfoFromApi();
    this.setLastUserLocationInfo(lastUserLocationInfo);
  };

  @action logoutUser = () => {
    removeSavedUserData();
    this.user = emptyUser;
    this.publisher = emptyPublisher;
    this.isLoggedIn = false;
    this.redirectToPathname.shouldRedirect = false;
    if (this.modal.show) this.modal.close();
  };

  @action popupToStore = popup => {
    const storedPopup = this.openPopupsList.find(storedPopup => storedPopup.name === popup.name)
    if (!storedPopup) return this.openPopupsList.push(popup);
    this.openPopupsList = this.openPopupsList.filter(storedPopup => popup.name !== storedPopup.name);
  }

  @action closePopups = () => {
    if (!this.arePopupsOpen) return;
    this.openPopupsList.forEach(storedPopup => storedPopup.popupToStore())
    this.openPopupsList = [];
  };

  @computed get arePopupsOpen() { return this.openPopupsList.length > 0 }
  @computed get requestInProgress() { return this.requestCount > 0; };
  @action requestStarted = () => this.requestCount++;
  @action requestDone = () => this.requestCount--;
};

export default AppStore;
