const tagflixMediaId = '8a4cab6a-b79d-4250-9f2c-6d921ab1e128';

export const mockMediaMapping = {
  ...videoPlayerAndUrl(),
  mediaId: tagflixMediaId,
  offsetMs: 1000,
  canWhiteLabel: false,
  isOverlayEnabled: true,
  fallbackVideoId: fallbackVideoId()
};

export const createMediaMappings = () => {
  const mediaArray = [];
  const rand = 10 + Math.round(10 * Math.random());
  for (let i = 0; i < rand; i++) {
    mediaArray.push(mediaObj());
  }
  return mediaArray;
};

function mediaObj() {
  return {
    mediaName: videoName(),
    videoPlayer: randomVideoPlayer(),
    videoId: videoId(),
    tagflixMediaId,
    isOverlayEnabled: isOverlayEnabled(),
    fallbackVideoId: fallbackVideoId()
  };
}

function videoName() {
  const rand = Math.random();
  if (rand < 0.125) return 'Boyz2Men - I\'ll Make Love To You';
  else if (rand < 0.25) return 'R. Kelly - Trapped In The Closet';
  else if (rand < 0.375) return 'Enya - Orinoco Flow (Sail Away)';
  else if (rand < 0.5) return 'Jimi Hendrix - Purple Haze';
  else if (rand < 0.625) return 'Tenacious D - Wonderboy';
  else if (rand < 0.75) return 'Divo - Whip It';
  else if (rand < 0.875) return 'Divo - Whip It';
  else return 'The Village People - YMCA';
}

function videoPlayerAndUrl() {
  const videoPlayer = randomVideoPlayer();
  let videoUrl;
  if (videoPlayer === 'youtube') videoUrl = 'https://www.youtube.com/watch?v=XoiEkEuCWog';
  else videoUrl = 'https://www.facebook.com/tagflix/videos/abc123';
  return { videoPlayer, videoUrl };
}

function randomVideoPlayer() {
  const rand = Math.random();
  if (rand < 0.7) return 'youtube';
  return 'facebook';
}

function videoId() {
  let id = '';
  for (let i = 0; i < 32; i++) {
    if (i === 8 || i === 12 || i === 16 || i === 20) id += '-';
    id += alphaNum();
  }
  return id;
}

function isOverlayEnabled() {
  const rand = Math.random();
  if (rand < 0.2) return false;
  return true;
}

function fallbackVideoId() {
  return Math.random() < 0.5 ? 'ABC123' : null;
}

function alphaNum() {
  const rand = Math.floor(36 * Math.random());
  return alphaNumMap[rand];
}

const alphaNumMap = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z'
];
