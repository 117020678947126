import React from 'react';
import PropTypes from 'prop-types';
import { Div, ExtendComponentStyles } from '../../style/Global';
import { WhiteButton } from '../../style/FormStyle';
import { colors, fontWeight, fontSizes } from '../../style/Constants';
import Icon from './FontAwesomeIconCmp';
import { withRouter } from 'react-router-dom';

const WhiteButtonCmp = withRouter(({
  icon, text, marginTop, marginRight, onClick = preventDefault,
  history, route, closeModal
}) => {
  if(closeModal) onClick = () => closeModal();
  if(route) onClick = () => {
    history.push(route);
    if(closeModal) closeModal();
  };

  const WB = marginTop || marginRight ? ExtendComponentStyles(WhiteButton)({ marginTop, marginRight }) : WhiteButton;
  return (
    <WB onClick={onClick}>
      <Div paddingRight={7} marginTop={-2}>
        <Icon name={icon} color='#ee0d4e' lg/>
      </Div>
      <Div color={colors.darkGreyText} fontSize={fontSizes.p} fontWeight={fontWeight.semiBold}>
        {text}
      </Div>
    </WB>
  );
});

export default WhiteButtonCmp;

function preventDefault(event) {
  event.preventDefault();
}

WhiteButtonCmp.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  marginTop: PropTypes.number,
  onClick: PropTypes.func,
  route: PropTypes.string
};
