import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Auth from '../../components/shared/AuthCmp';
import { Div, P, Span } from '../../style/Global';
import { colors } from '../../style/Constants';
import { limitStringLength } from '../../util/TextUtil';
import Icon from '../../components/shared/FontAwesomeIconCmp';
import Table from '../../components/shared/table/TableCmp';
import { RowDiv, UpperRow, LowerRow } from '../../components/shared/table/Helpers';
import { LabelText, LabelContainer } from '../../style/StyledCmps';
import { Bold, Italic, TinyThinText, Red, RedUnderline } from '../../style/Typography';
import AddVideoModal from './videoModals/AddVideoModal';
import DeleteVideoModal from './videoModals/DeleteVideoModal';
import StorageCapacity from './StorageCapacityCmp';
import { formatBytes } from '../../util/UnitConversions';
import WhiteButton from '../../components/shared/WhiteButtonCmp';

@inject('video', 'modal', 'app')
@observer
class VideosPage extends Component {
  constructor() {
    super();
    this.state = { currentPublisherId: '' };
    this.openVideoModal = this.openVideoModal.bind(this);
  }

  async updateVideoTable(){
    this.setState({ currentPublisherId: this.props.app.publisher.id });
    await this.props.video.tableQuery(this.state.filterQueryOptions);
  }

  componentDidMount(){
    this.updateVideoTable();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.app.publisher.id !== prevState.currentPublisherId) this.updateVideoTable();
  }

  componentWillUnmount() {
    const { app, video } = this.props;

    app.closePopups();
    video.clearDeletedVideoIds();
  }

  openVideoModal(title, videoListing = {}) {
    const { modal } = this.props;
    const modalProps = { title };
    switch (title) {
      case 'Add video':
        modalProps.component = AddVideoModal;
        break;
      case 'Upload video':
        modalProps.component = () => <AddVideoModal videoListing={videoListing} reuploadVideo />;
        break;
      case 'Cancel video upload':
        modalProps.component = () => <DeleteVideoModal videoListing={videoListing} cancelVideoUpload />;
        break;
      case 'Delete video':
        modalProps.component = () => <DeleteVideoModal videoListing={videoListing} />;
        break;
      case 'Edit video properties':
        modalProps.component = () => <AddVideoModal videoListing={videoListing} editVideoProperties />;
        break;
      default: break;
    }
    modal.open(modalProps);
  }


  render() {

    const { video, app } = this.props;

    return (
      <Div padding='30px'>
        <Div display='flex' justifyContent='space-between' marginBottom='15px'>
          <WhiteButton icon='fa-plus' text='Add Video' onClick={() => this.openVideoModal('Add video')} />
          <StorageCapacity />
        </Div>
        <Table
          app={app}
          title='Your videos'
          headerHeight={220}
          contentHeight={300}
          tableRecords={video.videoList}
          pubId={this.state.currentPublisherId}
          store={video}
          row={(videoListing, i) => <Row
            videoListing={videoListing} i={i} key={i}
            recentlyUpdatedVideoIds={video.recentlyUpdatedVideoIds}
            deletedVideoIds={video.deletedVideoIds}
            openVideoModal={this.openVideoModal}
            dismiss={() => video.dismissHighlight(videoListing)}
          />}
        />
      </Div>
    );
  }
}

class Row extends Component {
  constructor() {
    super();
    this.state = { active: false };
    this.activate = () => this.setState({ active: true });
    this.deactivate = () => this.setState({ active: false });
  }

  shouldShowActionButtons() {
    const { videoListing } = this.props;
    const uploadWasSuccessful = this.state.active && (videoListing.status === 'ready' || videoListing.status === 'upload complete');
    const uploadFailed = this.state.active && videoListing.status === 'uploading';
    if (uploadWasSuccessful || uploadFailed) return true;
  }

  isUploading() {
    const { videoListing } = this.props;
    if (videoListing.status === 'uploading' || videoListing.status === 'start upload') return true;
    return false;
  }

  isUploadedOrDeleted() {
    const { videoListing, recentlyUpdatedVideoIds, deletedVideoIds } = this.props;
    const recentlyUpdated = recentlyUpdatedVideoIds.includes(videoListing.uploadVideoId);
    const deleted = deletedVideoIds.includes(videoListing.uploadVideoId);
    const uploaded = videoListing.status === 'upload complete' && videoListing.uploadProgressPercent === 100;
    if (recentlyUpdated || deleted || uploaded) return true;
    return false;
  }

  render() {
    const { videoListing, i, openVideoModal, dismiss, deletedVideoIds, recentlyUpdatedVideoIds } = this.props;
    const deleted = deletedVideoIds.includes(videoListing.uploadVideoId);
    const uploaded = videoListing.status === 'upload complete' && videoListing.uploadProgressPercent === 100;
    const recentlySaved = (recentlyUpdatedVideoIds.includes(videoListing.uploadVideoId));
    const { title } = deleted
      ? highlightedRowText.deleted
      : uploaded
        ? highlightedRowText.uploaded
        : highlightedRowText.saved;
    const fileSize = videoListing.fileSizeBytes;
    const convertedFileSize = formatBytes(fileSize, 1);
    return (
      <RowDiv
        isEven={i % 2}
        recentlySaved={recentlySaved}
        deleted={deleted}
        justifyContent='space-between'
        padding='10'
        onMouseEnter={this.activate}
        onMouseLeave={this.deactivate}
        uploadProgressPercent={videoListing.uploadProgressPercent}
      >
        <Div>
          <UpperRow>
            <Labels videoListing={videoListing} />
            {limitStringLength(videoListing.videoName, 76)}
          </UpperRow>
          <LowerRow>
            <FileSizeAndStatusText videoListing={videoListing} convertedFileSize={convertedFileSize} openVideoModal={openVideoModal} />
          </LowerRow>
        </Div>
        {
          (() => {
            if (this.isUploading()) return null;
            if (this.isUploadedOrDeleted()) return <HighlightedRowText title={title} dismiss={dismiss} />;
            if (this.shouldShowActionButtons()) return <RowButtons videoListing={videoListing} openVideoModal={openVideoModal} />;
            return <HoverForActions />;
          })()
        }
      </RowDiv>
    );
  }
}

class Labels extends Component {
  render() {
    const labels = this.props.videoListing.labels.map((label, index) =>
      <LabelContainer key={index}>
        <LabelText color={colors.greyText}>
          {label}
        </LabelText>
      </LabelContainer>);
    return <Div display='flex'>{labels}</Div>;
  }
}

const FileSizeAndStatusText = ({ videoListing, convertedFileSize, openVideoModal }) => {
  const videoUploadFinished = videoListing.status === 'ready' || videoListing.uploadProgressPercent === 100;
  if (videoUploadFinished) return (
    <TinyThinText color={colors.greyText}>
      {convertedFileSize} | Ready
    </TinyThinText>
  );
  else if (videoListing.status === 'start upload') return (
    <TinyThinText color={colors.greyText}>
      Uploading... <Bold>{videoListing.uploadProgressPercent}%</Bold>
      {/* <Red cursor="pointer" marginLeft={6} fontWeight={400} onClick={() => openVideoModal('Cancel video upload', videoListing)}> Cancel upload.</Red> */}
    </TinyThinText>
  );
  else if (videoListing.status === 'uploading') return (
    <TinyThinText color={colors.greyText}>
      {'Upload failed'} <Red marginLeft={6} fontWeight={400} cursor='pointer' onClick={() => openVideoModal('Upload video', videoListing)}> Try again.</Red>
    </TinyThinText>
  );
  else return (
    <TinyThinText color={colors.greyText}>
      {videoListing.status} <Red marginLeft={6} fontWeight={400} cursor='pointer' onClick={() => openVideoModal('Upload video', videoListing)}> Try again.</Red>
    </TinyThinText>
  );
};

const RowButtons = ({ videoListing, openVideoModal }) => {
  if (videoListing.status === 'uploading') return (
    <Div display='flex' alignItems='center' justifyContent='flex-end' minWidth={130}>
      <Div onClick={() => openVideoModal('Delete video', videoListing)} marginLeft={15} marginRight={5}>
        <Icon name='fa-times' color={colors.red} cursor='pointer' lg />
      </Div>
    </Div>
  );
  else return (
    <Div display='flex' alignItems='center' justifyContent='flex-end' minWidth={130}>
      <Div onClick={() => openVideoModal('Edit video properties', videoListing)} marginLeft={15}>
        <Icon name='fa-pencil-alt' color={colors.red} cursor='pointer' lg />
      </Div>
      <Div onClick={() => openVideoModal('Delete video', videoListing)} marginLeft={15} marginRight={5}>
        <Icon name='fa-times' color={colors.red} cursor='pointer' lg />
      </Div>
    </Div>
  );
};

const highlightedRowText = {
  deleted: { title: 'Deleted!' },
  uploaded: { title: 'Uploaded!' },
  saved: { title: 'Saved!' }
};

const HighlightedRowText = ({ title, dismiss }) => (
  <Div display='flex' alignItems='center' justifyContent='flex-end' minWidth={130}>
    <Div textAlign='right'>
      <P fontSize={11}><Bold><Italic>{title}</Italic></Bold></P>
    </Div>
    <P fontSize={11} paddingLeft={5}>(<Span onClick={dismiss}><RedUnderline><Bold>dismiss</Bold></RedUnderline></Span>)</P>
  </Div>
);

const HoverForActions = () => (
  <Div display='flex' justifyContent='flex-end' alignItems='center' alignSelf='center' minWidth={130}>
    <P fontSize={11} paddingRight={4} paddingLeft={12}>
      <Red><Italic>hover for actions</Italic></Red>
    </P>
  </Div>
);

export default Auth(VideosPage);
