import React from 'react';
import { H5, DivComponent } from '../../../style/Global';
import { colors } from '../../../style/Constants';

export const TableHeader = ({ title, title2, children }) => (
  <TableHeaderDiv>
    <Title><H5 marginLeft='10px'>{title}</H5>{children}</Title>
    { title2 && <Title><H5>{title2}</H5></Title> }
  </TableHeaderDiv>
);

const TableHeaderDiv = DivComponent({
  display: 'flex',
  height: '35px',
  position: 'relative',
  backgroundColor: colors.lightGrey,
  textAlign: 'left',
  borderBottom: `1px solid ${colors.lightGreyBorder}`
});

const Title = DivComponent({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1
});
