import React, { Component } from 'react';
import { Div, PComponent } from '../../style/Global';
import { colors } from '../../style/Constants';
import { UnderlinedInput, BorderedInput } from '../../style/FormStyle';

class PasswordInput extends Component {
  constructor() {
    super();
    this.state = { showPassword: false };
    this.toggleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const { value, onChange, underlined, valid } = this.props;
    const { showPassword } = this.state;
    const type = showPassword ? 'text' : 'password';
    const Input = underlined ? UnderlinedInput : BorderedInput;
    return (
      <Div position='relative' width='100%'>
        <Input value={value} onChange={onChange} type={type} valid={valid} />
        <ShowHidePassword onClick={this.toggleShowPassword} underlined={underlined}>{ showPassword ? 'HIDE' : 'SHOW' }</ShowHidePassword>
      </Div>
    );
  }
};

export const ShowHidePassword = PComponent(({ underlined }) => ({
  position: 'absolute',
  right: underlined ? 16 : 12,
  top: underlined ? 12 : 6,
  fontSize: underlined ? 14 : 11,
  color: underlined ? colors.mediumGreyText : colors.lightGreyText,
  fontWeight: underlined ? 600 : 400,
  cursor: 'pointer'
}));

export default PasswordInput;
