import React, { Component } from 'react';
import { DivComponent, H6Component, FormComponent, SpanComponent, P } from '../../../style/Global';
import Icon from '../FontAwesomeIconCmp';
import { colors, fontSizes } from '../../../style/Constants';
import { WhiteButton } from '../../../style/FormStyle.js';
import { preventPopupDismissalPropagation } from '../helpers';
import { getQueryString } from '../../app/QueryParamsHelper';
import { Link, withRouter } from 'react-router-dom';

export class Filter extends Component {
  state = { clearHovered: false };

  toggleFilterMenu = e => {
    preventPopupDismissalPropagation(e);
    if (!this.state.clearHovered) this.props.toggleFilterMenu(e);
  }

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        applyFilterOnEnterPress: event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            this.props.applyButtonClicked();
            this.props.history.push(getQueryString({ mediaMappingPage: 1, ...(document.querySelector('.filter-string') && document.querySelector('.filter-string').value !== '' && { mediaName: document.querySelector('.filter-string').value }), videoPlayer: this.props.selectedValue }))
          }
        }
      });
    });

    return (
      <FilterContainer>
        <FilterRecordsButton
          onClick={this.toggleFilterMenu}
          opened={this.props.filterMenuRendered} clearHovered={this.state.clearHovered}
        >
          <Icon name='fa-filter' color={this.props.filterMenuRendered ? colors.grey : colors.red} />
          <Header>Filter Records</Header>
          {this.props.filterApplied && !this.props.filterMenuRendered &&
            <Link style={{ display: 'flex' }} to={`/media-mapping${getQueryString({ mediaMappingPage: 1, videoPlayer: 'all' }, ['mediaName'])}`}>
              <P fontSize={11} marginLeft='5px'>(</P>
              <P
                onMouseEnter={() => this.setState({ clearHovered: true })} onMouseLeave={() => this.setState({ clearHovered: false })}
                onClick={() => {
                  this.props.clearButtonClicked();
                  this.setState({ clearHovered: false });
                }}
                fontSize={11} color={colors.red}
              >clear</P>
              <P fontSize={11}>)</P>
            </Link>
          }
        </FilterRecordsButton>
        {this.props.filterMenuRendered &&
          <Dropdown onClick={preventPopupDismissalPropagation}>
            <Form>{children}</Form>
            {this.props.showErrorMessage &&
              <ErrorMessage>
                <p><em><b>Could not</b></em> filter your results at this time.</p>
              </ErrorMessage>
            }
            <ButtonContainer>
              {this.props.filterApplied &&
                <WhiteButton width='78px' margin='10px 0px' onClick={this.props.clearButtonClicked}>
                  <Icon name='fa-times' color={colors.red} />
                  <Span color={colors.darkGreyText} margin='0 0 0 8px' fontSize={fontSizes.p}>Clear</Span>
                </WhiteButton>
              }
              <Link to={getQueryString({ mediaMappingPage: 1, ...(document.querySelector('.filter-string') && document.querySelector('.filter-string').value !== '' && { mediaName: document.querySelector('.filter-string').value }), videoPlayer: this.props.selectedValue })}>
                <WhiteButton width='78px' margin='10px' onClick={this.props.applyButtonClicked}>
                  <Icon name={this.props.awaitingResponse ? 'fa-spinner fa-spin' : 'fa-check'} color={colors.red} />
                  <Span color={colors.darkGreyText} margin='0 0 0 8px'>Apply</Span>
                </WhiteButton>
              </Link>
            </ButtonContainer>
          </Dropdown>
        }
      </FilterContainer>
    );
  }
}
const FilterContainer = DivComponent({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  zIndex: 4
});

export const FilterRecordsButton = DivComponent(({ opened, clearHovered }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0px 10px',
  height: '34px',
  cursor: 'pointer',
  color: colors.greyText,
  zIndex: 3,
  backgroundColor: opened ? colors.superLightGreyText : 'none',
  boxShadow: opened ? '0px 0.5px 5px 0.5px rgba(52, 48, 77, 0.2)' : 'none',
  '&::after': {
    position: 'absolute',
    bottom: '-7.5px',
    right: 0,
    width: '115px',
    height: '10px',
    background: colors.superLightGreyText,
    content: opened ? '""' : 'none'
  },
  '&:hover': { color: opened || clearHovered ? colors.greyText : colors.red }
}));

const Header = H6Component({
  display: 'inline-block',
  fontSize: '11px',
  color: 'inheret',
  marginLeft: '2.5px'
});

const Dropdown = DivComponent({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  width: '340px',
  height: 'auto',
  backgroundColor: colors.superLightGreyText,
  position: 'absolute',
  top: '32px',
  right: '0px',
  zIndex: 2,
  boxShadow: '0.5px 0.5px 5px 0.5px rgba(52, 48, 77, 0.2)'
});

const Form = FormComponent({
  display: 'flex',
  flexDirection: 'column'
});

const Span = SpanComponent(({ color, margin }) => ({
  color,
  margin
}));

const ErrorMessage = DivComponent({
  width: '320px',
  margin: '10px 10px 0 10px',
  padding: '9px',
  backgroundColor: colors.deletedPink
});

const ButtonContainer = DivComponent({ display: 'flex' });

export default withRouter(Filter);
