import { observable, action } from 'mobx';
import API from '../../../util/ApiUtil';

class OverlayDefaultsStore {
  @observable primaryColor = { currentValue: null, requestActive: false, apiRoute: 'primaryColor' };
  @observable productEndCards = { currentValue: true, requestActive: false, apiRoute: 'showPostVideoOverlay' };
  @observable visualization = { currentValue: false, requestActive: false, apiRoute: 'hideVisualizationOnStart' };
  @observable requestQueue = [];
  @observable requestInProgress = false;

  @action setInitialPublisherDefaults = defaults => {
    this.primaryColor.currentValue = defaults.primaryColor;
    this.productEndCards.currentValue = defaults.showPostVideoOverlay;
    this.visualization.currentValue = defaults.hideVisualizationOnStart;
  };

  @action handleQueue = async () => {
    if (!this.requestQueue.length) return this.requestInProgress = false;
    if (!this.requestInProgress) this.requestInProgress = true;
    const nextRequest = this.requestQueue.shift();
    await this.handleRequest(nextRequest);
    this.handleQueue();
  }

  @action handleRequest = async request => {
    const { updateAllExistingRecords, param, src } = request;
    this.isSuccessful = false;
    const response = await this.request(param, updateAllExistingRecords, src.apiRoute);
    if (response) src.requestActive = false;
    if (response.status !== 200) return this.setErrorMessage();
    src.currentValue = param;
    this.changeIsSuccessful(true);
  }

  @action addSettingUpdateToQueue = async item => {
    item.src.requestActive = true;
    this.requestQueue.push(item);
    if (!this.requestInProgress) await this.handleQueue();
  }

  @action toggleHideVisualizationOnStart = async (updateAllExistingRecords = false) => await this.addSettingUpdateToQueue({ src: this.visualization, updateAllExistingRecords, param: !this.visualization.currentValue });

  @action toggleOverlayDefaults = async (updateAllExistingRecords = false) => await this.addSettingUpdateToQueue({ src: this.productEndCards, updateAllExistingRecords, param: !this.productEndCards.currentValue });

  @action setNewPrimaryColor = async (color, updateAllExistingRecords = false) => await this.addSettingUpdateToQueue({ src: this.primaryColor, updateAllExistingRecords, param: color });

  @action request = async (value, updateAll, route) => await API.put(
    `portal/publisher/${API.publisherId()}/setting/portalCapabilities/publisherSolution/${route}${updateAll ? '?updateAll=yes' : ''}`,
    { data: { value } }
  );

  @observable isSuccessful = false;
  @action changeIsSuccessful = input => {
    this.clearErrorMessage();
    this.isSuccessful = input;
  };

  @observable errorMessage = false;
  @action setErrorMessage = () => {
    this.isSuccessful = false;
    this.errorMessage = true;
  };

  @action clearErrorMessage = () => this.errorMessage = false;
};

export default OverlayDefaultsStore;
