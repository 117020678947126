import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  InteriorDiv,
  InteriorHeader,
  HeaderImg,
  LogoIcon,
  InteriorBody,
  SectionDiv,
  SectionHeader,
  SectionBackdrop,
  SectionText,
  SectionFooter
} from './InteriorStyle';
import { Div, Img, H1, H2 } from '../../style/Global';
import { TinyThinText } from '../../style/Typography';
import Sidebar from '../sidebar/SidebarCmp';
import LoadingBar from '../shared/loadingBar/LoadingBarCmp';
import headerBkg from '../../../assets/header-background.png';
import sectionBkdrp from '../../../assets/section-backdrop-top.svg';
import logoIcon from '../../../assets/tagflix-logo-icon.svg';
import logo from '../../../assets/tagflix-logo.svg';
import reports from '../../config/Reports';

@inject('app', 'modal')
@observer
class InteriorCmp extends Component {

  render() {
    const { modal, children, app } = this.props;
    const currentRoute = this.props.currentRoute.split('?')[0];

    return (
      <InteriorDiv>
        <InteriorHeader>
          <HeaderImg src={headerBkg} />
          <LogoIcon src={logoIcon} />
          <H2>Publisher Portal</H2>
        </InteriorHeader>
        <InteriorBody>
          <Sidebar currentRoute={currentRoute} />
          <SectionDiv reportPage={app.onReportPage}>
            <SectionHeader>
              <LoadingBar show={!modal.show} />
              <SectionBackdrop src={sectionBkdrp} />
              <Div padding='36px 30px 30px 30px'>
                <SectionHeaderText route={currentRoute} />
              </Div>
            </SectionHeader>
            <Div lineHeight={1.4}>
              {children}
            </Div>
            <SectionFooter>
              <Img height={16} src={logo} />
              <TinyThinText>© {new Date().getFullYear()} Tagflix Inc</TinyThinText>
            </SectionFooter>
          </SectionDiv>
        </InteriorBody>
      </InteriorDiv>
    );
  }
}

const SectionHeaderText = ({ route }) => {
  let title;
  let text;
  let firstRoutePath = route.match(/^\/[^\/]+/)[0];
  const routeToMatch = firstRoutePath === '/report' ? route : firstRoutePath;

  switch (routeToMatch) {
    case '/videos':
      title = 'Videos';
      text = (
        <SectionText>
          Tagflix offers globally distributed high performance video hosting across multiple platforms in a fast and secure
          manner. Videos distributed through Tagflix have full overlay support without the need for any fallbacks and your
          content will not contain any pre-roll or post-roll ads.<br/><br/>
        </SectionText>
        );
      break;
    case '/media-mapping':
      title = 'Media Mappings';
      text = (
        <SectionText>To deliver the correct data to your videos, Tagflix needs to know how to identify them.
          <br/>Start by adding your media identifiers here. Use the table below to check your mappings and update any incorrectly mapped media.
        </SectionText>
      );
      break;
    case reports['powerbi-standard'].route:
      title = reports['powerbi-standard'].title;
      text = (
        <SectionText>{reports['powerbi-standard'].description}</SectionText>
      );
      break;
    case reports['powerbi-bespoke'].route:
      title = reports['powerbi-bespoke'].title;
      text = (
        <SectionText>{reports['powerbi-bespoke'].description}</SectionText>
      );
      break;
    case '/history':
      title = 'History';
      text = (<SectionText>See recent actions performed by you and your teammates.</SectionText>);
      break;
    case '/settings':
    case '/team':
    case '/overlay-defaults':
    case '/keys':
      title = 'Settings';
      text = null;
      break;
    case '/support':
      title = 'Support';
      text = (<SectionText>We're here for you.<br/><br/>For all urgent enquiries, contact your account manager directly.<br/>Listed on the right are contact methods for less pressing issues.</SectionText>);
      break;
    default: break;
  }

  return (
    <div>
      <H1>{title}</H1>
      {text}
    </div>
  );
};

InteriorCmp.propTypes = { currentRoute: PropTypes.string.isRequired };

export default withRouter(InteriorCmp);
