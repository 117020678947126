import React, { Component } from 'react';
import { Div, P, Input } from '../../../style/Global';
import { Bold, Red } from '../../../style/Typography';
import { colors } from '../../../style/Constants';
import Icon from '../../../components/shared/FontAwesomeIconCmp';
import { FlowplayerConfiguration } from './flowplayer/FlowplayerCmp';
import { BrightcoveConfiguration } from './brightcove/BrightcoveCmp';
import { JwplayerConfiguration } from './jwplayer/JwplayerCmp';
import { VideoUrlInput } from '../MediaMappingStyle';
import { FacebookCmp } from './facebook/FacebookCmp';
import { YoutubeCmp } from './youtube/YoutubeCmp';
import API from '../../../util/ApiUtil';

export const SearchMedia = ({ value, onChange, mediaQueryList, parentContext, fallbackQuery, includeMapToMediaText, mediaPropertyToDisplay }) => (
  <Div width='100%' position='relative' marginTop={5} paddingLeft={10}>
    {includeMapToMediaText && <P position='absolute' top={-30} left={92}><Bold>Map to Tagflix Media</Bold></P>}
    <Input
      width='100%'
      height='28px'
      padding='0 30px 0 10px'
      border={`1px solid ${colors.grey}`}
      color={colors.darkGreyText}
      fontSize={14}
      value={value}
      onChange={onChange}
    />
    <InputIcon name='fa-search' />
    <MediaQueryList list={mediaQueryList} parentContext={parentContext} fallbackQuery={fallbackQuery} mediaPropertyToDisplay={mediaPropertyToDisplay} />
  </Div>
);

const InputIcon = ({ name }) => (
  <Div position='absolute' top={6} right={10} cursor='pointer'>
    <Icon name={name} color={colors.red} />
  </Div>
);

const MediaQueryList = ({ list, parentContext, mediaPropertyToDisplay, fallbackQuery = null }) => (
  <Div width='calc(100% - 10px)' position='absolute' zIndex={10} boxShadow='0.5px 0.5px 5px 0.5px rgba(52, 48, 77, 0.2)'>
    {
      list.map((media, i) => (
        <Div
          backgroundColor={i % 2 === 0 ? colors.white : colors.superLightGrey}
          borderTop={i > 0 ? `1px solid ${colors.lightGreyBorder}` : 'none'}
          cursor='pointer'
          key={i}
          onClick={() => fallbackQuery ? fallbackQuery(media) : setMediaFromQuery(media, parentContext)}
        >
          <P fontSize={11} padding='5px 10px'><Red>{media[mediaPropertyToDisplay]}</Red></P>
        </Div>
      ))
    }
  </Div>
);

export const PlayerConfiguration = ({
  videoPlayer,
  individualModal,
  setErrorMessage
}) => {
  if (videoPlayer === 'youtube') return <YoutubeCmp
    setErrorMessage={setErrorMessage}
    individualModal={individualModal}
    setActiveVideoId={setActiveVideoId}
  />;
  if (videoPlayer === 'facebook') return <FacebookCmp
    setErrorMessage={setErrorMessage}
    individualModal={individualModal}
    setActiveVideoId={setActiveVideoId}
  />;
  if (videoPlayer === 'flowplayer') return <FlowplayerConfiguration />;
  if (videoPlayer === 'brightcove') return <BrightcoveConfiguration />;
  if (videoPlayer === 'jwplayer') return <JwplayerConfiguration />;
  return null;
};

export class VideoUrlInputCmp extends Component {
  componentDidMount() {
    if (this.props.isActive) this.textInput.focus();
  }

  render() {
    const { value, onChange } = this.props;
    return <VideoUrlInput ref={node => this.textInput = node} type='text' value={value} onChange={onChange} letterCount={value.length} />;
  }
}

export const getYouTubeVideoIdFromVideoUrl = videoUrl => videoUrl.split('?v=')[1];
export const getFacebookIdsFromVideoUrl = videoUrl => {
  const [url, facebookUserId, facebookVideoId] = videoUrl.match(/^https:\/\/www\.facebook\.com\/(.*)\/videos\/(.*)$/);
  return { url, facebookUserId, facebookVideoId };
};

export const setActiveVideoId = (func, activeVideoId, individualModal) => func(individualModal, { activeVideoId });

export const onMediaNameChange = async (e, parentContext) => {
  const { individualModal } = parentContext.props;
  parentContext.addToInProgressEditsCount();
  const mediaName = e.target.value;
  individualModal.internalState.mediaName = mediaName;

  const response = await API.get(`media/?limit=5&name=${mediaName}`);
  if (response && response.status === 200) {
    const mediaQueryList = response.data.map(item => item.media);
    return parentContext.setState({ mediaQueryList });
  }
  parentContext.setErrorMessage('We couldn\'t search for media at this time. Please try again later.');
}

const setMediaFromQuery = (media, parentContext) => {
  const { individualModal } = parentContext.props;
  individualModal.hasBeenEdited = true;
  individualModal.internalState = {
    ...individualModal.internalState,
    mediaName: media.name,
    tagflixMediaId: media.id
  };
  parentContext.setState({ mediaQueryList: [] });
};
