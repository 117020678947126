import { colors, flexSpaceBetween } from '../../style/Constants';
import { DivComponent, ImgComponent, PComponent, ExtendComponentStyles, FormComponent  } from '../../style/Global';
import { Img, flexColumn } from '../../style/StyledCmps';

export const HomeDiv = DivComponent({
  ...flexColumn,
  position: 'relative',
  padding: '120px 0 90px 0',
  minHeight: '100vh',
  width: '100%'
});

export const Background = DivComponent({
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: -1
});

export const BackgroundTop = DivComponent({
  position: 'relative',
  height: '45%',
  overflow: 'hidden'
});

export const BackgroundBottom = DivComponent({
  height: '55%',
  overflow: 'hidden'
});

export const BkgBottomImg = ExtendComponentStyles(Img)({ minWidth: '1024px' });

export const Overlay = DivComponent({
  position: 'relative',
  width: 420,
  backgroundColor: colors.white,
  boxShadow: '0 20px 40px 0 rgba(52, 48, 77, 0.2)',
  marginBottom: 65
});

export const OverlayHeader = DivComponent({
  ...flexColumn,
  padding: '35px 0 24px 0',
  borderBottom: '2px solid #dfdfdf'
});

export const Logo = ImgComponent({ marginBottom: 24 });

export const OverlayBody = FormComponent({
  ...flexColumn,
  padding: 35
});

export const InputTitle = DivComponent({
  ...flexSpaceBetween,
  width: '100%',
  marginBottom: 9
});

export const Icons = DivComponent({
  display: 'flex',
  justifyContent: 'space-between',
  width: 112
});

export const RequiredWarning = PComponent({
  color: colors.red,
  fontSize: 16,
  fontStyle: 'italic'
});

export const ShowHidePassword = PComponent({
  position: 'absolute',
  right: 16,
  top: 12,
  color: colors.mediumGreyText,
  fontWeight: 600,
  cursor: 'pointer'
});
