import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { P, H4, Div } from '../../../style/Global';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';
import { MessageCmp } from '../../../style/StyledCmps';
import { WhiteButtonContainer } from '../../../style/FormStyle';
import PasswordInput from '../../../components/shared/PasswordInputCmp';

@inject('app')
@observer
class EnterPassword extends Component {
  constructor() {
    super();
    this.state = { password1: '', password2: '', errorMessage: '' };
    this.onPassword1Change = e => this.setState({ password1: e.target.value });
    this.onPassword2Change = e => this.setState({ password2: e.target.value });
    this.setErrorMessage = errorMessage => this.setState({ errorMessage });
    this.clearErrorMessage = () => this.setState({ errorMessage: '' });
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = async e => {
    e.preventDefault();
    const { app } = this.props
    const { password1, password2 } = this.state;
    if (!password1) return this.setErrorMessage('Please input a password.');
    if (password1 !== password2) return this.setErrorMessage('Both passwords did not match. Please try again.');
    this.clearErrorMessage();
    await app.resetPassword(password1)
  }

  render() {
    const { password1, password2, errorMessage } = this.state;
    return (
      <form>
        <P paddingBottom={25}>Elect a new password below.</P>

        <H4 paddingBottom={10}>New password</H4>
        <PasswordInput value={password1} onChange={this.onPassword1Change} underlined/>

        <H4 paddingBottom={10} paddingTop={25}>New password again</H4>
        <PasswordInput value={password2} onChange={this.onPassword2Change} underlined/>

        { errorMessage && <MessageCmp type='error'><p>{errorMessage}</p></MessageCmp> }

        <WhiteButtonContainer>
          <WhiteButton icon='far fa-envelope-open' text='Reset password' onClick={this.onSubmit} />
        </WhiteButtonContainer>
      </form>
    );
  }
}

@inject('modal')
@observer
class ExpiredCodeModal extends Component {
  render() {
    const { modal } = this.props;
    return (
      <Div>
        <P>Sorry, invalid `email` and `code` combination.</P>
        <WhiteButtonContainer>
          <WhiteButton icon='fa-check' text='Ok' closeModal={modal.close} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

@inject('modal')
@observer
class SuccessfulPasswordReset extends Component {
  render() {
    const { modal } = this.props;
    return (
      <Div>
        <P>Your password has been successfully reset. Please login again.</P>
        <WhiteButtonContainer>
          <WhiteButton icon='fa-check' text='Ok' closeModal={modal.close} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

const resetPasswordModals = {
  enterPassword: {
    title: 'Reset Password',
    component: EnterPassword
  },
  success: {
    title: 'Reset Password',
    component: SuccessfulPasswordReset
  },
  expiredCodeModal: {
    title: 'Invalid Reset Code',
    component: ExpiredCodeModal
  }
};

export default resetPasswordModals;
