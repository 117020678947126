import React, { Component } from 'react';
import { Div, DivComponent, LabelComponent } from '../../../style/Global';
import Icon from '../FontAwesomeIconCmp';
import { colors } from '../../../style/Constants';
import { BorderedInput } from '../../../style/FormStyle.js';

class FilterInput extends Component {
  render() {

    const { store, createObservableInput, id, inputLabel, inputPlaceholder, iconName, applyFilterOnEnterPress } = this.props;
    const inputStoreValue = store.inputs[id];

    return (
      <FlexContainer>
        <Label>{inputLabel}</Label>
        <BorderedInput
          placeholder={inputPlaceholder}
          margin='0 10px'
          padding='0 5px'
          width='269px'
          id={id}
          className='filter-string'
          borderColor={colors.greyText}
          backgroundColor={store.disableForm ? 'rgba(210, 210, 210, 0.5)' : colors.white}
          value={ inputStoreValue === undefined ? store.defaultInputValue : inputStoreValue }
          onChange={event => createObservableInput(id, event.target.value)}
          onKeyPress={applyFilterOnEnterPress}
        />
        <InputIcon name={iconName} inputDisabled={store.disableForm}/>
      </FlexContainer>
    );
  }
}

const FlexContainer = DivComponent({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '10px 0'
});

const Label = LabelComponent({
  fontSize: '14px',
  color: colors.darkGreyText,
  textAlign: 'right'
});

const InputIcon = ({ name, inputDisabled }) => (
  <Div position='absolute' right={17} cursor='pointer'>
    <Icon name={name} color={inputDisabled ? colors.grey : colors.red } />
  </Div>
);

export default FilterInput;
