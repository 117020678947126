import { observable, action, computed } from 'mobx';
import {
  getDefaultScrubberValues,
  getTicks,
  checkMediaResponseAndReturnIfError,
  checkOffsetResponseAndReturnIfError,
  formatErrorAsReferenceNumber
} from './SyncMediaOffsetUtil';
import API from '../../../../util/ApiUtil';
import { iFrameSeekTo } from './helpers';

class SyncMediaOffsetModalStore {
  @observable mediaId;
  @observable publisherVideoId;
  @observable offset;
  @observable fps;
  @observable totalFrames;
  @observable offsetFrame;
  @observable originalFrame;
  @observable product;
  @observable productCoordinates;
  @observable aspectRatio = 16 / 9;
  @observable loaded = false;
  @observable errorMessage = '';
  @observable videoPlayerIsReady = false;
  @observable notchValues = [ 0, 50, 100 ];
  @observable originalNotchValues = [ 0, 50, 100 ];
  @observable scrubberMinValue = 0;
  @observable scrubberMaxValue = 3000;
  @observable ticks = { mode: 'seconds', show: false, numberOfTicks: 0 };
  @computed get startAtSecond() { return (this.offsetFrame / this.fps).toFixed(3); };

  @action loadMedia = async mapping => {
    const [ mediaResponse, offsetResponse ] = await Promise.all([
      API.post('portal/media', { data: { publisherVideoId: mapping.videoId, pubId: API.publisherId() } }),
      API.get(`portal/media/${mapping.tagflixMediaId}/offset`)
    ]);
    const mediaError = checkMediaResponseAndReturnIfError(mediaResponse);
    const offsetError = checkOffsetResponseAndReturnIfError(offsetResponse);
    if (mediaError || offsetError) return this.errorMessage = formatErrorAsReferenceNumber(
      mediaError || offsetError,
      mapping.videoId, mapping.tagflixMediaId
    );
    const mediaData = mediaResponse.data;
    const offsetData = offsetResponse.data;

    this.publisherVideoId = mapping.videoId;
    this.mediaId = mapping.tagflixMediaId;
    this.offsetMs = mediaData.offsetMs;
    this.originalFrame = offsetData.frame.frame;
    this.fps = offsetData.fps;
    this.offsetFrame = this.originalFrame + Math.round(this.offsetMs / this.fps);
    this.totalFrames = offsetData.totalFrames;
    this.productCoordinates = offsetData.point;
    this.aspectRatio = offsetData.sourceWidth / offsetData.sourceHeight;
    this.offset = mediaData.offsetMs;
    this.product = {
      name: offsetData.product.name,
      imageUrl: offsetData.product.imageUrl,
      imageDimensions: { width: 135, height: 135 }
    };

    if (mapping.videoPlayer === 'youtube') this.videoId = mediaData.videoProps.url.split('=')[1];
    this.originalNotchValues = this.setDefaultScrubberValues();
    this.scrubberMinValue = this.originalNotchValues[0];
    this.scrubberMaxValue = this.originalNotchValues[2];
    this.loaded = true;
  };

  @action setVideoPlayerIsReady = () => this.videoPlayerIsReady = true;

  @action reset = () => {
    this.mediaId = '';
    this.publisherVideoId = '';
    this.offsetMs = 0;
    this.fps = 0;
    this.totalFrames = 0;
    this.offsetFrame = 0;
    this.originalFrame = 0;
    this.productCoordinates = {};
    this.aspectRatio = 0;
    this.product = {};
    this.videoId = '';
    this.loaded = false;
    this.errorMessage = '';
    this.videoPlayerIsReady = false;
    this.notchValues = [ 0, 50, 100 ];
    this.originalNotchValues = [ 0, 50, 100 ];
    this.scrubberMinValue = 0;
    this.scrubberMaxValue = 3000;
    this.ticks = { mode: 'seconds', show: false, numberOfTicks: 0 };
  };

  @action setDefaultScrubberValues = () => {
    const defaultHandleValues = getDefaultScrubberValues(this.offsetFrame, this.fps, this.totalFrames);
    this.notchValues = defaultHandleValues;
    return defaultHandleValues;
  };

  @action onSliderValueChange = values => {
    if (!this.loaded) return;
    const middleNotch = values[1];
    const defaultMiddleNotchValue = this.notchValues[1];
    if (middleNotch !== defaultMiddleNotchValue) {
      const second = values[1] / this.fps;
      iFrameSeekTo(second);
    };
    this.notchValues = values;
  };

  @action afterSliderValueChange = values => {
    if (!this.loaded) return;
    const leftNotch = values[0];
    const rightNotch = values[2];
    if (leftNotch !== this.scrubberMinValue) this.scrubberMinValue = leftNotch;
    if (rightNotch !== this.scrubberMaxValue) this.scrubberMaxValue = rightNotch;
    this.ticks = getTicks(this.notchValues, this.fps);
  };

  @action resetScrubberMinValue = () => {
    this.scrubberMinValue = this.originalNotchValues[0];
    const newHandleValues = [ ...this.notchValues ];
    newHandleValues[0] = this.scrubberMinValue;
    this.notchValues = newHandleValues;
    this.ticks = getTicks(newHandleValues, this.fps);
  };

  @action resetScrubberMaxValue = () => {
    this.scrubberMaxValue = this.originalNotchValues[2];
    const newHandleValues = [ ...this.notchValues ];
    newHandleValues[2] = this.scrubberMaxValue;
    this.notchValues = newHandleValues;
    this.ticks = getTicks(newHandleValues, this.fps);
  };

  @computed get resetScrubberButtonEnabled() {
    return {
      backward: this.scrubberMinValue !== this.originalNotchValues[0],
      forward: this.scrubberMaxValue !== this.originalNotchValues[2]
    };
  };

  @action save = async () => {
    const scrubberFrame = this.notchValues[1];
    const offsetMs = Math.round((scrubberFrame - this.originalFrame) * this.fps);
    const data = { publisherVideoId: this.publisherVideoId, offsetMs };
    await API.put('portal/media', { data: { ...data, pubId: API.publisherId() } });
  };
};

export default SyncMediaOffsetModalStore;
