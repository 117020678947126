import AccountStore from '../pages/settings/account/AccountStore';
import HistoryStore from '../pages/history/HistoryStore';
import MediaMappingStore from '../pages/mediaMapping/MediaMappingStore';
import KeysStore from '../pages/settings/keys/KeysStore';
import TeamStore from '../pages/settings/team/TeamStore';
import ModalStore from '../components/shared/modal/ModalStore';
import SyncMediaOffsetStore from '../pages/mediaMapping/modals/syncMediaOffsetModal/SyncMediaOffsetStore';
import VideoStore from '../pages/videos/VideoStore';
import FlowplayerStore from '../pages/mediaMapping/modals/flowplayer/FlowplayerStore';
import BrightcoveStore from '../pages/mediaMapping/modals/brightcove/BrightcoveStore';
import AppStore from '../components/app/AppStore';
import OverlayDefaultsStore from '../pages/settings/overlay/OverlayDefaultsStore';
import ReportStore from '../pages/dashboard/ReportStore';
import JwplayerStore from '../pages/mediaMapping/modals/jwplayer/JwplayerStore';

let modal = new ModalStore;

export const createStores = () => ({
  modal,
  app: new AppStore(modal),
  account: new AccountStore,
  publisherHistory: new HistoryStore,
  keys: new KeysStore,
  overlayDefaults: new OverlayDefaultsStore,
  mediaMappings: new MediaMappingStore,
  video: new VideoStore,
  team: new TeamStore,
  mediaOffset: new SyncMediaOffsetStore,
  reportStore: new ReportStore,
  flowplayerStore: new FlowplayerStore,
  brightcoveStore: new BrightcoveStore,
  jwplayerStore: new JwplayerStore
});

export const stores = createStores();
