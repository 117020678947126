import { observable, action } from 'mobx';

class ModalStore {
  @observable modals = [];

  @action open = modal => {
    modal.internalState = {};
    modal.hasBeenEdited = false;
    this.modals.push(modal);
  }

  @action close = () => {
    this.findCurrentModal().dismissLinkFunction(this.findCurrentModal().dismissModalLink);
    this.modals.pop();
  }

  @action findCurrentModal = () => this.modals[this.modals.length - 1];
};

export default ModalStore;
