import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Div, H5, P } from '../../../style/Global';
import { limitStringLength } from '../../../util/TextUtil';
import { Bold, Italic, Red, SmallText } from '../../../style/Typography';
import { colors } from '../../../style/Constants';
import { WhiteButtonContainer } from '../../../style/FormStyle';
import { PinkBox, MessageCmp } from '../../../style/StyledCmps';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';
import GreyBoxWithArrow from './GreyBoxWithArrowCmp';
import Auth from '../../../components/shared/AuthCmp';

@inject('mediaMappings', 'modal')
@observer
class DeleteMappingModal extends Component {
  constructor() {
    super();
    this.state = { errorMessage: '' };
    this.setErrorMessage = errorMessage => this.setState({ errorMessage });
    this.deleteMapping = this.deleteMapping.bind(this);
  }

  async deleteMapping() {
    const { mapping, mediaMappings, modal } = this.props;
    const responseStatus = await mediaMappings.delete(mapping.videoId);
    if (responseStatus === 204) return modal.close();
    this.setErrorMessage('We couldn\'t delete the mapping at this time. Please try again later.');
  }

  render() {
    const { mapping, openChangeMappingModal } = this.props;
    const { errorMessage } = this.state;
    return (
      <Div>
        <P paddingBottom={20}>You are about to delete the following mapping.</P>
        <H5 paddingBottom={10}>Confirm delete of your video id</H5>
        <GreyBoxWithArrow>
          <GreyTextBox text={mapping.videoId}/>
        </GreyBoxWithArrow>
        <Div position='relative'>
          <H5 position='absolute' bottom={12} left={92}>Mapped to Tagflix media</H5>
        </Div>
        <GreyTextBox text={mapping.mediaName} style={{ backgroundColor: colors.lightGrey, margin: '0 0 20px 10px' }}/>
        <PinkBox darker>
          <SmallText padding={5}><Bold><Italic>Warning:</Italic></Bold> the Tagflix embedded video on your site will cease to be available should you proceed. Make sure you have replaced the integration with your own player before doing so. If you want to temporarily disable the overlay, you can <Red cursor='pointer' onClick={openChangeMappingModal}>do that instead</Red>.</SmallText>
        </PinkBox>

        { errorMessage && <MessageCmp type='error'><p>{errorMessage}</p></MessageCmp> }

        <WhiteButtonContainer>
          <WhiteButton icon='fa-trash-alt' text='Delete' onClick={this.deleteMapping} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

const GreyTextBox = ({ text, style = {} }) => (
  <Div {...style} border={`1px solid ${colors.grey}`} height={26}><P padding='2px 0 0 10px'>{limitStringLength(text, 56)}</P></Div>

);

export default Auth(DeleteMappingModal);
