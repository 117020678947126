import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Div, Img } from '../../../../style/Global';
import WhiteButton from '../../../../components/shared/WhiteButtonCmp';
import { WhiteButtonContainer } from '../../../../style/FormStyle';
import { Bold, SmallText } from '../../../../style/Typography';
import {
  MediaOffsetSubcontainer,
  VideoControlContainer,
  VideoPlayerDiv,
  GreyLine,
  BigNumber,
  BigNumberTitle
} from './SyncMediaOffsetStyle';
import ScrubberControl from './scrubberControl/ScrubberControlCmp';
import CrosshairOverlay, { LoadingInProgressOverlay } from './crosshairOverlay/CrosshairOverlayCmp';
import CodeCopyableTextArea from '../../../../components/shared/CodeCopyableTextAreaCmp';
import Auth from '../../../../components/shared/AuthCmp';
import { readyPostMessageReceived, playingPostMessageReceived, PUBLISHER_SOLUTION_IFRAME_ID } from './helpers';
import Helper from '../../../../util/TagflixStorageUtil/helpers';
import config from '../../../../config/config';
const { determineCdnSrcUrl } = Helper;

@inject('mediaOffset', 'modal', 'mediaMappings', 'app')
@observer
class SyncMediaOffsetCmp extends Component {
  constructor() {
    super();
    this.save = this.save.bind(this);
  };

  state = {
    isLoading: true,
    errorMessage: null
  };

  async componentDidMount() {
    const { mediaOffset, mapping } = this.props;
    await mediaOffset.loadMedia(mapping);
    this.timerHandle = setTimeout(() => this.setState({ isLoading: false, errorMessage: { title: 'Sorry, cannot load your video at this time.', message: 'Please check your media mappings and player settings.' } }), 20000);
    window.addEventListener('message', this.postMessageListener);
  };

  componentWillUnmount() {
    window.removeEventListener('message', this.postMessageListener);
    if (this.timerHandle) clearTimeout(this.timerHandle);
    this.props.mediaOffset.reset();
  };

  postMessageListener = e => {
    const { mediaOffset } = this.props;
    const { startAtSecond } = mediaOffset;
    const { message } = e.data;
    const getHostname = url => {
      const a = document.createElement('a');
      a.href = url;
      return a.hostname;
    };
    if (getHostname(config.publisherSolutionDomain) !== getHostname(e.origin)) return;
    if (!message) return;
    if (message === 'ready') readyPostMessageReceived(startAtSecond);
    if (message === 'playing') {
      playingPostMessageReceived();
      this.setState({ isLoading: false, errorMessage: null });
      if (this.timerHandle) clearTimeout(this.timerHandle);
    };
  };

  async save() {
    const { mapping, modal, mediaMappings, mediaOffset } = this.props;
    await mediaOffset.save();
    mediaMappings.markVideoIdSaved(mapping.videoId);
    modal.close();
  }

  render() {
    const { mediaOffset, modal, mapping, app } = this.props;
    const { product, offsetMs, offsetFrame } = mediaOffset;
    const { videoId } = mapping;
    const { id } = app.publisher;
    return (
      <Div id='sync-media-offset-modal'>
        {
          (!mediaOffset.loaded && !mediaOffset.errorMessage) &&
          <p>Loading, one moment please...</p>
        }

        {
          mediaOffset.errorMessage &&
          <div>
            <p>
              It looks like this media cannot be synced due to missing information.
              <br /><br />
              Please contact your account manager and include the following reference number:
            </p>
            <CodeCopyableTextArea margin='15px 0 0 0' clickToCopyStyles={{ bottom: 0, left: '37%' }} code={mediaOffset.errorMessage} copyable />
            <WhiteButtonContainer>
              <WhiteButton icon='fa-check' text='Contact account manager' route='/support' closeModal={modal.close} />
            </WhiteButtonContainer>
          </div>
        }

        {
          mediaOffset.loaded &&
          <div>
            <p>Tagged data may not match exactly video starting point. You can customise the offset here.
              <br /><br />
              Use the playhead controls below to locate the product pictured right within the video below such that it fits within the focus frame as close as possible.</p>

            <MediaOffsetSubcontainer>
              <VideoControlContainer>
                <VideoPlayerDiv id='video-player' position='relative'>
                  {(this.state.isLoading) && <LoadingInProgressOverlay title='Initializing Player...' message='Please wait.' />}
                  {(this.state.errorMessage) && <LoadingInProgressOverlay isLoading={this.state.isLoading} title={this.state.errorMessage.title} message={this.state.errorMessage.message} />}
                  <iframe
                    style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', zIndex: 4 }}
                    frameBorder='0' type='text/html'
                    allowFullScreen={true}
                    id={PUBLISHER_SOLUTION_IFRAME_ID}
                    allow='autoplay'
                    webkitallowfullscreen='true'
                    mozallowfullscreen='true'
                    onLoad={mediaOffset.setVideoPlayerIsReady}
                    src={`${determineCdnSrcUrl(id)}?publisherVideoId=${videoId}&visualization=hidden&doNotLoadSeeds=true`}
                  />
                </VideoPlayerDiv>
                {!this.state.isLoading && !this.state.errorMessage && <CrosshairOverlay />}
                <ScrubberControl />
              </VideoControlContainer>

              <div>
                <Div width='100%'>
                  <Img width='100%' src={product.imageUrl} paddingBottom={10} />
                  <SmallText><Bold>{product.name}</Bold></SmallText>

                  <GreyLine />

                  <BigNumberTitle>product data frame</BigNumberTitle>
                  <BigNumber lightGrey>{offsetFrame}</BigNumber>

                  <BigNumberTitle>media offset (ms)</BigNumberTitle>
                  <BigNumber>{`${offsetMs > 0 ? '+' : ''}${offsetMs}`}</BigNumber>
                </Div>
              </div>
            </MediaOffsetSubcontainer>

            <WhiteButtonContainer>
              <WhiteButton icon='fa-check' text='Save' onClick={this.save} />
            </WhiteButtonContainer>
          </div>
        }
      </Div>
    );
  };
};

export default Auth(SyncMediaOffsetCmp);
