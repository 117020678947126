import { DivComponent, HeaderComponent, ImgComponent, ExtendComponentStyles } from './Global';
import { colors } from './Constants';
import { TinyThinText } from './Typography';
import { filterPropsForStyling } from './StyleUtil';

export const ModalDiv = DivComponent({
  padding: 30,
  width: 470,
  boxShadow: '0 20px 40px 0 rgba(52, 48, 77, 0.2), 0 4px 8px 0 rgba(41, 49, 67, 0.4)'
});

export const ModalHeader = HeaderComponent({
  height: 62,
  backgroundColor: colors.lightPink,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'spaceBetween',
  padding: '0 20px'
});

export const ModalBody = DivComponent({
  padding: 25,
  display: 'flex',
  flexDirection: 'column'
});

export const MessageCmp = DivComponent(props => ({
  width: 'auto',
  margin: '15px 0 0 0',
  fontSize: 12,
  padding: 9,
  backgroundColor: props.type === 'error' ? colors.mediumPink : props.type === 'success' ? colors.green : 'none',
  ...filterPropsForStyling(props)
}));

export const PinkBox = DivComponent(({ darker }) => ({
  backgroundColor: darker ? colors.mediumPink : colors.lightPink,
  padding: 5
}));

export const LightBeigeBox = DivComponent(props => ({
  backgroundColor: colors.lightBeige,
  padding: '10px 5px 10px 5px',
  ...filterPropsForStyling(props)
}));

export const GreyBox = DivComponent(props => ({
  backgroundColor: colors.lightGrey,
  padding: 10,
  ...filterPropsForStyling(props)
}));

export const Img = ImgComponent({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
});

export const AbsoluteImg = ExtendComponentStyles(Img)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0
});

export const Spacer = DivComponent(({ height }) => ({ height }));

export const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export const FlexColumn = DivComponent({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

export const LabelText = ExtendComponentStyles(TinyThinText)(props => ({
  margin: 'auto',
  ...filterPropsForStyling(props)
}));

export const LabelContainer = DivComponent(props => ({
  display: 'flex',
  flexBasis: 'auto',
  padding: '1px 2px 2px 5px',
  backgroundColor: colors.superLightGreyText,
  borderRadius: '10%',
  justifyContent: 'center',
  alignItems: 'end',
  marginRight: 7,
  ...filterPropsForStyling(props)
}));

export const LabelDeleteButton = DivComponent({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  width: 16,
  height: 16,
  padding: 2,
  marginLeft: 2
});
