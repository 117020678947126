import helper from './helpers';
import AzureStorage from './providers/azure-storage.file';
import Config from './../../config/config';

class PublicInterface {
  constructor(configuration = {}) {
    if(!configuration.pubId) throw new Error('`pubId` required.');
    if(!configuration.authToken) throw new Error('`authToken` required.');
    if(configuration.allowedFileTypes){
      if(!Array.isArray(configuration.allowedFileTypes)) throw new Error('`allowedFileTypes` must be array.');
    }

    this.pubId = configuration.pubId;
    this.authToken = configuration.authToken;
    this.allowedFileTypes = configuration.allowedFileTypes || [];
    
    this.provider = {};
    this.eventBus = {
      handlers: [],

      emitError: function(errorMessage, details = null){ this.emit({ type: 'error', message: errorMessage, details }); this.emitState('error'); },
      emitState: function(message){ this.emit({ type: 'state', message }); },
      emitStart: function(videoId){ this.emit({ type: 'start', videoId });},
      emitProgress: function(progress){ this.emit({ type: 'progress', progress }); },
      emitSuccess: function(successMessage){ this.emit({ type: 'success', message: successMessage }); this.emitState('success'); },
      
      emit: function(event){
        if(!event.type) throw new Error('Event `type` required.');
        const type = event.type;

        let hasErrorHandler = false;
        for(let handler of this.handlers){
          if(handler.type === type) handler.eventHandler(event);
          if(handler.type === 'error') hasErrorHandler = true;
        }
        
        if(!hasErrorHandler){
          if(event.type === 'error'){
            this.emitState('error');
            console.warn('TagflixStorageUtil: You should define at least one error handler. As you have no error handlers, error below was thrown so you would not miss it.');
            throw new Error(event.message);
          }
        }
      }
    };
  }

  on(eventType, eventHandler){
    this.eventBus.handlers.push({ type: eventType.toLowerCase(), eventHandler });
    return this;
  }

  getSelectedFileFromFileDOMElement(FileDOMElement){
    if(!FileDOMElement) return this.Error('Invalid DOM element.');
    if(!FileDOMElement.files) return this.Error('Invalid file DOM element.');
    const files = FileDOMElement.files;
    return files[0];
  }

  async uploadFile(file, metadata){
    this.UpdateState('initialize upload');
    if(!this.validateIncommingFile(file)) return;
    if(!this.validateMetaData(metadata)) return;

    if(Array.isArray(metadata.allowedFileTypes)) this.allowedFileTypes = metadata.allowedFileTypes;
    if(!this.isFileTypeAllowed(file)) return this.Error('File type not allowed.', this.getFileTypeFromFileName(file.name));

    this.UpdateState('request upload permissions');
    const uploadPermission = await this.getUploadPermission(file, metadata);
    if(!this.setHostingProviderUsingUploadPermission(uploadPermission)) return this.UpdateState('could not authorize');

    this.UpdateState('establish connection');
    const FileService = this.getFileService(uploadPermission.provider.token);
    if(!FileService) return this.Error('Cannot init file service.');

    this.UpdateState('start upload');
    this.eventBus.emitStart(uploadPermission.metadata.video_id);

    let progressPercent = 0;
    const progress = FileService.createFileFromBrowserFile(
      this.provider.fileShare, this.provider.directoryPath, uploadPermission.provider.filename, file, {
        contentSettings: { contentType: file.type },
        metadata: uploadPermission.metadata, storeFileContentMD5: true
      }, error => {
        if (error) {
          this.Error('Upload failed.', error);
          progressPercent = 0;
        } else {
          progressPercent = 100;
          this.eventBus.emitSuccess('complete');
          this.eventBus.emitState('upload complete');
        }
      }
    );
    
    progress.on('progress', () => {
      progressPercent = parseFloat(progress.getCompletePercent());
      this.eventBus.emitProgress(progressPercent);
    });
  }

  async getUploadPermission(file, metadata){
    const response = await helper.genericRequest('POST', `${Config.apiUrl}/portal/publisher/${this.pubId}/video`, {
      token: this.authToken, params: {
        filename: file.name, fileSizeBytes: file.size, fileType: this.getFileTypeFromFileName(file.name),
        labels: metadata.labels, videoName: metadata.videoName
      }
    });

    if(response.statusCode >= 400) return this.Error('Incorrect `pubId`, invalid or expired `authToken` or both.');
    return { provider: response.json.data, metadata: response.json.meta };
  }

  setHostingProviderUsingUploadPermission(uploadPermission){
    if(!uploadPermission) return this.Error('No upload permissions.');
    if(!(uploadPermission.provider && uploadPermission.provider.provider)) return this.Error('Upload `provider` not set.');
    if(!Array.isArray(uploadPermission.provider.providerAttributes)) return this.Error('Upload provider supplied no attributes.');

    this.provider = {};
    for(let attribute of uploadPermission.provider.providerAttributes){
      if(!uploadPermission.provider.hasOwnProperty(attribute)) return this.Error(`Provider requires '${attribute}' which is not supplied.`);
      this.provider[attribute] = uploadPermission.provider[attribute];
    }
    this.provider.host = uploadPermission.provider.provider;
    return true;
  }

  getFileService(sasToken){
    return AzureStorage.createFileServiceWithSas(this.provider.host, sasToken).withFilter(
      new AzureStorage.ExponentialRetryPolicyFilter()
    );
  }

  validateIncommingFile(file){
    if(!file) return this.Error('No file selected.');
    if(!file.name || !file.size) return this.Error('Invalid `File` object.', file);
    return true;
  }

  validateMetaData(metadata){
    if(!metadata) return this.Error('Upload `metadata` required.');
    if(!metadata.videoName) return this.Error('Metadata `videoName` required.');
    if(!Array.isArray(metadata.labels)) return this.Error('Metadata `labels` array required.');
    return true;
  }

  isFileTypeAllowed(file){
    for(let fileType of this.allowedFileTypes){
      if(fileType === this.getFileTypeFromFileName(file.name)) return true;
    }
    return false;
  }

  getFileTypeFromFileName(filename){
    const filenameParts = filename.split('.');
    if(filenameParts.length <= 1) return '';
    return filenameParts[filenameParts.length-1];
  }

  UpdateState(message){ this.eventBus.emitState(message); }

  Error(message, details){
    this.eventBus.emitError(message, details);
    return false;
  }
}

export default PublicInterface;
