import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Auth from '../../components/shared/AuthCmp';
import {
  SettingsDiv,
  SettingsSidebar,
  NavList,
  NavItem,
  SelectedNavItem
} from './SettingsStyle';
import Icon from '../../components/shared/FontAwesomeIconCmp';
import AccountPage from './account/AccountPage';
import TeamPage from './team/TeamPage';
import KeysPage from './keys/KeysPage';
import { colors } from '../../style/Constants';
import OverlayDefaultsPage from './overlay/OverlayDefaultsPage';
import { observer } from 'mobx-react';

@observer
class SettingsPage extends Component {
  constructor(props) {
    super();
    this.determineChild = this.determineChild.bind(this);
    this.setActivePage = this.setActivePage.bind(this);
    this.state = { activePage: 'account', currentPublisherId: props.app.publisher.id };
  }

  componentDidMount() {
    this.setActivePage(this.props.match.params.page);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.page !== prevProps.match.params.page) this.setActivePage(this.props.match.params.page);
    if (this.props.app.publisher.id !== prevState.currentPublisherId) {
      this.setState({ currentPublisherId: this.props.app.publisher.id }, () => this.props.history.push('/settings/account'));
    }
  }

  setActivePage = activePage => this.setState({ activePage });

  determineChild = () => {
    if (this.state.activePage === 'overlay-defaults' && this.props.app.publisherOverlayDefaults.primaryColor) return <OverlayDefaultsPage />;
    if (this.state.activePage === 'keys') return <KeysPage />;
    if (this.state.activePage === 'team') return <TeamPage />;
    return <AccountPage />;
  }

  render() {
    if(this.state.activePage === 'overlay-defaults' && !this.props.app.publisherOverlayDefaults.primaryColor) return <Redirect to={'/settings/account'} />
    return (
      <SettingsDiv>
        <SettingsSidebar>
          <NavList>
            <Link to={'/settings/account'}>
              <NavButton pageIsActive={this.state.activePage === 'account'} icon='fa-user-circle' text='Your account'/>
            </Link>
            { this.props.app.publisherOverlayDefaults.primaryColor &&
            <Link to={'/settings/overlay-defaults'}>
              <NavButton pageIsActive={this.state.activePage === 'overlay-defaults'} icon='fas fa-sliders-h' text='Overlay defaults'/>
            </Link>
            }
            {/* <NavButton icon="fa-users" text="Team" onClick={() => this.setActivePage('team')} /> */}
            <Link to={'/settings/keys'}>
              <NavButton pageIsActive={this.state.activePage === 'keys'} icon='fa-key' text='API keys' />
            </Link>
          </NavList>
        </SettingsSidebar>
        { this.determineChild() }

      </SettingsDiv>
    );
  }
}

const NavButton = ({ pageIsActive, icon, text }) => {
  if (pageIsActive) return (
    <SelectedNavItem>
      <Icon name={icon} color={colors.white} margin='0 8px 0 0'/>
      <p>{text}</p>
    </SelectedNavItem>
  );
  return (
    <NavItem>
      <Icon name={icon} margin='0 8px 0 0'/>
      <p>{text}</p>
    </NavItem>
  );
};

export default Auth(SettingsPage);
