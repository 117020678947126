import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { P, H4, Div } from '../../../style/Global';
import API from '../../../util/ApiUtil';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';
import { MessageCmp } from '../../../style/StyledCmps';
import { WhiteButtonContainer } from '../../../style/FormStyle';
import PasswordInput from '../../../components/shared/PasswordInputCmp';

@inject('modal')
@observer
class InvalidInvite extends Component {
  render() {
    const { modal } = this.props;
    return (
      <Div>
        <P lineHeight={1.6}>Sorry, the invite link appears to be invalid or has expired.</P>
        <WhiteButtonContainer>
          <WhiteButton icon='fa-check' text='Ok' closeModal={modal.close} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

@inject('modal')
@observer
class AcceptTeammateInvite extends Component {
  constructor() {
    super();
    this.state = { password1: '', password2: '', errorMessage: '' };
    this.onPassword1Change = e => this.setState({ password1: e.target.value });
    this.onPassword2Change = e => this.setState({ password2: e.target.value });
    this.setErrorMessage = errorMessage => this.setState({ errorMessage });
    this.clearErrorMessage = () => this.setState({ errorMessage: '' });
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const { modal } = this.props;
    const { password1, password2 } = this.state;
    if (!password1) return this.setErrorMessage('Please input a password.');
    if (password1 !== password2) {
      return this.setErrorMessage('Both passwords did not match. Please try again.');
    }
    this.clearErrorMessage();
    modal.open(resetPasswordModals.success);
  }

  render() {
    const { password1, password2, errorMessage } = this.state;
    return (
      <Div>
        <P paddingBottom={25}>Setup your password for your portal invite.</P>

        <H4 paddingBottom={10}>New password</H4>
        <PasswordInput value={password1} onChange={this.onPassword1Change} underlined/>

        <H4 paddingBottom={10} paddingTop={25}>New password again</H4>
        <PasswordInput value={password2} onChange={this.onPassword2Change} underlined/>

        { errorMessage && <MessageCmp type='error'><p>{errorMessage}</p></MessageCmp> }

        <WhiteButtonContainer>
          <WhiteButton icon='far fa-envelope-open' text='Reset password' onClick={this.onSubmit} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

@inject('modal')
@observer
class AccountSetupSuccess extends Component {
  render() {
    const { modal } = this.props;
    return (
      <Div>
        <P lineHeight={1.6}>You may now login to your portal account using your email and password combination.</P>
        <WhiteButtonContainer>
          <WhiteButton icon='fa-check' text='Ok' closeModal={modal.close} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

const determineModal = async () => {
  const response = await API.post('portal/teammate/invite/accept', { data });
  if (response.status === 200) return acceptInviteModals.acceptInvite;
  return acceptInviteModals.invalidInvite;
};

export const acceptInviteModals = {
  determineModal,
  invalidInvite: {
    title: 'Invalid invite',
    component: InvalidInvite
  },
  acceptInvite: {
    title: 'Accept teammate invite',
    component: AcceptTeammateInvite
  },
  success: {
    title: 'Account setup successful!',
    component: AccountSetupSuccess
  }
};

export default acceptInviteModals;
