import React from 'react';
import Toggle from 'react-toggle'
import EditableComponent from './EditableComponent';

export default class ToggleCmp extends EditableComponent {

  onChange = () => {
    const { onChange } = this.props;
    this.addToInProgressEditsCount();
    onChange();
  }

  render() {
    const { checked, icons } = this.props;
    return <Toggle checked={checked} onChange={this.onChange} icons={icons}/>
  }
}
