import React, { Component } from 'react';
import { colors, flexCenter } from '../../style/Constants';
import { copyTextToClipboard } from '../../util/TextUtil';
import { COPY_BUTTON_TIMEOUT } from '../../config/TimeoutConfig';
import { Div, P, DivComponent } from '../../style/Global';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

class CodeCopyableTextArea extends Component {
  constructor() {
    super();
    this.state = { clickAnimationInProgress: false, copyButtonText: 'click to copy', timeoutIds: [] };
    this.addTimeoutId = timeoutId => this.state.timeoutIds.push(timeoutId);
    this.onCopyCodeClick = this.onCopyCodeClick.bind(this);
    this.changeCopyButtonText = this.changeCopyButtonText.bind(this);
  }

  componentWillUnmount() {
    const { timeoutIds } = this.state;
    if (timeoutIds.length > 0) timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
  }

  onCopyCodeClick() {
    const { code } = this.props;
    copyTextToClipboard(code);
    if (!this.state.clickAnimationInProgress) {
      const timeoutId = setTimeout(() => {
        this.setState({ clickAnimationInProgress: false });
        this.changeCopyButtonText('click to copy', COPY_BUTTON_TIMEOUT);
      }, 2400);
      this.addTimeoutId(timeoutId);
    };
    this.setState({ clickAnimationInProgress: true });
    this.changeCopyButtonText('copied!');
  }

  changeCopyButtonText(newText, delay = 0) {
    const timeoutId = setTimeout(() => {
      this.setState({ copyButtonText: newText });
    }, delay);
    this.addTimeoutId(timeoutId);
  }
;
  render() {
    const { code, margin = 0, clickToCopyStyles } = this.props;
    const showCopyButton = this.props.copyable;
    const { clickAnimationInProgress, copyButtonText } = this.state;

    return (
      <Div position='relative' margin={margin}>
        <EmbeddedCodeContainer clickAnimationInProgress={clickAnimationInProgress} height={this.props.height} width={this.props.width} padding={this.props.padding}>
          <P width='100%' wordWrap='break-word' overflowY='auto' fontFamily='"Courier New", Courier, monospace'>{code}</P>
        </EmbeddedCodeContainer>
        {
          showCopyButton &&
          <ClickToCopy onClick={this.onCopyCodeClick} clickToCopyStyles={clickToCopyStyles} clickAnimationInProgress={clickAnimationInProgress}>
            <p>{copyButtonText}</p>
          </ClickToCopy>
        }
      </Div>
    );
  };
};

const transitionMs = { start: 160, end: 1600 };

const EmbeddedCodeContainer = ({ children, clickAnimationInProgress, width, height, padding = 6 }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width,
    height,
    padding,
    backgroundColor: clickAnimationInProgress ? colors.green : colors.superLightGreyText,
    border: `1px solid ${clickAnimationInProgress ? colors.green : colors.red}`,
    transition: `border ${clickAnimationInProgress ? transitionMs.start : transitionMs.end}ms ease, background-color ${clickAnimationInProgress ? transitionMs.start : transitionMs.end}ms ease`
  };
  return <OverlayScrollbarsComponent style={style}><Div>{children}</Div></OverlayScrollbarsComponent>;
};

const ClickToCopy = DivComponent(({ clickToCopyStyles, clickAnimationInProgress }) => ({
  ...flexCenter,
  width: 145,
  height: 22,
  position: 'absolute',
  zIndex: 5,
  bottom: clickToCopyStyles.bottom && clickToCopyStyles.bottom,
  left: clickToCopyStyles.left && clickToCopyStyles.left,
  color: clickAnimationInProgress ? colors.darkGreyText : colors.white,
  fontWeight: clickAnimationInProgress ? 600 : 400,
  backgroundColor: clickAnimationInProgress ? colors.green : colors.red,
  cursor: 'pointer',
  transition: `background-color ${clickAnimationInProgress ? transitionMs.start : transitionMs.end}ms ease`
}));

export default CodeCopyableTextArea;
