import React, { Component } from 'react';
import { P, LabelComponent, DivComponent, InputComponent, PComponent, ExtendComponentStyles } from '../../../style/Global';
import { observer, inject } from 'mobx-react';
import { colors } from '../../../style/Constants';
import Icon from '../../../components/shared/FontAwesomeIconCmp';
import { Red, White, DarkGrassGreen, Bold } from '../../../style/Typography';
import { formatBytes } from '../../../util/UnitConversions';

@inject('video', 'app')
@observer
export default class FileSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unsupportedFileType: false,
      compatibleFileSelected: false,
      maxVideoUploadSizeMbExceeded: false,
      applyFileSelectorBorder: false
    };

    this.dropHandler = this.dropHandler.bind(this);
    this.dragOverHandler = this.dragOverHandler.bind(this);
    this.fileSelectedViaFileExplorer = this.fileSelectedViaFileExplorer.bind(this);
    this.dragEnterHandler = this.dragEnterHandler.bind(this);
    this.dragLeaveHandler = this.dragLeaveHandler.bind(this);
    this.getFileExtension = this.getFileExtension.bind(this);
    this.dragAndDropHandler = this.dragAndDropHandler.bind(this);
    this.appendPeriodToAcceptedFileType = this.appendPeriodToAcceptedFileType.bind(this);
  }

  componentDidMount(){
    window.addEventListener('dragover', this.windowEventHandler, false);
    window.addEventListener('drop', this.windowEventHandler, false);
  }

  componentWillUnmount(){
    window.removeEventListener('dragover', this.windowEventHandler, false);
    window.removeEventListener('drop', this.windowEventHandler, false);
  }

  windowEventHandler(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  dragEnterHandler() {
    this.setState({ applyFileSelectorBorder: true });
  }

  dragAndDropHandler(droppedItems) {
    const { maxVideoUploadSizeBytes } = this.props.app;
    for (let item in droppedItems) {
      if (droppedItems[item].kind === 'file') {
        let file = (droppedItems[item].getAsFile) ? droppedItems[item].getAsFile() : droppedItems[item];
        const fileType = this.getFileExtension(file.name);
        if(this.props.acceptedFileTypes.includes(fileType) === false) {
          this.setState({ unsupportedFileType: true });
          this.setState({ compatibleFileSelected: false });
          this.props.video.removeVideoToUpload();
        } else if(file.size > maxVideoUploadSizeBytes) {
          this.setState({ maxVideoUploadSizeMbExceeded: true });
        } else {
          this.setState({ unsupportedFileType: false });
          this.props.video.addVideoToUpload(file);
          this.setState({ compatibleFileSelected: true });
        }
      } else if(droppedItems[item].kind !== 'file' && droppedItems[item].kind !== undefined) {
        this.setState({ unsupportedFileType: true });
      }
    }
  }

  dropHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragAndDropHandler(event.dataTransfer[event.dataTransfer.items ? 'items': 'files']);
    this.removeDragData(event);
    this.setState({ applyFileSelectorBorder: false });
  }

  dragOverHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ applyFileSelectorBorder: true });
  }

  dragLeaveHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ applyFileSelectorBorder: false });
  }

  removeDragData(event) {
    if (event.dataTransfer.items) event.dataTransfer.items.clear();
    else event.dataTransfer.clearData();
  }

  fileSelectedViaFileExplorer() {
    const { maxVideoUploadSizeBytes } = this.props.app;
    const input = document.querySelector('#fileSelectorInput');
    const selectedFile = input.files[0];
    if((selectedFile.size ) > maxVideoUploadSizeBytes) return this.setState({ maxVideoUploadSizeMbExceeded: true });
    this.props.video.addVideoToUpload(selectedFile);
    this.setState({ compatibleFileSelected: true });
  }

  getFileExtension(fileName) {
    const filenameParts = fileName.split('.');
    const fileExtension = filenameParts[filenameParts.length-1];
    return fileExtension;
  }

  appendPeriodToAcceptedFileType(){
    const acceptedFileExtensionsForInput = this.props.acceptedFileTypes.map(fileType => {
      const period = '.';
      return period.concat(fileType);
    });
    return acceptedFileExtensionsForInput;
  }

  render() {
    const { maxVideoUploadSizeBytes } = this.props.app.publisherVideoAssetsConfig;
    const acceptedFileExtensions = this.props.acceptedFileTypes.join(', ');
    const acceptedFileExtensionsForInput = this.appendPeriodToAcceptedFileType();
    const errorMessage = this.state.unsupportedFileType ? 'Filetype not supported, try again.' : `File size exceeds ${this.props.maxVideoUploadSizeMb}Mb`;
    if(this.state.compatibleFileSelected === false) {
      return (
        <div>
          <Header><Bold>Upload video</Bold> <em>(max filesize {formatBytes(maxVideoUploadSizeBytes)})</em></Header>
          <FileSelectorContainer
            applyFileSelectorBorder={this.state.applyFileSelectorBorder}
            onDragEnter={this.dragEnterHandler}
            onDragLeave={this.dragLeaveHandler}
            onDrop={this.dropHandler}
            onDragOver={this.dragOverHandler}
            onDragEnd={this.dragEndHandler}
            data-testid='fileSelectorContainer'
          >
            { (this.state.unsupportedFileType || this.state.maxVideoUploadSizeMbExceeded) &&
              <UnsupportedFileTypeMessage onDragEnter={this.dragEnterHandler}>
                <Icon
                  onDragEnter={this.dragEnterHandler}
                  name='fa-exclamation-triangle'
                  color={colors.white}
                  margin='0 5px 0 10px'
                />
                <White onDragEnter={this.dragEnterHandler} fontSize='11px'>{errorMessage}</White>
              </UnsupportedFileTypeMessage>
            }
            <InnerElementsContainer
              display='flex'
              flexDirection='column'
              alignItems='center'
              marginTop={50}
              marginBottom={31}
            >
              <Icon
                onDragEnter={this.dragEnterHandler}
                name='fa-file-upload'
                color={colors.darkPink}
                size={3}
                margin='0 0 20px 0'
              />
              <FileSelectorInput
                type='file'
                accept={acceptedFileExtensionsForInput.toString()}
                onChange={this.fileSelectedViaFileExplorer}
                id='fileSelectorInput'
                data-testid='fileSelectorInput'
              />
              <Red onDragEnter={this.dragEnterHandler} fontSize='14px' fontWeight={600} marginBottom='4px'>Drag file here or click to select.</Red>
              <SupportedFileTypes onDragEnter={this.dragEnterHandler}>Supported file types: {acceptedFileExtensions}</SupportedFileTypes>
            </InnerElementsContainer>
          </FileSelectorContainer>
        </div>
      );
    }
    return (
      <div>
        <Header><Bold>Upload video</Bold> <em>(max filesize {formatBytes(maxVideoUploadSizeBytes)})</em></Header>
        <CompatibleFileSelectedContainer
          data-testid='compatibleFileSelectedContainer'
          onDragEnter={this.dragEnterHandler}
          onDragLeave={this.dragLeaveHandler}
          onDrop={this.dropHandler}
          onDragOver={this.dragOverHandler}
          onDragEnd={this.dragEndHandler}
        >
          <InnerElementsContainer
            display='flex'
            flexDirection='column'
            alignItems='center'
            marginTop={50}
            marginBottom={31}
          >
            <Icon
            name='fa-check-circle'
            color={colors.darkGrassGreen}
            size={3}
            margin='0px 0 20px 0'
            />
            <FileSelectorInput
                type='file'
                accept={acceptedFileExtensionsForInput.toString()}
                onChange={this.fileSelectedViaFileExplorer}
                id='fileSelectorInput'
                data-testid='fileSelectorInput'
              />
            <DarkGrassGreen marginBottom='4px'>Compatible file selected.</DarkGrassGreen>
            <P fontSize='11px' color='#417505' fontWeight='300'>{this.props.video.videoToUpload.fileName}</P>
          </InnerElementsContainer>
        </CompatibleFileSelectedContainer>
      </div>
    );
  }
}

const Header = PComponent({
  marginBottom: '10px',
  fontSize: '14px',
  color: colors.darkGreyText
});

const FileSelectorContainer = LabelComponent({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  width: '411px',
  height: '180px',
  borderRadius: '10px',
  margin: '0px 0px 30px 10px',
  backgroundColor: colors.lightPink,
  cursor: 'pointer'
}, ({ applyFileSelectorBorder }) => ({ border: applyFileSelectorBorder ? `dotted 2px ${colors.red}` : `solid 2px ${colors.white}` }));

const CompatibleFileSelectedContainer = ExtendComponentStyles(FileSelectorContainer)({ backgroundColor: '#b8e986' });

const InnerElementsContainer = DivComponent({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '50px',
  marginBottom: '31px'
});

const FileSelectorInput = InputComponent({ display: 'none' });

const SupportedFileTypes = PComponent({
  fontSize: '11px',
  fontWeight: '300',
  color: colors.darkPink
});

const UnsupportedFileTypeMessage = DivComponent({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  height: '25px',
  width: '100%',
  backgroundColor: colors.darkPink,
  borderRadius: '10px 10px 0 0'
});
