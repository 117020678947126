import { observable, action } from 'mobx';
import API from '../../../../util/ApiUtil';

class FlowplayerStore {
  @observable flowplayerTokenIsValid = true;
  @observable flowplayerVideoEmbedIsValid = false;
  @observable flowplayerEmbedCode = '';
  @observable flowplayerToken = null;
  @observable flowplayerPlayerId = null;
  @observable flowplayerVideoId = null;
  @observable isLoading = false;
  @observable hasCodeBeenEntered = false;

  @action onTokenChange = async e => {
    const inputElement = e.currentTarget;
    this.flowplayerToken = inputElement.value;
    const response = await API.post('portal/plugin/flowplayer/set-player-token', { data: { token: this.flowplayerToken, pubId: API.publisherId() } });
    inputElement.value = '';
    if(response && response.status === 200) {
      this.hasCodeBeenEntered = false;
      this.flowplayerTokenIsValid = true;
      return;
    };
    this.hasCodeBeenEntered = true;
    this.flowplayerTokenIsValid = false;
  };

  @action onIframeEmbedAdd = async e => {
    const embedCode = e.target.value;
    const inputElement = e.currentTarget;
    this.isLoading = true;
    const response = await API.post('portal/plugin/flowplayer/embed-code', { data: { pubId: API.publisherId(), code: embedCode } });

    if(response && response.status === 200) {
      const { videoId, playerId } = response.data;
      this.flowplayerEmbedCode = embedCode;
      this.flowplayerVideoId = videoId;
      this.flowplayerPlayerId = playerId;
      this.flowplayerVideoEmbedIsValid = true;
      this.hasCodeBeenEntered = false;
      this.isLoading = false;
      return;
    };

    inputElement.value = '';
    this.flowplayerVideoEmbedIsValid = false;
    this.hasCodeBeenEntered = true;
    this.isLoading = false;
  };

  @action editToken = () => {
    this.flowplayerToken = null;
    this.flowplayerTokenIsValid = false;
  };

  @action clearEmbedText = () => {
    this.flowplayerEmbedCode = '';
    this.flowplayerVideoId = '';
    this.hasCodeBeenEntered = false;
  };

  @action resetEmbedValues = () => {
    this.flowplayerEmbedCode = '';
    this.flowplayerPlayerId = null;
    this.flowplayerVideoId = null;
    this.hasCodeBeenEntered = false;
  };
};

export default FlowplayerStore;
