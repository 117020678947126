import React from 'react';
import { inject, observer } from 'mobx-react';
import { Div, P } from '../../../../style/Global';
import config from '../../../../config/config';
import { RedUnderline, Bold, Italic } from '../../../../style/Typography';
import { BorderedInput } from '../../../../style/FormStyle';
import { colors, fontSizes } from '../../../../style/Constants';
import EditableComponent from '../../../../components/shared/EditableComponent';
import EmbedPropertiesContainer from '../../SharedComponents/EmbedPropertiesContainerCmp';
import { GreyBox, MessageCmp } from '../../../../style/StyledCmps';
import LoadingCmp from '../../LoadingCmp';
import IframeEmbedCode from '../../SharedComponents/IframeEmbedCmp';

@inject('mediaMappings', 'jwplayerStore')
@observer
export class JwplayerConfiguration extends EditableComponent {

  state = { page: 1 };

  render() {
    const { mediaMappings, jwplayerStore } = this.props;

    if (!jwplayerStore.jwplayerLibrarySdkIsValid) return <JwplayerPreActivation mediaMappings={mediaMappings} />
    if (!jwplayerStore.jwplayerVideoEmbedIsValid) return <JwplayerPreEmbedCodeActivation mediaMappings={mediaMappings} />

    return (
      <Div width='100%' display='flex' flexDirection='column' alignItem='center'>
        <P marginTop={5} marginBottom={14}>
          Your jwplayer integration is activated with the following cloud player. The same player is used for all your jwplayer videos on Tagflix.&nbsp;
          <RedUnderline cursor='pointer'>
            <a href={config.documentationUrl.jwplayer} target='_blank'>More information »</a>
          </RedUnderline>
        </P>
        <BorderedInput readOnly value={jwplayerStore.jwplayerLibrarySdk}
          backgroundColor={colors.lightGrey}
          marginTop={0}
          width='100%'
        />
        <Div textAlign='right'>
          <RedUnderline
            fontSize={fontSizes.small}
            onClick={() => {
              jwplayerStore.editToken();
            }}
          >
            Change cloud player library url for all videos
          </RedUnderline>
          <EmbedPropertiesContainer
            title='Jwplayer'
            embedPropertiesList={[{ name: 'Video Id', value: jwplayerStore.jwplayerVideoId }]}
            resetEmbedValues={jwplayerStore.resetEmbedValues}
          />
        </Div>
      </Div>
    );
  }
}

@inject('jwplayerStore', 'modal')
@observer
class JwplayerPreActivation extends EditableComponent {
  render() {
    const { mediaMappings, jwplayerStore, modal } = this.props;
    const currentModal = modal.findCurrentModal();
    return (
      <Div width='100%'>
        <P marginTop={5}><Bold>Activate Integration</Bold></P>
        <P marginTop={10}>To start using jwplayer, you need to obtain your cloud player library url and provide it below. <RedUnderline cursor='pointer'><a href={config.documentationUrl.jwplayer} target='_blank'>More information »</a></RedUnderline></P>
        <GreyBox width='100%' marginTop={10}>
          <P marginBottom={4}>Paste cloud player library url</P>
          <BorderedInput
            type='text'
            onChange={e => {
              currentModal._notice = null;
              jwplayerStore.hasCodeBeenEntered = true;
              this.addToInProgressEditsCount();
              jwplayerStore.onLibraryUrlChange(e);
              mediaMappings.setErrorMessage(null);
            }}
            marginTop={0}
            width='100%' />
        </GreyBox>
        {
          jwplayerStore.jwplayerLibrarySdk && !jwplayerStore.jwplayerLibrarySdkIsValid && jwplayerStore.hasCodeBeenEntered &&
          <MessageCmp type='error' width='100%'>
            <P><Italic><Bold>The url</Bold></Italic> entered appears to be invalid.</P>
          </MessageCmp>
        }
      </Div>
    );
  };
}

@inject('jwplayerStore', 'modal')
@observer
class JwplayerPreEmbedCodeActivation extends EditableComponent {
  render() {
    const { mediaMappings, jwplayerStore, modal } = this.props;
    const currentModal = modal.findCurrentModal();
    return (
      <Div width='100%'>
        <P marginTop={10}>Your jwplayer integration is activated with the following cloud player. The same player is used for all your jwplayer videos on Tagflix. <RedUnderline cursor='pointer'><a href={config.documentationUrl.jwplayer} target='_blank'>More information »</a></RedUnderline></P>
        <BorderedInput id='jwplayerLibrarySdkInput' readOnly value={jwplayerStore.jwplayerLibrarySdk}
          onChange={e => {
            currentModal._notice = null;
            jwplayerStore.hasCodeBeenEntered = true;
            this.addToInProgressEditsCount();
            jwplayerStore.onLibraryUrlChange(e);
            mediaMappings.setErrorMessage(null);
          }}
          backgroundColor={colors.lightGrey}
          margin='10px 0 0 0'
          width='100%'
        />
        <Div
          marginBottom={0}
          marginTop={2}
          textAlign='right'>
          <RedUnderline
            fontSize={fontSizes.small}
            onClick={() => {
              jwplayerStore.editToken();
            }}
          >Change cloud player library url for all videos</RedUnderline>
        </Div>
        <React.Fragment>
          {jwplayerStore.isLoading ?
            <Div marginTop={30}>
              <LoadingCmp title='Checking embed code...' message='Please wait.' />
            </Div>
            : !jwplayerStore.jwplayerVideoId || !jwplayerStore.jwplayerVideoEmbedIsValid ?
              <IframeEmbedCode
                title='jwplayer video'
                addEmbedCode={jwplayerStore.onIframeEmbedAdd}
                errorMessage='Embed appears to be invalid.'
                embedIsValidString={'jwplayerVideoEmbedIsValid'}
                currentStore={jwplayerStore}
                currentModal={currentModal}
                marginBottom={0}
              />
              :
              <EmbedPropertiesContainer
                title='Jwplayer'
                embedPropertiesList={[{ name: 'Video Id', value: jwplayerStore.jwplayerVideoId }]} resetEmbedValues={jwplayerStore.clearEmbedText}
              />
          }
        </React.Fragment>
        {
          mediaMappings.errorMessage &&
          <MessageCmp type='error' width='100%'>
            <P><Italic><Bold>The url</Bold></Italic> entered appears to be invalid.</P>
          </MessageCmp>
        }
      </Div>
    );
  };
}
