import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Div, DivComponent } from '../../../../../style/Global';
import { flexCenter } from '../../../../../style/Constants';
import { VIDEO_PLAYER_SIZE } from '../SyncMediaOffsetStyle';
import { Crosshair, TopLeftCorner, TopRightCorner, BottomLeftCorner, BottomRightCorner } from './CrosshairSvgs';
import LoadingCmp from '../../../LoadingCmp';
@inject('mediaOffset')
@observer
class CrosshairOverlayCmp extends Component {
  render() {
    const { productCoordinates, aspectRatio } = this.props.mediaOffset;
    const crosshairGrid = getCrosshairOverlayGrid(productCoordinates, aspectRatio);
    return <CrosshairOverlay crosshairGrid={crosshairGrid} />;
  };
};

export const LoadingInProgressOverlay = ({ isLoading = true, message, title }) => (
  <Div
    display='flex'
    justifyContent='center'
    flexDirection='column'
    alignItems='center'
    margin='auto'
    position='relative'
    zIndex='5'
    backgroundColor='black'
    height='100%'
    width='100%'
  >
    <LoadingCmp
      isLoading={isLoading}
      message={message}
      title={title}
      textColor='white'
      spinnerColor='white'
    />
  </Div>
);

const CrosshairOverlay = ({ crosshairGrid }) => (
  <CrosshairOverlayContainer grid={crosshairGrid}>
    <Div {...opaque}/>
    <Div {...opaque}/>
    <Div {...opaque}/>
    <Div {...opaque}/>
    <Div {...crosshairGrid.crosshair} {...flexCenter} position='relative' >
      <Crosshair/>
      <TopLeftCorner/>
      <TopRightCorner/>
      <BottomLeftCorner/>
      <BottomRightCorner/>
    </Div>
    <Div {...opaque}/>
    <Div {...opaque}/>
    <Div {...opaque}/>
    <Div {...opaque}/>
  </CrosshairOverlayContainer>
);

const DEFAULT_ASPECT_RATIO = 16/9;

const getCrosshairOverlayGrid = (point, aspectRatio) => {
  const videoPlayer = VIDEO_PLAYER_SIZE;
  const resizedVideoSize = aspectRatio < DEFAULT_ASPECT_RATIO ?
  {
    width: videoPlayer.height * aspectRatio,
    height: videoPlayer.height
  } :
  {
    width: videoPlayer.width,
    height: videoPlayer.width / aspectRatio
  };

  const crosshair = {
    width: Math.round(resizedVideoSize.width * point.w),
    height: Math.round(resizedVideoSize.height * point.h)
  };
  const column1 = Math.ceil((VIDEO_PLAYER_SIZE.width - crosshair.width + (resizedVideoSize.width * point.x)) / 2);
  const row1 = Math.ceil((VIDEO_PLAYER_SIZE.height - crosshair.height + (resizedVideoSize.height * point.y)) / 2);

  const getPositiveNumber = (number1, number2 = 0) => Math.max(number1, number2);
  const totalColumnWidth = getPositiveNumber(column1) + getPositiveNumber(crosshair.width) + getPositiveNumber(VIDEO_PLAYER_SIZE.width - crosshair.width - column1);
  const totalRowHeight = getPositiveNumber(row1) + getPositiveNumber(crosshair.height) + getPositiveNumber(VIDEO_PLAYER_SIZE.height - crosshair.height - row1);

  const sizeDifference = {
    width: Math.abs(videoPlayer.width - totalColumnWidth),
    height: Math.abs(videoPlayer.height - totalRowHeight)
  };

  const longestSide = getPositiveNumber(sizeDifference.width, sizeDifference.height);

  return {
    crosshair: {
      width: crosshair.width - longestSide,
      height: crosshair.height - longestSide
    },
    column1: getPositiveNumber(column1 - longestSide / 2),
    column2: getPositiveNumber(crosshair.width - sizeDifference.width),
    column3: getPositiveNumber(VIDEO_PLAYER_SIZE.width - crosshair.width - column1) + longestSide / 2,
    row1: getPositiveNumber(row1) + longestSide / 2,
    row2: getPositiveNumber(crosshair.height - longestSide),
    row3: getPositiveNumber(VIDEO_PLAYER_SIZE.height - crosshair.height - row1) + longestSide / 2
  };
};

const CrosshairOverlayContainer = DivComponent(({ grid }) => ({
  position: 'absolute',
  top: 0,
  display: 'grid',
  gridTemplateColumns: `${grid.column1}px ${grid.column2}px ${grid.column3}px`,
  gridTemplateRows: `${grid.row1}px ${grid.row2}px ${grid.row3}px`,
  zIndex: 100
}));

const opaque = {
  backgroundColor: 'black',
  opacity: 0.5
};

export default CrosshairOverlayCmp;
