import axios from 'axios';
import config from '../config/config';
import { getUserToken } from './PersistedStateUtil';
import { mockResponses } from '../mock/ApiMock';
const { apiUrl } = config;

class ApiClass {

  connectToApp(app) {
    this.app = app;
    mockResponses.connectToApp(app);
  }

  publisherId() {
    return this.app.publisher.id;
  }

  async request({ method = 'get', url, data = {}, includeAuthHeader = true }) {
    const options = { method, data, url: `${apiUrl}/${url}` };
    if (includeAuthHeader) {
      const userToken = getUserToken();
      if (!userToken) return this.app.logoutUser();
      options.headers = getAuthHeader(userToken);
    };
    try {
      this.app.requestStarted();
      const response = await axios(options);
      this.app.requestDone();
      return parseResponse(response);
    } catch(e) {
      this.app.requestDone();
      const errorResponse = e.response;
      const status = errorResponse.status;
      let error = errorResponse.statusText;
      if(errorResponse.data && errorResponse.data.status) error = errorResponse.data.status.error;
      if (status === 401 && !ignoreApiRequestStatusList.includes(url)) this.app.logoutUser();
      return { error, status, data: {} };
    };
  }

  get = async (url, options = {}) => await this.request({ method: 'get', url, ...options });
  put = async (url, options = {}) => await this.request({ method: 'put' , url, ...options });
  post = async (url, options = {}) => await this.request({ method: 'post', url, ...options });
  delete = async (url, options = {}) => await this.request({ method: 'delete', url, ...options });
};

const ignoreApiRequestStatusList = ['portal/plugin/powerbi/token'];

export const errorMessageHelper = errorMessage => {
  if(errorMessage[0] === errorMessage[0].toUpperCase()) return `${errorMessage}.`;
  return `${errorMessage[0].toUpperCase()}${errorMessage.slice(1)}.`;
};

const getAuthHeader = userToken => ({ Authorization: `Bearer ${userToken}` });

const parseResponse = ({ status, data }) => ({ ...data, status, data: data.data });

export const REQUESTED_RECORDS_LIMIT = 10;

const API = new ApiClass;
export default API;
