import React, { Component } from 'react';
import { Red } from '../../style/Typography';
import Icon from './FontAwesomeIconCmp';
import { userAcceptsCookiePolicy } from '../../util/PersistedStateUtil';
import { DivComponent } from '../../style/Global';
import { colors } from '../../style/Constants';

 const Rectangle = DivComponent({
  position: 'fixed',
  bottom: 25,
  left: 25,
  width: 230,
  backgroundColor: '#ffffff',
  boxShadow: '0 20px 40px 0 rgba(52, 48, 77, 0.2)',
  padding: 20,
  display: 'flex'
});

 export default class CookiePolicy extends Component {
  constructor() {
    super();
     this.closeModal = this.closeModal.bind(this);
  }

   closeModal() {
    userAcceptsCookiePolicy();
    this.props.hide();
  }

   render() {
    return (
      <Rectangle>
        <p>By continuing to use this website, you consent to the use of cookies in accordance with our <Red><a target='_blank' href='https://partner.tagflix.com/terms-and-privacy/cookie-policy/'>Cookie Policy</a></Red>.</p>
        <Icon name='fa-times' color={colors.grey} cursor='pointer' onClick={this.closeModal} lg/>
      </Rectangle>
    );
  }
}
