import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Div, H5, P } from '../../../style/Global';
import { limitStringLength } from '../../../util/TextUtil';
import { Bold, Italic, SmallText, RedUnderline } from '../../../style/Typography';
import { colors } from '../../../style/Constants';
import { WhiteButtonContainer } from '../../../style/FormStyle';
import { PinkBox, MessageCmp } from '../../../style/StyledCmps';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';
import Auth from '../../../components/shared/AuthCmp';

@inject('video', 'modal')
@observer
class DeleteMappingModal extends Component {
  constructor() {
    super();
    this.state = { errorMessage: '' };
    this.setErrorMessage = errorMessage => this.setState({ errorMessage });
    this.deleteVideo = this.deleteVideo.bind(this);
  }

  async deleteVideo() {
    const { videoListing, video, modal } = this.props;
    const responseStatus = await video.deleteVideo(videoListing.uploadVideoId);
    if (responseStatus === 204) return modal.close();
    this.setErrorMessage('We couldn\'t delete the video at this time. Please try again later.');
  }

  render() {
    const { videoListing, cancelVideoUpload, modal } = this.props;
    const { errorMessage } = this.state;
    const action = cancelVideoUpload ? 'canceled' : 'deleted';
    return (
      <Div>
        <P paddingBottom={20}>{ dialogText[action].title }</P>
        <H5 paddingBottom={10}>{ dialogText[action].directions }</H5>
        <GreyTextBox text={videoListing.videoName} style={{ backgroundColor: colors.lightGrey, margin: '0 0 20px 10px' }} />
        <PinkBox darker>
          <SmallText padding={5}><Bold><Italic>Warning:</Italic></Bold> { dialogText[action].warning } <Bold>This action cannot be undone.</Bold></SmallText>
        </PinkBox>

        { errorMessage && <MessageCmp type='error'><p>{errorMessage}</p></MessageCmp> }

        <Div>
          <WhiteButtonContainer>
            <RedUnderline marginRight={25} onClick={ () => modal.close() }>Cancel</RedUnderline>
            <WhiteButton icon='fa-trash-alt' text='Delete' onClick={this.deleteVideo} />
          </WhiteButtonContainer>
        </Div>
      </Div>
    );
  }
}

const dialogText = {
  canceled: {
    title: 'You are about to cancel an in progress video upload.',
    directions: 'Confirm cancel of in progress video upload',
    warning: 'cancelling an in progress upload will take some time to cleanup.'
  },
  deleted: {
    title: 'You are about to delete the following video.',
    directions: 'Confirm delete of video',
    warning: 'if you delete this video, any corresponding fallback or mappings will be deleted as well. Any videos which are still hosted on your site will also cease to function.'
  }
};

const GreyTextBox = ({ text, style = {} }) => (
  <Div {...style} border={`1px solid ${colors.grey}`} height={26}><P padding='2px 0 0 10px'>{limitStringLength(text, 56)}</P></Div>
);

export default Auth(DeleteMappingModal);
