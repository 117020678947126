import React from 'react';
import { Link } from 'react-router-dom';
import { RedUnderline } from '../../style/Typography';

export const getRoleDescription = role => {
  if (!role) return '';
  if (role === 'ownerWithLink') return roleDescriptions.ownerWithLink.map((content, i) => <span key={i}>{content}</span>);
  return roleDescriptions[role];
};

export const roleDescriptions = {
  owner: 'There can only be one account owner. This user cannot be removed and has equivalent access rights to admins. Admins can add and modify media identities as well as have api access.',
  ownerWithLink: [ 'There can only be one account owner. This user cannot be removed and has equivalent access rights to admins. Please ', <Link to='/support'><RedUnderline>contact support</RedUnderline></Link>, ' should you need to change them.' ],
  admin: 'Admins can add and modify media identities as well as have api access.',
  'read-only': 'These users can only view media identity mappings and do not have ability to add or modify any data. No api access.'
};
