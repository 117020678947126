import { colors } from '../../../style/Constants';
import { ExtendComponentStyles, SpanComponent } from '../../../style/Global';

export const PageNumber = SpanComponent({
  boxSizing: 'border-box',
  padding: '0 7.5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '35px',
  color: colors.red,
  fontSize: '11px',
  cursor: 'pointer'
});

export const PageNumberHighlighted = ExtendComponentStyles(PageNumber)({
  color: colors.darkGreyText,
  backgroundColor: '#d8d8d8',
  cursor: 'default'
});
