import { observer, inject } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { H4 } from '../../style/Global';
import { SmallText, TinyText, LightGrey } from '../../style/Typography';
import { Spacer } from '../../style/StyledCmps';
import {
  SidebarDiv,
  UserInfo,
  NavList,
  NavLink,
  SelectedLink

} from './SidebarStyle';
import Icon from '../shared/FontAwesomeIconCmp';
import difference from 'lodash.difference';
import SelectPublisherDropdown from './SelectPublisherDropdownCmp';
import config from '../../config/config';
import Capabilities, { defaultCapabilities } from '../../config/Capabilities';
import { colors } from '../../style/Constants';
import Reports from '../../config/Reports';

@inject('app')
@observer
class Sidebar extends Component {

  onCapabilityClick = route => {
    this.props.app.setRedirectPathname(route);
  };

  render() {
    const capabilityNavItems = this.props.app.publisherCapabilities.map(capability => {
      const currentCapability = defaultCapabilities[capability.name] || Capabilities[capability.name];
      if (!currentCapability) return;
      if (difference([ 'text', 'icon', 'route' ], Object.keys(currentCapability)).length > 0) return;
      return (
        <NavItem
          text={currentCapability.text}
          icon={currentCapability.icon}
          route={currentCapability.route}
          currentRoute={`/${this.props.currentRoute.split('/')[1]}`}
          key={capability.name}
          onCapabilityClick={this.onCapabilityClick}
        />
      );
    });

    const { dashboards } = this.props.app.publisher;
    const dashboardNavItems = () => dashboards.map(dashboard => {
      const report = Reports[dashboard.name];
      return (
        <NavItem
          text={report.title}
          icon='fa-chart-bar'
          route={report.route}
          currentRoute={this.props.currentRoute}
          key={dashboard.name}
          onCapabilityClick={this.onCapabilityClick}
        />
      );
    });

    return (
      <SidebarDiv>
        <UserInfo>
          <SelectPublisherDropdown />
          <SmallText>{this.props.app.user.name}</SmallText>
          <TinyText><LightGrey>{this.props.app.user.email}</LightGrey></TinyText>
        </UserInfo>
        <nav>
          <NavList>
            <H4 paddingBottom={15}>Media Management</H4>
            {capabilityNavItems}
          </NavList>
          {
            dashboards && dashboards.length !== 0 &&
            <NavList>
              <H4 paddingBottom={15}>Dashboard</H4>
              {dashboardNavItems()}
            </NavList>
          }
          <NavList>
            <H4 paddingBottom={15}>Company</H4>
            <NavItem
              text='Settings'
              icon='fa-cog'
              route='/settings'
              currentRoute={`/${this.props.currentRoute.split('/')[1]}`}
              onCapabilityClick={this.onCapabilityClick}
            />
            <NavItem
              text='Support'
              icon='far fa-question-circle'
              route='/support'
              currentRoute={this.props.currentRoute}
              onCapabilityClick={this.onCapabilityClick}
            />
            <NavLink onClick={() => {
              this.props.app.logoutUser();
            }}>
              <Icon name='fa-power-off' margin='0 8px 0 6px' lg />
              <p>Sign Out</p>
            </NavLink>
          </NavList>
        </nav>
        <Spacer height={35} />
        <TinyText><LightGrey>{config.environment}-{config.appVersion}</LightGrey></TinyText>
      </SidebarDiv>
    );
  }
}

const NavItem = ({ text, icon, route, currentRoute, onCapabilityClick }) => {
  let item;
  const settingsRoutes = ['/settings', '/team', '/keys'];
  if (text === 'Settings' && settingsRoutes.includes(currentRoute)) item = (
    <SelectedLink onClick={() => onCapabilityClick(route)}>
      <Icon name={icon} color={colors.white} margin='0 8px 0 6px' lg />
      <p>{text}</p>
    </SelectedLink>
  );
  else if (currentRoute === route) item = (
    <SelectedLink onClick={() => onCapabilityClick(route)}>
      <Icon name={icon} color={colors.white} margin='0 8px 0 6px' lg />
      <p>{text}</p>
    </SelectedLink>
  );
  else item = (
    <NavLink onClick={() => onCapabilityClick(route)}>
      <Icon name={icon} margin='0 8px 0 6px' lg />
      <p>{text}</p>
    </NavLink>
  );
  return <Link to={route}>{item}</Link>;
};

Sidebar.propTypes = { currentRoute: PropTypes.string.isRequired };

export default Sidebar;
