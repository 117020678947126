import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { P, Div } from '../../../style/Global';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';
import { WhiteButtonContainer } from '../../../style/FormStyle';

@inject('modal')
@observer
class NoFallbackMapping extends Component {
  render() {
    const { modal } = this.props;
    return (
      <Div>
        <P lineHeight={1.6}>No fallback mapping set for facebook player. Popular mobile devices will not display Tagflix overlay.</P>
        <WhiteButtonContainer>
          <WhiteButton icon='fa-check' text='Ok' closeModal={modal.close} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

export default NoFallbackMapping;
