import { PComponent, InputComponent } from '../../style/Global';

export const VideoUrlTextStyle = {
  fontFamily: '"Courier New", Courier, monospace',
  fontSize: 14
};

export const VideoUrlText = PComponent(VideoUrlTextStyle);

export const EmbedProperties = PComponent(({ paddingLeft }) => ({
  fontFamily: '"Courier New", Courier, monospace',
  fontSize: 12,
  paddingLeft
}));

export const VideoUrlInput = InputComponent(({ letterCount }) => ({
  ...VideoUrlTextStyle,
  width: 4 + Math.round(letterCount * 8.5),
  paddingLeft: 2,
  fontWeight: 'bold'
}));
