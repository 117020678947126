import React from 'react';
import { observer, inject } from 'mobx-react';
import { Div, H5 } from '../../../style/Global';
import AddLabel from './AddLabelCmp';
import FileSelector from './FileSelectorCmp';
import { BorderedInput, WhiteButtonContainer } from '../../../style/FormStyle';
import { MessageCmp } from '../../../style/StyledCmps';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';
import Auth from '../../../components/shared/AuthCmp';
import StorageUtil from '../../../util/TagflixStorageUtil/StorageUtil';
import config from '../../../config/config';
import { getSelectedPublisherId, getUserToken } from '../../../util/PersistedStateUtil';
import EditableComponent from '../../../components/shared/EditableComponent';

@inject('video', 'modal', 'app')
@observer
class AddVideoModal extends EditableComponent {

  state = { errorMessage: '' };

  componentDidMount = () => {
    if(this.props.videoListing) this.props.video.setVideoName(this.props.videoListing.videoName);
  }

  componentWillUnmount = () => {
    this.props.video.clearVideoUploadData();
  }

  setErrorMessage = errorMessage => this.setState({ errorMessage });

  setVideoName = e => {
    this.addToInProgressEditsCount()
    const { setVideoName } = this.props.video;
    const videoName = e.target.value;
    setVideoName(videoName);
  }

  submitLabel = () => {
    const labelInput = document.getElementById('label-input');
    if(labelInput && labelInput.value) {
      labelInput.focus();
      this.simulateEnterKeyPressOnAddLabelCmp();
    }
  }

  async saveVideoAsset() {
    const { videoName, labels, videoToUpload, usedStorageBytes, populateVideoList, populatePageWithUploadedVideo } = this.props.video;
    const { videoStorageCapacityBytes } = this.props.app.publisherVideoAssetsConfig;
    const { editVideoProperties } = this.props;
    const metadata = {
      videoName,
      labels
    };
    if(!videoName) return this.setErrorMessage('All fields required, please try again.');
    if(!videoToUpload.name && !editVideoProperties) return this.setErrorMessage('Cannot upload non-selected file. Please select a file before clicking upload.');
    if((videoToUpload.size + usedStorageBytes) > videoStorageCapacityBytes) {
      return this.setErrorMessage('File exceeds maximum storage capacity. Please contact your account manager.');
    }
    if(!editVideoProperties) {
      metadata.videoToUpload = videoToUpload;
      this.submitLabel();
      await this.uploadFile(metadata);
      populatePageWithUploadedVideo(metadata);
    } else {
      metadata.videoUploadId = this.props.videoListing.uploadVideoId;
      this.submitLabel();
      metadata.labels = this.props.video.labels;
      await this.updateVideo(metadata);
      await populateVideoList();
    }

    this.props.modal.close();
    this.props.video.clearVideoUploadData();
  }

  uploadFile = async metadata => {
    this.addToInProgressEditsCount();
    const storageUtil = new StorageUtil({
      pubId: getSelectedPublisherId(),
      authToken: getUserToken()
    });

    storageUtil.on('error', error => {
      console.log('ERROR', error);
      })
      .on('start', startEvent => {
        metadata.uploadVideoId = startEvent.videoId;
        this.props.video.addIdToRecentlySavedVideoIds(startEvent.videoId);
      })
      .on('state', state => {
        this.props.video.updateVideoState(state, metadata.uploadVideoId);
      })
      .on('progress', progress => {
        this.props.video.updateUploadProgress(progress.progress, metadata.uploadVideoId);
      })
      .on('success', success => {
        console.log('SUCCESS', success);
      });

    const file = this.props.video.videoToUpload;
    await storageUtil.uploadFile(file, { ...metadata, allowedFileTypes: [ 'mp4' ] });
  }

  updateVideo = async metadata => {
    const { videoListing } = this.props;
    await this.props.video.updateVideo({ ...metadata, videoId: videoListing.uploadVideoId });
  }

  render() {
    const { editVideoProperties, videoListing, reuploadVideo } = this.props;
    const whiteButtonText = editVideoProperties? 'Save' : 'Upload';
    if(reuploadVideo) return (
      <Div>
        <FileSelector acceptedFileTypes={config.acceptedFileTypesForVideoUpload} />
        <WhiteButtonContainer>
          <WhiteButton icon='fa-check' text={whiteButtonText} onClick={this.saveVideoAsset} />
        </WhiteButtonContainer>
      </Div>
    );
    return (
      <Div>
        <H5 paddingBottom={10}>{!editVideoProperties ? 'Enter' : 'Change'} video name</H5>
        <Div paddingLeft={10} marginBottom={30}>
          <BorderedInput
            id='video-name-input'
            type='text'
            onChange={ e => this.setVideoName(e) }
            defaultValue={editVideoProperties ? videoListing.videoName : ''}
          />
        </Div>
        {!editVideoProperties && <FileSelector acceptedFileTypes={config.acceptedFileTypesForVideoUpload}/>}
        <AddLabel editVideoProperties={editVideoProperties} videoListing={videoListing} simulateEnterPress={childComponentMethod => this.simulateEnterKeyPressOnAddLabelCmp = childComponentMethod} />
        { this.state.errorMessage && <MessageCmp type='error'><p>{this.state.errorMessage}</p></MessageCmp> }
        <WhiteButtonContainer>
          <WhiteButton icon='fa-check' text={whiteButtonText} onClick={this.saveVideoAsset} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

export default Auth(AddVideoModal);
