const reports = {
  'powerbi-standard': {
    title: 'Standard Report',
    description: 'Drill down into your data with this dashboard to unearth deep consumer insights.',
    route: '/report/standard'
  },
  'powerbi-bespoke': {
    title: 'Bespoke Report',
    description: 'Drill down into your data with this dashboard to unearth deep consumer insights.',
    route: '/report/bespoke'
  }
};

export default reports;
