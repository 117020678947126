export const checkMediaResponseAndReturnIfError = mediaResponse => {
  const media = mediaResponse.data;
  if(!media) return 'no `media`';
  if(Object.is(NaN, parseInt(media.offsetMs, 10))) return 'no valid `offsetMs` for `media`';
  const error = checkObjectPropertiesExistAndReturnErrorIfNot(media, [ 'videoPlayer', 'videoProps' ], 'media');
  if(error) return error;
  return false;
};

export const checkOffsetResponseAndReturnIfError = offsetResponse => {
  if(offsetResponse.error) return `${offsetResponse.error} (api)`;
  const offset = offsetResponse.data;
  const error = checkObjectPropertiesExistAndReturnErrorIfNot(offset, [
    'fps', 'frame', 'point', 'product', 'sourceHeight', 'sourceWidth', 'totalFrames'
  ], 'offset');
  if(error) return error;
  return false;
};

const checkObjectPropertiesExistAndReturnErrorIfNot = (object, propertiesList, objectType = 'object') => {
  for(let i=0; i < propertiesList.length; i++){
    const property = propertiesList[i];
    if(!object[property]) return `no \`${property}\` for \`${objectType}\``;
  }
};

export const formatErrorAsReferenceNumber = (errorMessage, publisherVideoId, tagflixMediaId) => {
  const errorReport = { videoId: publisherVideoId, mediaId: tagflixMediaId, error: errorMessage };
  try {
    return btoa(JSON.stringify(errorReport));
  } catch(e) {
    return 'No reference available.';
  }
};

export const getDefaultScrubberValues = (frame, fps, totalFrames) => {
  // frame is the middle value with +- 15 seconds on each side;
  const numberOfFramesInFifteenSeconds = Math.round(fps * 15);
  const minValue = Math.max(frame - numberOfFramesInFifteenSeconds, 0);
  const maxValue = Math.min(frame + numberOfFramesInFifteenSeconds, totalFrames);
  return [ minValue, frame, maxValue ];
};

export const frameNumberToTimestamp = (frameNumber, fps) => {
  const totalSeconds = Math.floor(frameNumber / fps);
  let seconds = totalSeconds%60;
  let minutes = Math.floor((totalSeconds%(60 * 60)) / 60);
  let hours = Math.floor(totalSeconds / (60 * 60));
  const stringifiedValues = [ hours, minutes, seconds ].map(value => {
    if (value < 10) return `0${value}`;
    return value;
  });
  return stringifiedValues.join(':');
};

export const getTicks = (handleValues, fps) => {
  const MAX_NUMBER_OF_FRAME_TICKS = 60;
  const numberOfFrames = handleValues[2] - handleValues[0];
  const mode = numberOfFrames > MAX_NUMBER_OF_FRAME_TICKS ? 'seconds' : 'frames';
  let numberOfTicks = numberOfFrames;
  let show = numberOfTicks <= MAX_NUMBER_OF_FRAME_TICKS;
  if (mode === 'seconds') {
    numberOfTicks = numberOfFrames / fps;
    show = numberOfTicks <= 20;
  }
  return { mode, show, numberOfTicks };
};
