import { colors } from '../../style/Constants';
import { DivComponent, NavComponent, UlComponent, LiComponent, ExtendComponentStyles } from '../../style/Global';

export const SettingsDiv = DivComponent({ display: 'flex' });

export const SettingsSidebar = NavComponent({
  alignSelf: 'stretch',
  width: 210,
  minWidth: 210,
  backgroundColor: colors.superLightGrey,
  borderRight: `1px solid ${colors.lightGreyBorder}`
});

export const NavList = UlComponent({
  display: 'flex',
  flexDirection: 'column'
});

export const NavItem = LiComponent({
  display: 'flex',
  alignItems: 'center',
  height: 45,
  paddingLeft: 12,
  borderBottom: `1px solid ${colors.lightGreyBorder}`,
  cursor: 'pointer'
});

export const SelectedNavItem = ExtendComponentStyles(NavItem)({
  color: colors.white,
  backgroundColor: colors.darkPink,
  cursor: 'default'
});

export const SubpageDiv = DivComponent({
  padding: '25px 30px',
  width: 'calc(100% - 200px)',
  minWidth: '600px'
});

export const MaxWidthDiv = DivComponent({ maxWidth: 420 });

export const GreyLine = DivComponent({
  width: '100%',
  height: 2,
  margin: '25px 0',
  backgroundColor: colors.lightGrey
});

export const ToggleDiv = DivComponent({
    display: 'flex',
    alignItems: 'center',
    marginTop: 20
});
