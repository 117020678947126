import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Div, Span, Svg } from '../../style/Global';
import Auth from '../../components/shared/AuthCmp';
import { colors, fontSizes, fontWeight } from '../../style/Constants';
import { WhiteButton } from '../../style/FormStyle';
import * as pbi from 'powerbi-client';

@inject('app', 'reportStore')
@observer
class ReportPage extends Component {

  constructor() {
    super()
    this.ref = React.createRef();
    this.state = { dashboardReport: undefined, tokenInvalid: false, errorMessage: '' }
  }

  componentDidMount() {
    const dashboards = this.props.app.publisher.dashboards;
    if (!dashboards) return;
    this.props.app.onReportPage = true;
    this.interval = setInterval(() => {
      this.findReportAndInitialize();
    }, 1000);
  };

  componentWillUnmount() {
    this.props.app.onReportPage = false;
    if(this.interval) clearInterval(this.interval);
  };

  findReportAndInitialize = () => {
    const { type } = this.props.match.params;
    const { dashboards } = this.props.app.publisher;

    const powerbiType = type === 'standard' ? 'powerbi-standard' : 'powerbi-bespoke';
    const report = dashboards.find(report => report.name === powerbiType);

    if (report && report.token) this.setState({ dashboardReport: report }, async () => await this.initialize());
  }

  initialize = async () => {
    if (this.interval) clearInterval(this.interval);
    const preExistingElement = document.getElementById('powerbi-report');
    if (preExistingElement) preExistingElement.remove();

    const reportElement = document.createElement('div');
    const parent = document.getElementById('powerbi-parent');

    reportElement.setAttribute('id', 'powerbi-report');
    reportElement.setAttribute('style', 'width: 100%; height: 100%;');

    parent.appendChild(reportElement);

    const configurationOptions = {
      type: 'report',
      tokenType: pbi.models.TokenType.Embed,
      accessToken: this.state.dashboardReport.token,
      embedUrl: 'https://app.powerbi.com/reportEmbed',
      id: this.state.dashboardReport.config.reportId, // powerbi reportId
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: true,
        visualSettings: {
          visualHeaders: [
            { settings: { visible: false } }
          ]
        }
      },
      filters: [{
        $schema: 'http://powerbi.com/product/schema#basic',
        target: { table: 'production', column: 'PublisherID' },
        operator: 'In',
        values: [this.props.app.publisher.id],
        filterType: 1
      }]
    }

    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    const report = powerbi.embed(reportElement, configurationOptions);
    this.report = report;

    this.report.on('error', async err => {
      if (err && err.detail.message === 'TokenExpired') {
        await this.props.reportStore.getPowerBiToken(this.props.app.publisher, true);
        this.findReportAndInitialize();
        return;
      }
      this.setState({ tokenInvalid: true, errorMessage: 'Something went wrong, please refresh the page. If this problem persists, contact your account manager.' });
    });
  }

  handleReportFullscreen = () => this.report && this.report.fullscreen();

  render() {
    const powerbiType = this.props.match.params.type === 'standard' ? 'powerbi-standard' : 'powerbi-bespoke';
    return (
      <Div>
        { !this.props.reportStore.reportRequestStatus[powerbiType].error ? (
          <Div>
            <Div width='100%' display='flex' justifyContent='flex-end'>
              <WhiteButton onClick={this.handleReportFullscreen} margin='25px 30px 0 0'>
                <Svg
                width={16}
                height={16}
                viewBox='0 0 1792 1792'
                xmlns='http://www.w3.org/2000/svg'
              >
                  <path d='M883 1056q0 13-10 23l-332 332 144 144q19 19 19 45t-19 45-45 19h-448q-26 0-45-19t-19-45v-448q0-26 19-45t45-19 45 19l144 144 332-332q10-10 23-10t23 10l114 114q10 10 10 23zm781-864v448q0 26-19 45t-45 19-45-19l-144-144-332 332q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l332-332-144-144q-19-19-19-45t19-45 45-19h448q26 0 45 19t19 45z' fill='red' />
                </Svg>
                <Span color={colors.darkGreyText} margin='0 0 0 8px' fontSize={fontSizes.p} fontWeight={fontWeight.semiBold}>Full Screen</Span>
              </WhiteButton>
            </Div>
            <Div backgroundColor={colors.lightGrey} width='95%' minWidth='698px' height='800px' margin='15px 30px 33px 30px'>
              {!this.state.tokenInvalid
              ?
                <Div height='100%' id='powerbi-parent' />
              :
                <Div>{this.state.errorMessage}</Div>
            }
            </Div>
          </Div>
        ) : (
          <Div padding='32px'>{this.props.reportStore.reportRequestStatus[this.props.type].errorMessage}</Div>
      )}
      </Div>
    )
  };
};

export default Auth(ReportPage);
