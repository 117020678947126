import { observable, action } from 'mobx';
import API from '../../../util/ApiUtil';

const emptyMessage = { isError: false, text: '' };
const emptyMessages = { invite: emptyMessage, manage: emptyMessage };

class SettingsStore {
  @observable team = [];
  @action getTeam = async () => {
    const response = await API.getTeam();
    if (response.status === 200) {
      const team = response.data;
      this.team = team.map(mate => ({ ...mate, name: `${mate.firstname} ${mate.surname}` }));
      return;
    }
    this.setMessage({
      section: 'manage',
      isError: true,
      text: 'We couldn\'t retrieve your team. Please try again later.'
    });
  };

  @action inviteTeammate = async data => {
    const response = await API.post('portal/teammate/invite', { data: { ...data, pubId: API.publisherId() } });
    if (response.status === 204) {
      return this.setMessage({
        section: 'invite',
        text: 'An invite email has been sent to your teammate.'
      });
    }
    this.setMessage({
      section: 'invite',
      isError: true,
      text: 'We couldn\'t send the invite at this stage. Please try again later.'
    });
  };

  @action changeTeammateRole = async (role, userId) => {
    const response = await API.put(`portal/teammate/role/${userId}`, { data: { role, pubId: API.publisherId() } });
    if (response.status === 200) {
      this.team = this.team.map(mate => {
        if (mate.id === userId) return { ...mate, role };
        return mate;
      });
    }
  };

  @action removeTeammate = async userId => {
    const response = await API.delete('portal/teammate', { data: { userId, pubId: API.publisherId() } });
    if (response.status === 204) return this.team = this.team.filter(mate => mate.id !== userId);
    this.setMessage({
      section: 'manage',
      isError: true,
      text: 'The operation failed. Please try again later.'
    });
  };

  @observable messages = emptyMessages;
  @action setMessage = ({ section, isError = false, text }) => {
    // "section" is either "invite" or "manage"
    const messages = { ...this.messages };
    messages[section] = { isError, text };
    this.messages = messages;
  };

  @action clearMessage = section => {
    if (section === 'all') this.messages = emptyMessages;
    else this.messages[section] = emptyMessage;
  };
}

export default SettingsStore;
