import React from 'react';
import { observer, inject } from 'mobx-react';
import Select from 'react-select';
import Toggle from 'react-toggle';
import { Div, P } from '../../../style/Global';
import config from '../../../config/config';
import { Bold, Italic, RedUnderline, SmallText } from '../../../style/Typography';
import { BorderedText } from '../../../style/FormStyle';
import { PinkBox, Spacer, MessageCmp } from '../../../style/StyledCmps';
import InfoBubble from '../../../components/shared/InfoBubbleCmp';
import GreyBoxWithArrow from './GreyBoxWithArrowCmp';
import Auth from '../../../components/shared/AuthCmp';
import API from '../../../util/ApiUtil';
import { getSupportedPlayerList, getPlayerStore } from '../MediaMappingHelpers';
import EditableComponent from '../../../components/shared/EditableComponent';
import { PlayerConfiguration, SearchMedia, getYouTubeVideoIdFromVideoUrl, getFacebookIdsFromVideoUrl, onMediaNameChange } from './MappingModalHelpers';

@inject('mediaMappings', 'modal', 'flowplayerStore', 'brightcoveStore', 'jwplayerStore')
@observer
class EditMappingModal extends EditableComponent {
  constructor(props) {
    super();
    this.state = { isOverlayEnabled: props.mapping.isOverlayEnabled, mediaQueryList: [] };
    props.individualModal.disableGlobalButtons = !props.mapping.isOverlayEnabled;
    props.individualModal.changePlayerOrMapping = true;
    props.individualModal.internalState = {
      mediaName: props.mapping.mediaName,
      videoPlayer: props.mapping.videoPlayer,
      videoId: props.mapping.videoId,
      tagflixMediaId: props.mapping.tagflixMediaId,
      id: props.mapping.id
    };
    this.setErrorMessage = (errorMessage, internalMessage) => {
      const { mediaMappings } = this.props;
      mediaMappings.setErrorMessage(errorMessage);
      if (internalMessage) console.error(internalMessage);
    };
    this.setVideoPlayer = videoPlayer => {
      const { individualModal } = this.props;
      individualModal.hasBeenEdited = true;
      individualModal.internalState.videoPlayer = videoPlayer;
    };
    this.setOverlayEnabled = isOverlayEnabled => {
      const { individualModal } = this.props;
      individualModal.disableGlobalButtons = !isOverlayEnabled;
      this.setEditableState({ isOverlayEnabled });
    };
    this.onOverlayEnabledClick = this.onOverlayEnabledClick.bind(this);
    this.disableMapping = this.disableMapping.bind(this);
    this.enableMapping = this.enableMapping.bind(this);
  }

  async componentDidMount() {
    const { mapping, flowplayerStore, brightcoveStore, jwplayerStore, individualModal } = this.props;

    this.setErrorMessage(null);


    individualModal.internalState = { ...individualModal.internalState, ...mapping };
    if (mapping.videoPlayer === 'facebook' && mapping.fallbackVideoId) {
      const fallbackMapping = { ...individualModal.internalState.fallbackMapping, enabled: true, videoId: mapping.fallbackVideoId };
      individualModal.internalState.fallbackMapping = fallbackMapping;
    }
    if (mapping.videoPlayer === 'flowplayer') flowplayerStore.isLoading = true;
    if (mapping.videoPlayer === 'brightcove') brightcoveStore.isLoading = true;
    if (mapping.videoPlayer === 'jwplayer') jwplayerStore.isLoading = true;

    const response = await API.post('portal/media', { data: { publisherVideoId: mapping.videoId, pubId: API.publisherId() } });
    const media = response.data;

    if (media.videoPlayer === 'youtube') {
      const youtubeVideoId = getYouTubeVideoIdFromVideoUrl(media.videoProps.url);
      individualModal.internalState.youtubeVideoId = youtubeVideoId;
    }
    if (media.videoPlayer === 'facebook') {
      const { facebookUserId, facebookVideoId } = getFacebookIdsFromVideoUrl(media.videoProps.url);
      individualModal.internalState.facebookUserId = facebookUserId;
      individualModal.internalState.facebookVideoId = facebookVideoId;
    }
    if (media.videoPlayer === 'flowplayer') {
      flowplayerStore.isLoading = false;
      flowplayerStore.flowplayerPlayerId = media.videoProps.playerId;
      flowplayerStore.flowplayerVideoId = media.videoProps.videoId;
      flowplayerStore.flowplayerVideoEmbedIsValid = true;
    }
    if (media.videoPlayer === 'brightcove') {
      brightcoveStore.isLoading = false;
      brightcoveStore.brightcoveVideoEmbedId = media.videoProps.videoId;
      brightcoveStore.brightcoveVideoEmbedIsValid = true;
    }

    if (media.videoPlayer === 'jwplayer') {
      jwplayerStore.isLoading = false;
      jwplayerStore.jwplayerVideoId = media.videoProps.videoId;
      jwplayerStore.jwplayerLibrarySdk = media.videoProps.librarySdk;
      jwplayerStore.jwplayerVideoEmbedIsValid = true;
    }
  }

  componentDidUpdate() {
    const { saveMapping, closeModal, individualModal } = this.props;
    const { hasBeenEdited } = individualModal

    if (hasBeenEdited && individualModal.globalButtons.length === 2) return;
    if (hasBeenEdited) individualModal.globalButtons = [
      { text: 'Cancel', func: closeModal },
      { text: 'Save', icon: 'fa-check', func: saveMapping }
    ];
  }

  componentWillUnmount() {
    const { brightcoveStore, flowplayerStore, jwplayerStore, mediaMappings } = this.props;
    brightcoveStore.clearVideoEmbedCodeAndResetPlayerConfig();
    flowplayerStore.resetEmbedValues();
    jwplayerStore.resetEmbedValues();
    mediaMappings.setErrorMessage();
  }

  onOverlayEnabledClick() {
    if (this.state.isOverlayEnabled) return this.disableMapping();
    this.enableMapping();
  }

  async disableMapping() {
    const { mapping, mediaMappings } = this.props;
    const responseStatus = await mediaMappings.disableOverlay(mapping.videoId);
    if (responseStatus === 200) return this.setOverlayEnabled(false);
    this.setErrorMessage('We couldn\'t disable the mapping at this time. Please try again later.');
  }

  async enableMapping() {
    const { mapping, mediaMappings } = this.props;
    const responseStatus = await mediaMappings.enableOverlay(mapping.videoId);
    if (responseStatus === 200) return this.setOverlayEnabled(true);
    this.setErrorMessage('We couldn\'t enable the mapping at this time. Please try again later.');
  }

  render() {
    const {
      mediaName,
      videoPlayer,
      videoId
    } = this.props.individualModal.internalState;
    const { isOverlayEnabled, mediaQueryList } = this.state;
    const { errorMessage } = this.props.mediaMappings;

    let dropdownOptions = [];
    getSupportedPlayerList().forEach(player => dropdownOptions.push({ value: player.toLowerCase(), label: player }));

    const { mediaMappings } = this.props;
    return (
      <Div>
        <P>To deliver the correct overlay data to your videos, Tagflix needs to know which videos you want to enable. <RedUnderline cursor='pointer'><a href={config.documentationUrl.flowplayer} target='_blank'>More information »</a></RedUnderline></P>
        <Spacer height={15} />
        <Div display='flex' alignItems='center' paddingTop={10} paddingBottom={30}>
          <Toggle checked={isOverlayEnabled} onChange={this.onOverlayEnabledClick} icons={false} />
          <p>{isOverlayEnabled ? <Bold>Tagflix Overlay Enabled</Bold> : 'Tagflix Overlay Disabled'}</p>
          <InfoBubble height={50}>It may take up to 20 minutes for this change to occur.</InfoBubble>
        </Div>
        {
          isOverlayEnabled
            ?
              <Div>
                <P paddingBottom={10}><Bold>Your video id </Bold><Italic>(this is any id your system generates)</Italic></P>
                <GreyBoxWithArrow>
                  <BorderedText>{videoId}</BorderedText>
                </GreyBoxWithArrow>
                <SearchMedia
                value={mediaName}
                onChange={e => onMediaNameChange(e, this)}
                mediaQueryList={mediaQueryList}
                parentContext={this}
                mediaPropertyToDisplay='name'
                includeMapToMediaText
              />
                <P paddingTop={30} paddingBottom={10}><Bold>Where is this video uploaded to?</Bold></P>
                <GreyBoxWithArrow>
                  <Select
                  value={videoPlayer}
                  onChange={e => {
                    this.setVideoPlayer(e.value);
                    mediaMappings.setErrorMessage(null);
                    if (videoPlayer === 'brightcove' || videoPlayer === 'flowplayer') getPlayerStore(videoPlayer).hasCodeBeenEntered = false;
                  }}
                  searchable={false} clearable={false}
                  options={dropdownOptions}
                />
                </GreyBoxWithArrow>

                <Div paddingLeft='10px'>
                  <PlayerConfiguration
                  {...this.props.individualModal.internalState}
                  individualModal={this.props.individualModal}
                  setErrorMessage={this.setErrorMessage}
                />
                </Div>
              </Div>
            :
              <PinkBox darker>
                <SmallText padding={5}><Bold><Italic>Note:</Italic></Bold> while your video is disabled, the Tagflix overlay will not display. Your video should still be available and accessible on your site.</SmallText>
              </PinkBox>
        }
        {errorMessage && <MessageCmp type='error' width='100%' marginTop={'10px'} marginBottom='10px' ><p>{errorMessage}</p></MessageCmp>}
      </Div>
    );
  }
}

export default Auth(EditMappingModal);
