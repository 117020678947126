import { Component } from 'react';
import { addToInProgressEditsCount, subtractFromInProgressEditsCount } from './modal/helpers';

export default class EditableComponent extends Component {
  state = { _hasBeenEdited: false }

  componentWillUnmount = () => subtractFromInProgressEditsCount(this);

  setEditableState = (obj, callback = null) => {
    addToInProgressEditsCount(this);
    this.setState(obj, callback);
  }

  setInternalModalState = (modal, state, callback) => {
    addToInProgressEditsCount(this);
    modal.hasBeenEdited = true;
    modal.internalState = { ...modal.internalState, ...state };
    if(callback) callback();
  }

  addToInProgressEditsCount = () => addToInProgressEditsCount(this);
}
