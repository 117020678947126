import React from 'react';
import { observer, inject } from 'mobx-react';
import { P, Div, Img } from '../../../style/Global';
import { getRoleDescription } from '../SettingsUtil';
import { uppercaseFirstLetter } from '../../../util/TextUtil';
import { Bold, Italic, SmallText, Grey } from '../../../style/Typography';
import { MessageCmp } from '../../../style/StyledCmps';
import { FieldDiv } from '../../../style/FormStyle';
import { SubpageDiv, MaxWidthDiv, GreyLine } from '../SettingsStyle';
import PasswordInput from '../../../components/shared/PasswordInputCmp';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';
import moment from 'moment';
import { colors, fontSizes } from '../../../style/Constants';
import EditableComponent from '../../../components/shared/EditableComponent';

@inject('app', 'account')
@observer
class AccountPage extends EditableComponent {
  state = { newPassword1: '', newPassword2: '', oldPassword: '' };

  componentWillUnmount = () => this.props.account.clearMessage();

  onOldPasswordChange = e => this.setEditableState({ oldPassword: e.target.value })

  onNewPassword2Change = e => this.setEditableState({ newPassword2: e.target.value })

  onNewPassword1Change = e => this.setEditableState({ newPassword1: e.target.value })

  onSubmit = e => {
    e.preventDefault();
    const { account } = this.props;
    const { newPassword1, newPassword2, oldPassword } = this.state;
    if (!oldPassword) account.setMessage({
      isError: true,
      text: 'Please input the current password.'
    });
    else if (!newPassword1) account.setMessage({
      isError: true,
      text: 'Please input the new password.'
    });
    else if (newPassword1 !== newPassword2) account.setMessage({
      isError: true,
      text: 'Passwords do not match.'
    });
    else account.changePassword(newPassword1, oldPassword);
  }

  render() {
    const { app, account } = this.props;
    const { user, publisher, lastUserLocationInfo } = app;
    const { newPassword1, newPassword2, oldPassword } = this.state;
    const { message } = account;

    return (
      <SubpageDiv>
        <h1><Bold>{user.name}</Bold></h1>
        <P padding='8px 0'><Bold>{user.email}</Bold></P>
        <SmallText>
          <Grey>Your email address is used to login to this portal. We may send you critical service notices and your account manager may contact you at this address.  Contact your administrator or account manager to update.</Grey>
        </SmallText>
        <P paddingTop={25}><Bold><Italic>{uppercaseFirstLetter(publisher.userRole)}</Italic></Bold></P>
        <P paddingTop={5}>{getRoleDescription(publisher.userRole)}</P>
        <GreyLine />
        {lastUserLocationInfo && <LastUserLocationComponent lastUserLocationInfo={lastUserLocationInfo} />}
        <MaxWidthDiv>
          <h5>Change your password.</h5>
          <form>
            <FieldDiv>
              <label>Current password</label>
              <PasswordInput value={oldPassword} onChange={this.onOldPasswordChange} />
            </FieldDiv>

            <FieldDiv>
              <label>New password</label>
              <PasswordInput value={newPassword1} onChange={this.onNewPassword1Change} />
            </FieldDiv>

            <FieldDiv>
              <label>New password again</label>
              <PasswordInput value={newPassword2} onChange={this.onNewPassword2Change} />
            </FieldDiv>
            {
              message.text &&
              <MessageCmp type={message.isError ? 'error' : 'success'}>{message.text}</MessageCmp>
            }

            <WhiteButton icon='fa-check' text='Save' marginTop={20} onClick={this.onSubmit} />
          </form>
        </MaxWidthDiv>
      </SubpageDiv>
    );
  }
}
export const LastUserLocationComponent = ({ lastUserLocationInfo }) => {
  const { country, flagImage, ip, time } = lastUserLocationInfo;
  const currentTime = moment(time);
  const lastLoginDay = currentTime.format('dddd');
  const lastLoginDate = currentTime.format('MMMM Do YYYY');
  const lastLoginTime = currentTime.format('LT');

  return (
    <Div>
      <h5>Last account login</h5>
      <Div display='flex' alignItems='center' marginTop={15}>
        <Img width={52} height={52} src={flagImage.svgUrl}></Img>
        <Div width='100%' margin='0 0 0 10px' display='flex' flexDirection='column' >
          {country ?
            <P marginBottom={5}>We detect your account was last accesssed from a device located from <Bold>{country}</Bold> on <Bold>{lastLoginDay}, {lastLoginDate} at {lastLoginTime}</Bold> your local time.</P>
            :
            <P marginBottom={5}>We detect your account was last accesssed on <Bold>{lastLoginDay}, {lastLoginDate} at {lastLoginTime}</Bold> your local time.</P>
          }
          <P color={colors.greyText} fontSize={fontSizes.small}>IP address {ip}</P>
          <P color={colors.lightGreyText} fontSize={fontSizes.superTiny}>Flag icon by Freepik from www.flaticon.com.</P>
        </Div>
      </Div>
      <GreyLine />
    </Div>
  );
};

export default AccountPage;
