import { colors } from '../../style/Constants';
import { DivComponent, UlComponent, LiComponent, ExtendComponentStyles } from '../../style/Global';

export const SidebarDiv = DivComponent({
  width: 246,
  padding: 20
});

export const UserInfo = DivComponent({
  paddingTop: 5,
  paddingBottom: 20,
  borderBottom: `1px solid ${colors.superLightGreyText}`
});

export const NavList = UlComponent({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 30
});

export const NavLink = LiComponent({
  display: 'flex',
  alignItems: 'center',
  width: 196,
  height: 29,
  marginLeft: 10,
  cursor: 'pointer'
});

export const SelectedLink = ExtendComponentStyles(NavLink)({
  color: colors.white,
  backgroundColor: colors.darkPink
});
