import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { P, Div } from '../../../style/Global';
import { removeSavedUserData } from '../../../util/PersistedStateUtil';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';
import { Italic, Red, Grey } from '../../../style/Typography';
import { colors } from '../../../style/Constants';
import { flexColumn } from '../../../style/StyledCmps';
import { WhiteButtonContainer } from '../../../style/FormStyle';
import Icon from '../../../components/shared/FontAwesomeIconCmp';
import { RowDiv } from '../../../components/shared/table/Helpers';
import Table from '../../../components/shared/table/TableCmp';

@inject('modal')
@observer
class NoAssignedPublishers extends Component {
  render() {
    const { modal } = this.props;
    return (
      <Div>
        <P lineHeight={1.6}>It appears your account does not have any assigned publishers.</P>
        <WhiteButtonContainer>
          <WhiteButton icon='fa-check' text='Ok' closeModal={modal.close} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

@inject('modal', 'app')
@observer
class SelectPublisher extends Component {
  render() {
    const { modal, app } = this.props;
    return (
      <Div {...flexColumn}>
        <P lineHeight={1.6}>You are a member of multiple publishers. Select the one you would like to access.</P>
        <PublisherTable publishers={app.publishers} setPublisher={app.setPublisher} />
        <SignOut closeModal={() => {
          modal.close();
          removeSavedUserData();
        }} />
      </Div>
    );
  };
};

const tableBorder = `1px solid ${colors.lightGreyBorder}`;

const PublisherTable = ({ publishers, setPublisher }) => {
  return (
    <Div width='100%' border={tableBorder} margin='27px 0'>
      <Table
        contentHeight={350}
        scrollTrackBottomBorder={false}
        tableRecords={publishers}
        row={PublisherToSelect({ setPublisher })}
      />
    </Div>
  );
};

const PublisherToSelect = ({ setPublisher }) => (publisher, i) => {
  const isEven = i % 2;
  return (
    <RowDiv onClick={() => setPublisher(publisher, true)} isEven={isEven} key={i} justifyContent='space-between' padding={10} cursor='pointer'>
      <P><Red>{publisher.name}</Red></P>
      <P paddingRight={10} fontSize={11}><Grey><Italic>{publisher.userRole}</Italic></Grey></P>
    </RowDiv>
  );
};


const SignOut = ({ closeModal }) => (
  <Div display='flex' alignItems='center' cursor='pointer' onClick={closeModal}>
    <Icon name='fa-power-off' color={colors.red} lg />
    <P paddingLeft={8}>Sign out</P>
  </Div>
);

export const publisherModals = {
  noAssignedPublishers: {
    title: 'No assigned publishers',
    component: NoAssignedPublishers
  },
  selectPublisher: {
    title: 'Select Publisher',
    component: SelectPublisher
  }
};
