import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Select from 'react-select';
import { Div, H5, P } from '../../../style/Global';
import { getRoleDescription } from '../SettingsUtil';
import { Bold, Italic, Thin, LightGrey, SmallText } from '../../../style/Typography';
import { PinkBox, GreyBox, MessageCmp } from '../../../style/StyledCmps';
import { BorderedInput, WhiteButtonContainer } from '../../../style/FormStyle';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';
import Icon from '../../../components/shared/FontAwesomeIconCmp';
import Auth from '../../../components/shared/AuthCmp';

@inject('team', 'modal', 'app')
@observer
class InviteTeammate extends Component {
  constructor() {
    super();
    this.state = { firstname: '', surname: '', role: 'Read only', message: '' };
    this.onFirstnameChange = e => this.setState({ firstname: e.target.value });
    this.onSurnameChange = e => this.setState({ surname: e.target.value });
    this.onRoleChange = e => this.setState({ role: e.value });
    this.setMessage = message => this.setState({ message });
    this.clearMessage = () => this.setState({ message: '' });
    this.inviteTeammate = this.inviteTeammate.bind(this);
  }

  async inviteTeammate() {
    const { firstname, surname, role } = this.state;
    if (!firstname) return this.setMessage('What is your teammate\'s firstname?');
    this.clearMessage();
    const { teammateEmail, team, modal } = this.props;
    await team.inviteTeammate({ email: teammateEmail, firstname, surname, role });
    modal.close();
  }

  render() {
    const { teammateEmail } = this.props;
    const { firstname, surname, role, message } = this.state;
    return (
      <Div>
        <P paddingBottom={10}>An email with a unique invite code will be sent to</P>
        <Div paddingLeft={10}>
          <GreyBox>
            <P>{teammateEmail}</P>
          </GreyBox>
        </Div>
        <H5 paddingTop={15}>How should we address this person?</H5>
        <Div paddingLeft={10} paddingTop={5} display='flex'>
          <Div width={205} marginRight={10}>
            <P>Firstname</P>
            <BorderedInput type='text' value={firstname} onChange={this.onFirstnameChange} />
          </Div>
          <Div width={205}>
            <P>Surname</P>
            <BorderedInput type='text' value={surname} onChange={this.onSurnameChange} />
          </Div>
        </Div>
        { message && <MessageCmp type='error'><p>{message}</p></MessageCmp>}
        <H5 paddingTop={15}>And their initial access role?</H5>
        <Div paddingLeft={10} paddingTop={10}>
          <Select
            value={role}
            onChange={e => this.onRoleChange(e)}
            searchable={false} clearable={false}
            options={[
              { value: 'Admin', label: 'Admin' },
              { value: 'Read only', label: 'Read only' }
            ]}
          />
        </Div>
        <WhiteButtonContainer>
          <WhiteButton icon='far fa-envelope-open' text='Invite' onClick={this.inviteTeammate} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

@inject('team', 'modal')
@observer
class RemoveTeammate extends Component {
  render() {
    const { mate, modal, team } = this.props;
    return (
      <Div>
        <H5 paddingBottom={10}>Teammate</H5>
        <GreyBox>
          <P>{mate.name}</P>
          <Thin><LightGrey><Italic>{mate.email}</Italic></LightGrey></Thin>
        </GreyBox>
        <H5 padding='15px 0'>Are you sure you would like to delete this teammate?</H5>
        <PinkBox darker>
          <SmallText padding={5}><Bold><Italic>Warning:</Italic></Bold> any API access linked to this account will be revoked.</SmallText>
        </PinkBox>
        <WhiteButtonContainer marginTop={20}>
          <WhiteButton icon='fa-times' text='Remove' onClick={async () => {
            await team.removeTeammate(mate.id);
            modal.close();
          }} />
        </WhiteButtonContainer>
      </Div>
    );
  }
}

export const InfoBubbleContent = () => (
  <div>
    <Subtitle icon='fa-pencil' text='Owner' />
    <Description>{ getRoleDescription('ownerWithLink') }</Description>
    <Subtitle icon='fa-pencil' text='Admin' />
    <Description>{ getRoleDescription('admin') }</Description>
    <Subtitle icon='far fa-eye' text='Read only' />
    <Description>{ getRoleDescription('read-only') }</Description>
  </div>
);

const Subtitle = ({ icon, text }) => (
  <Div display='flex' alignItems='center'>
    <Icon name={icon} margin='0 5px 0 0' lg/>
    <H5>{text}</H5>
  </Div>
);

const Description = ({ children }) => (
  <P padding='2px 0 12px 10px'>
    <Italic>
      {children}
    </Italic>
  </P>
);

export const InviteTeammateModal = Auth(InviteTeammate);
export const RemoveTeammateModal = Auth(RemoveTeammate);
