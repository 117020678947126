import React from 'react';
import { Ul, LiComponent, Div } from '../../../style/Global';
import EditableComponent from '../../../components/shared/EditableComponent';

export class ColorPallete extends EditableComponent {

  generateColors() {
    const colors = [ '#FF6901', '#FCB900', '#7BDCB5', '#00CF84', '#8FD0FC', '#0693E3', '#ABB8C3', '#EE0D4E', '#F78DA6', '#9900EF' ];
    const { setNewPrimaryColor, marginLeft, marginRight } = this.props;
    let { primaryColor } = this.props;
    primaryColor = primaryColor.toUpperCase();

    return colors.map((color, i) => {
      return (
        <Colors
          onClick={() => {
            this.addToInProgressEditsCount();
            if(color !== primaryColor) setNewPrimaryColor(color)}
          }
          color={color}
          primaryColor={primaryColor}
          marginLeft={marginLeft}
          marginRight={marginRight}
          key={i}
        >
          { color === primaryColor &&
            <Div width='18px' height='18px' backgroundColor={color} borderRadius='100%' />
          }
        </Colors>
      );
    });
  };

  render() {
    return (
      <Ul
        height='50px'
        marginTop='15px'
        marginLeft='auto'
        marginRight='auto'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        {this.generateColors()}
      </Ul>
    );
  }
};

const Colors = LiComponent(({ color, primaryColor, marginLeft, marginRight }) =>({
  cursor: color !== primaryColor && 'pointer',
  backgroundColor: color === primaryColor ? 'white' : color,
  boxShadow: color === primaryColor ? `0 0 5px 0 ${color}` : 'none',
  border: color === primaryColor ? `solid 3px ${color}` : 'none',
  marginLeft: marginLeft ? marginLeft : '0',
  marginRight: marginRight ? marginRight : '10px',
  transition: 'box-shadow 150ms linear',
  '&:hover': { boxShadow: color !== primaryColor && `0 0 5px 0.5px ${color}` },
  borderRadius: '100%',
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0'
}));
