import React from 'react';
import { Div, DivComponent } from '../../../style/Global';
import { colors } from '../../../style/Constants';
import Icon from '../../../components/shared/FontAwesomeIconCmp';

export const GreyBoxWithArrow = ({ children, paddingLeft = 10 }) => {
  return (
    <Div paddingLeft={paddingLeft}>
      <GreyBox>{children}</GreyBox>
      <Icon name='fa-arrow-down' margin='-5px 0 0 30px' size={3} color={colors.lightGrey}/>
    </Div>
  );
};

const GreyBox = DivComponent({
  padding: 10,
  backgroundColor: colors.lightGrey
});

export default GreyBoxWithArrow;
