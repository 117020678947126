import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Div, P, DivComponent, ExtendComponentStyles } from '../../../style/Global';
import config from '../../../config/config';
import { Bold, Italic, TinyThinGreyText, TinyText } from '../../../style/Typography';
import { LabelText, LabelContainer, LabelDeleteButton } from '../../../style/StyledCmps';
import { colors } from '../../../style/Constants';
import { BorderedInput } from '../../../style/FormStyle';
import Icon from '../../../components/shared/FontAwesomeIconCmp';

@inject('video', 'modal', 'app')
@observer
class AddLabel extends Component {
  constructor() {
    super();
    this.state = {
      labels: [],
      formDisabled: false,
      charactersLeft: 0,
      inputInFocus: false
    };
    this.toggleDisableForm = this.toggleDisableForm.bind(this);
    this.setInputFocus = this.setInputFocus.bind(this);
    this.handleEnterKeyPress = this.handleEnterKeyPress.bind(this);
    this.handleDeleteLabel = this.handleDeleteLabel.bind(this);
    this.handleLabelCharacterCount = this.handleLabelCharacterCount.bind(this);
  }

  toggleDisableForm() { this.setState({ formDisabled: !this.state.formDisabled }); }
  setInputFocus(trueOrFalse) { this.setState({ inputInFocus: trueOrFalse }); }
  async handleEnterKeyPress(e) {
    if(e.keyCode == 13 && e.target.value) {
      const label = e.target.value;
      e.target.value = '';
      this.setState({ charactersLeft: config.maxCharacterCountPerLabel });
      this.setState({ labels: [ ...this.state.labels, label ] });
      this.props.video.setLabels(this.state.labels);
    }
  }

  submitLabelForUser() {
    const label = document.getElementById('label-input').value;
    this.props.video.setLabels([ ...this.props.video.labels, label ]);
  }

  handleDeleteLabel(labelIndex) {
    const newLabelsArray = [ ...this.state.labels ];
    newLabelsArray.splice(labelIndex, 1);

    this.setState({ labels: newLabelsArray });
    this.props.video.setLabels(newLabelsArray);
  }

  handleLabelCharacterCount(e) {
    const label = e.target.value;
    this.setState({ charactersLeft: config.maxCharacterCountPerLabel - label.length });
  }

  async componentDidMount() {
    this.props.simulateEnterPress(this.submitLabelForUser);
    const { videoListing } = this.props;
    this.setState({ charactersLeft: config.maxCharacterCountPerLabel });

    if(videoListing) {
      this.setState({ labels: videoListing.labels });
      this.props.video.setLabels(videoListing.labels);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const labelInput = document.getElementById('label-input');
    if(this.state.labels.length < this.props.app.publisherVideoAssetsConfig.maxVideoLabelCount) {
      if(this.state.formDisabled) {
        this.toggleDisableForm();
        labelInput.removeAttribute('disabled');
      }
    }
    else if(!this.state.formDisabled) {
      labelInput.setAttribute('disabled', true);
      this.toggleDisableForm();
    }
    if(prevState.labels.length !== this.state.labels.length) this.props.video.setLabels(this.state.labels);
  }

  render() {
    return (
      <Div>
        <P paddingBottom={10}><Bold>Add {this.props.editVideoProperties && 'or remove '}labels </Bold><Italic>(maximum of 5)</Italic></P>
        <Div paddingLeft={10}>
          <P marginBottom={10}>Use labels to help organise your video library. Videos can later be filtered by the labels you add here.</P>
          <BorderedInput
            id='label-input'
            type='text'
            onKeyDown={ e => this.handleEnterKeyPress(e) }
            maxLength={ config.maxCharacterCountPerLabel }
            onChange={ e => this.handleLabelCharacterCount(e) }
            onFocus={ () => this.setInputFocus(true) }
            onBlur={ () => this.setInputFocus(false) }
            backgroundColor={ this.state.formDisabled ? colors.lightGrey : colors.white }
          />
          <IconTagContainer>
            <Icon name='fa-tag' color={colors.red} me />
          </IconTagContainer>
          <InstructionsAndCountContainer>
            <TinyThinGreyText>Type a label name and hit enter to add.</TinyThinGreyText>
            { this.state.inputInFocus && <CharacterCount>{this.state.charactersLeft} characters left</CharacterCount> }
          </InstructionsAndCountContainer>
          { (this.state.labels) && <Labels labelArray={this.state.labels} handleDeleteLabel={this.handleDeleteLabel} /> }
        </Div>
      </Div>
    );
  }
}

class Labels extends Component {
  render() {
    const labels = this.props.labelArray.map( (label, index) => <Label key={index} labelIndex={index} labelText={label} handleDeleteLabel={this.props.handleDeleteLabel} /> );
    return (
      <LabelArea>
        {labels}
      </LabelArea>
    );
  }
}

class Label extends Component {
  render() {
    return (
      <LabelContainer marginBottom={7}>
        <LabelText>
          {this.props.labelText}
        </LabelText>
        <LabelDeleteButton onClick={ () => this.props.handleDeleteLabel(this.props.labelIndex) }>
          <Icon name='fa-times' color={colors.grey} />
        </LabelDeleteButton>
      </LabelContainer>
    );
  }
}

const InstructionsAndCountContainer = DivComponent({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 8
});

const IconTagContainer = DivComponent({
  display: 'inline-block',
  float: 'right',
  position: 'relative',
  textAlign: 'right',
  bottom: 20,
  right: 5
});

const CharacterCount = ExtendComponentStyles(TinyText)({
  fontStyle: 'italic',
  marginRight: 10
});

const LabelArea = DivComponent({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  flexWrap: 'wrap'
});

export default AddLabel;
