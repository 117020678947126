import React from 'react';
import { Route } from 'react-router-dom';
import routesFromConfig from '../config/RouteConfig';

export function SubRoutes(routes) {
  return routes.map((route, i) => (
    <Route key={i} exact={route.exact} path={route.path} render={props => (
      // pass the sub-routes down to keep nesting
      <route.component {...props} {...route.props} routes={route.routes}/>
      )}/>
  ));
}

function generateRoutesList(routes, arr = []) {
  return routes.reduce( (acc, route) => {
    if (route.routes) return [ route.path, ...generateRoutesList(route.routes, acc) ];
    return [ ...acc, route.path ];
  }, arr);
}

export const routesList = generateRoutesList(routesFromConfig);
