import React from 'react';
import { P, Div } from '../../../style/Global';
import { GreyBox, MessageCmp } from '../../../style/StyledCmps';
import { BorderedInput } from '../../../style/FormStyle';
import { observer, inject } from 'mobx-react';
import EditableComponent from '../../../components/shared/EditableComponent';

@inject('mediaMappings')
@observer
class IframeEmbedCode extends EditableComponent  {
  render() {
    const { mediaMappings, currentStore, currentModal, addEmbedCode, embedIsValidString, errorMessage, title, marginBottom = 10, marginTop = 15 } = this.props;
    return (
      <Div>
        <GreyBox width='100%' marginTop={marginTop} marginBottom={marginBottom}>
          <P>Paste {title} embed code</P>
          <BorderedInput
          type='text'
          onChange={e => {
            currentModal._notice = null;
            this.addToInProgressEditsCount();
            mediaMappings.setErrorMessage(null);
            addEmbedCode(e);
            currentStore.hasCodeBeenEntered = true;
          }}
          marginTop={0} width='100%'
        />
        </GreyBox>
        { !currentStore[embedIsValidString] && currentStore.hasCodeBeenEntered &&
        <MessageCmp type='error' width='100%' marginTop={10}>
          <P>{errorMessage}</P>
        </MessageCmp>
      }
      </Div>
  )}
}

export default IframeEmbedCode;
