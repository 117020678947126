import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Div, P, H5 } from '../../../style/Global';
import { Bold, Italic } from '../../../style/Typography';
import { PinkBox } from '../../../style/StyledCmps';
import { ColorPallete } from './ColorPalleteCmp';
import API from '../../../util/ApiUtil';
import ToggleCmp from '../../../components/shared/ToggleCmp';


@observer
export class ToggleOverlayDefaultsModal extends Component {
  constructor(props) {
    super();
    const { modal } = props;
    modal.internalState = { enabled: false }
  }

  onChange = () => this.props.modal.internalState.enabled = !this.props.modal.internalState.enabled;

  render() {
    const { enabled } = this.props.modal.internalState;
    return (
      <Div>
        <H5 lineHeight='normal' letterSpacing='normal'>Are you sure you would like to change the default behavior for new media mappings?</H5>
        <Div margin='20px 10px 10px 10px'>
          <Div display='flex' alignItems='center' marginBottom='15px'>
            <ToggleCmp checked={enabled} onChange={this.onChange} icons={false} />
            <p>also apply changes to all existing video mappings</p>
          </Div>
          {enabled &&
            <PinkBox>
              <P><Bold><Italic>Warning:</Italic></Bold> applying change to existing mappings will overwrite any individual customizations you have already set.</P>
            </PinkBox>
          }
        </Div>
      </Div>
    );
  }
}

@observer
export class ToggleShowVisualizationOnVideoStart extends Component {
  constructor(props) {
    super();
    const { modal } = props;
    modal.internalState = { enabled: false }
  }

  onChange = () => this.props.modal.internalState.enabled = !this.props.modal.internalState.enabled;

  render() {
    const { enabled } = this.props.modal.internalState;
    return (
      <Div>
        <H5 lineHeight='normal' letterSpacing='normal'>Are you sure you would like to change the default behavior for new media mappings?</H5>
        <Div margin='20px 10px 10px 10px'>
          <Div display='flex' alignItems='center' marginBottom='15px'>
            <ToggleCmp checked={enabled} onChange={this.onChange} icons={false} />
            <p>also apply changes to all existing video mappings</p>
          </Div>
          {enabled &&
            <PinkBox>
              <P><Bold><Italic>Warning:</Italic></Bold> applying change to existing mappings will overwrite any individual customizations you have already set.</P>
            </PinkBox>
          }
        </Div>
      </Div>
    );
  }
}

@observer
export class UpdateDefaultPrimaryColor extends Component {
  constructor(props) {
    super();
    const { modal, color } = props;
    modal.internalState = { enabled: false, color }
  }

  onChange = () => this.props.modal.internalState.enabled = !this.props.modal.internalState.enabled;

  render() {
    const { enabled } = this.props.modal.internalState;

    return (
      <Div>
        <H5 lineHeight='normal' letterSpacing='normal'>Are you sure you would like to change the default primary color for new media mappings?</H5>
        <Div margin='20px 10px 10px 10px'>
          <Div display='flex' alignItems='center' marginBottom='15px'>
            <ToggleCmp checked={enabled} onChange={this.onChange} icons={false} />
            <p>also apply changes to all existing video mappings</p>
          </Div>
          {enabled &&
            <PinkBox>
              <P><Bold><Italic>Warning:</Italic></Bold> applying change to existing mappings will overwrite any individual customizations you have already set.</P>
            </PinkBox>
          }
        </Div>
      </Div>
    );
  }
}

@inject('modal', 'mediaMappings')
@observer
export class UpdateDefaultSettingsForMediaMapping extends Component {
  constructor(props) {
    super();
    let { primaryColor, showPostVideoOverlay, hideVisualizationOnStart } = props.mapping;
    if (!primaryColor) primaryColor = '#EE0D4E';
    this.state = { enabled: showPostVideoOverlay, primaryColor, hideVisualizationOnStart };
  };

  async componentWillUnmount() {
    const { mapping } = this.props;
    const { videoId } = mapping;
    const { enabled, primaryColor, hideVisualizationOnStart } = this.state;
    const data = {
      publisherVideoId: videoId,
      ...primaryColor !== mapping.primaryColor && { primaryColor },
      ...enabled !== mapping.showPostVideoOverlay && { showPostVideoOverlay: enabled },
      ...hideVisualizationOnStart !== mapping.hideVisualizationOnStart && { hideVisualizationOnStart }
    };
    if (Object.keys(data).length > 1) await this.updateUserDefaults(data);
  };

  async updateUserDefaults(data) {
    const { mediaMappings } = this.props;
    const { markVideoIdSaved } = mediaMappings;
    const { videoId } = this.props.mapping;
    const response = await API.put('portal/media', { data: { pubId: API.publisherId(), ...data } });
    if (response.status !== 200) return;
    markVideoIdSaved(videoId);
    delete data.publisherVideoId;
    mediaMappings.setNewMappingOverlayProperties(videoId, data);
  }

  setNewPrimaryColor = primaryColor => this.setState({ primaryColor })

  toggleShowProductCardsAtEndofVideo = () => this.setState({ enabled: !this.state.enabled })

  render() {
    return (
      <Div marginLeft='5px'>
        <P>Control the way your Tagflix overlay looks and behaves.</P>

        <H5 marginTop='25px'>Primary Color</H5>
        <Div marginTop='15px' marginLeft='10px' display='flex' justifyContent='center'>
          <Div>
            <P marginTop='5px'>Set the color for various parts of the overlay such as the wavelet and button hover states.</P>
            <ColorPallete setNewPrimaryColor={this.setNewPrimaryColor} primaryColor={this.state.primaryColor} />
          </Div>
        </Div>

        <H5 marginTop='25px'>Behavior</H5>
        <Div marginTop='20px' marginBottom='15px' marginLeft='10px' display='flex' justifyContent='center'>
          <Div width='65%'>
            <P marginTop='5px'>Show product cards at end of video?</P>
          </Div>
          <Div width='35%'>
            <Div display='flex' alignItems='center' marginLeft='10px'>
              <ToggleCmp checked={this.state.enabled} onChange={() => this.setState({ enabled: !this.state.enabled })} icons={false} />
              <p>{this.state.enabled ? <Bold> Enabled</Bold> : ' Disabled'}</p>
            </Div>
          </Div>
        </Div>

        <Div marginTop='10px' marginBottom='5px' marginLeft='10px' display='flex' justifyContent='center'>
          <Div width='65%'>
            <P marginTop='5px'>Show visualization on start?</P>
          </Div>
          <Div width='35%'>
            <Div display='flex' alignItems='center' marginLeft='10px'>
              <ToggleCmp checked={!this.state.hideVisualizationOnStart} onChange={() => this.setState({ hideVisualizationOnStart: !this.state.hideVisualizationOnStart })} icons={false} />
              <p>{!this.state.hideVisualizationOnStart ? <Bold> Enabled</Bold> : ' Disabled'}</p>
            </Div>
          </Div>
        </Div>

      </Div>
    );
  }
}

@inject('modal')
@observer
export class TestModal extends Component {
  state = { test: 'here' };

  render() {
    const modal = this.props.modal.modals.find(modal => modal.title === 'Test Modal');
    const { activeTab } = modal;
    return (
      <Div>
        {activeTab === 'Tab 1' ?
          <React.Fragment>
            <H5 lineHeight='normal' letterSpacing='normal' onClick={() => this.props.modal.open({
              title: 'Modal 2',
              component: () => <Div>Hi hello I am a new modal</Div>,
              globalButtons: [{ text: 'close', func: this.props.modal.close }, { text: 'modal 3', func: () => this.props.modal.open({ title: 'Modal 3', component: () => <Div>Modal 3 has arrived for the party</Div> }) }]
            })}>Tab 1</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
            <H5 lineHeight='normal' marginTop='50px' letterSpacing='normal'>Tab 2</H5>
          </React.Fragment>
          :
          activeTab === 'Tab 2' ?
            <H5 lineHeight='normal' letterSpacing='normal'>Tab 2</H5>
            :
            null
        }
      </Div>
    );
  }
}
