import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import emailValidator from 'email-validator';
import Select from 'react-select';
import { Div, H5 } from '../../../style/Global';
import { uppercaseFirstLetter } from '../../../util/TextUtil';
import { SmallText, LightGrey } from '../../../style/Typography';
import { colors, flexCenter } from '../../../style/Constants';
import { Spacer, MessageCmp } from '../../../style/StyledCmps';
import { BorderedInput, FieldDiv } from '../../../style/FormStyle';
import { SubpageDiv, MaxWidthDiv, GreyLine } from '../SettingsStyle';
import Table from '../../../components/shared/table/TableCmp';
import { RowDiv } from '../../../components/shared/table/Helpers';
import Icon from '../../../components/shared/FontAwesomeIconCmp';
import WhiteButton from '../../../components/shared/WhiteButtonCmp';
import InfoBubble from '../../../components/shared/InfoBubbleCmp';
import { InviteTeammateModal, RemoveTeammateModal, InfoBubbleContent } from './TeamModals';

@inject('team', 'modal', 'app')
@observer
class TeamPage extends Component {
  constructor() {
    super();
    this.state = { teammateEmail: '' };
    this.onTeammateEmailChange = e => this.setState({ teammateEmail: e.target.value });
    this.showRemoveTeammateModal = this.showRemoveTeammateModal.bind(this);
    this.showInviteTeammateModal = this.showInviteTeammateModal.bind(this);
  }

  async componentDidMount() {
    await this.props.team.getTeam();
  }

  componentWillUnmount() {
    this.props.team.clearMessage('all');
  }

  showInviteTeammateModal(e) {
    e.preventDefault();
    const { team, modal } = this.props;
    const { teammateEmail } = this.state;
    if (!emailValidator.validate(teammateEmail)) {
      return team.setMessage({
        section: 'invite',
        isError: true,
        text: 'Please input a valid email address.'
      });
    }
    team.clearMessage('invite');
    modal.open({
      title: 'Invite teammate',
      component: () => <InviteTeammateModal teammateEmail={teammateEmail} />
    });
  }

  showRemoveTeammateModal(mate) {
    const { modal } = this.props;
    modal.open({
      title: 'Remove teammate',
      component: () => <RemoveTeammateModal mate={mate} />
    });
  }

  render() {
    const { team, app } = this.props;
    return (
      <SubpageDiv>
        <MaxWidthDiv>
          <H5>Invite your teammates.</H5>

          <form>
            <FieldDiv>
              <label>Teammate's email</label>
              <BorderedInput type='text' value={this.state.teammateEmail} onChange={e => this.onTeammateEmailChange(e)} />
            </FieldDiv>
            <Message section='invite' team={team}/>
            <WhiteButton icon='far fa-envelope-open' text='Invite' marginTop={15} onClick={e => this.showInviteTeammateModal(e)} />
          </form>
        </MaxWidthDiv>

        <GreyLine />
        <H5>Manage teammates.</H5>
        <Message section='manage' team={team}/>
        <Spacer height={15} />
        <Table title='Role'
          HeaderIcon={<InfoBubble><InfoBubbleContent/></InfoBubble>}>
          { team.team.map( (mate, i) => <Row userRole={app.publisher.userRole} mate={mate} i={i} key={i} showRemoveTeammateModal={this.showRemoveTeammateModal} changeTeammateRole={team.changeTeammateRole} /> )}
          <Div height={50} />
        </Table>
      </SubpageDiv>
    );
  }
}

const Row = ({ mate, i, showRemoveTeammateModal, changeTeammateRole }) => {
  // const userLevel = getRoleLevel(userRole);
  const userLevel = 2;
  const mateLevel = getRoleLevel(mate.role);
  const disabled = userLevel <= mateLevel;
  return (
    <RowDiv isEven={i%2}>
      <Div {...flexCenter} width={150} borderRight={`1px solid ${colors.lightGreyBorder}`}>
        <SelectBox disabled={disabled} mate={mate} changeTeammateRole={changeTeammateRole} />
      </Div>
      <Div display='flex' flexGrow={1} justifyContent='space-between' alignItems='center' padding={10}>
        <div>
          <p>{mate.name}</p>
          <SmallText><LightGrey>{mate.email}</LightGrey></SmallText>
        </div>
        {
          !disabled
            &&
            <Icon name='fa-times' color={colors.grey} cursor='pointer' onClick={() => showRemoveTeammateModal(mate)} />
        }
      </Div>
    </RowDiv>
  );
};

const SelectBox = ({ disabled, mate, changeTeammateRole }) => {
  if (mate.role === 'owner') return (
    <Div width={120}>
      <Select
        value={uppercaseFirstLetter(mate.role)}
        disabled={true}
        searchable={false} clearable={false}
        options={[{ value: 'Owner', label: 'Owner' }]}
      />
    </Div>
  );
  else return (
    <Div width={120}>
      <Select
        disabled={disabled}
        value={uppercaseFirstLetter(mate.role)}
        onChange={e => changeTeammateRole(e.value, mate.id)}
        searchable={false} clearable={false}
        options={[
          { value: 'Admin', label: 'Admin' },
          { value: 'Read only', label: 'Read only' }
        ]}
      />
    </Div>
  );
};

const Message = observer(({ team, section }) => {
  const message = team.messages[section];
  if (!message.text) return null;
  if (message.isError) return <MessageCmp type='error'><p>{message.text}</p></MessageCmp>;
  return <MessageCmp type='success'><p>{message.text}</p></MessageCmp>;
});

const getRoleLevel = role => {
  switch (role.toLowerCase()) {
    case 'owner': return 2;
    case 'admin': return 1;
    case 'read only': return 0;
    default: return 0;
  }
};

export default TeamPage;
