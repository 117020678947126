import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Div, P } from '../../../style/Global';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import '../../../style/external/scroll.css';
import Pagination from './PaginationCmp';
import Filter from './FilterCmp';
import FilterInput from './FilterInputCmp';
import FilterDropdown from './FilterDropdownCmp';
import { REQUESTED_RECORDS_LIMIT } from '../../../util/ApiUtil';
import { TableHeader } from './TableHeader';
import { StaticMessage, StaticMessageContainer } from './Helpers';
import { colors } from '../../../style/Constants';
import { preventPopupDismissalPropagation } from '../helpers';
import { getQueryParamaters, getQueryString } from '../../app/QueryParamsHelper';
import { Link } from 'react-router-dom';

@observer
export class Table extends Component {
  state = {
    filterMenuRendered: false,
    popupBackground: false,
    awaitingResponse: false,
    showErrorMessage: false,
    disableForm: false,
    applyButtonClicked: false,
    inputs: {},
    defaultInputValue: '',
    dropdownComponents: {},
    filterApplied: false,
    filterParams: {},
    shouldShowScrollbar: false
  };

  async componentDidMount() {
    const { store } = this.props;
    if (!store) return;

    if (store.filterRecords && !store.filterRecordsTemplate) throw new Error('`filterRecordsTemplate` is required');
    if (store.filterRecordsTemplate && !store.filterRecords) throw new Error('`filterRecords` is required');

    if (store.pagination && !store.tableSetSkipIndex) throw new Error('`tableSetSkipIndex` is required');
    if (store.tableSetSkipIndex && !store.pagination) throw new Error('`pagination` is required');

    const filterRecordsTemplate = store.filterRecordsTemplate();
    this.setState({ filterParams: filterRecordsTemplate }, this.checkForAppliedFilter);
  };

  componentDidUpdate = async prevProps => {
    if (this.props.pubId !== prevProps.pubId) {
      await this.resetDropdowns();
      await this.resetInputs();
      const filterParams = this.props.store.filterRecordsTemplate();
      this.setState({ filterParams });
      this.toggleOffFilterApplied();
    };
  };

  renderPageNumber = skipToIndex => {
    this.props.store.tableSetSkipIndex({ skip: skipToIndex, ...this.state.filterParams });
  };

  renderNextPage = () => {
    const { currentPage } = this.props.store.tablePagination();
    this.props.store.tableSetSkipIndex({ skip: currentPage * REQUESTED_RECORDS_LIMIT, ...this.state.filterParams });
  };

  renderPreviousPage = () => {
    const { currentPage } = this.props.store.tablePagination();
    this.props.store.tableSetSkipIndex({ skip: (currentPage - 2) * REQUESTED_RECORDS_LIMIT, ...this.state.filterParams });
  };

  toggleAwaitingResponse = () => this.setState({ awaitingResponse: !this.state.awaitingResponse });

  toggleFilterMenu = () => {
    const { app } = this.props;
    app.popupToStore({ name: 'filter', popupToStore: this.toggleFilterMenu });
    this.setState({ filterMenuRendered: !this.state.filterMenuRendered })
  };

  toggleShowErrorMessage = () => this.setState({ showErrorMessage: !this.state.showErrorMessage });

  toggleDisableForm = () => {
    this.setState({ disableForm: !this.state.disableForm });
    const filterInputs = document.getElementsByClassName('filter-string');
    const filterInputsArray = Array.from(filterInputs);
    filterInputsArray.forEach(element => element.removeAttribute('disabled'));
  };

  toggleApplyButtonClicked = () => this.setState({ applyButtonClicked: !this.state.applyButtonClicked });

  filterRecords = async query => {
    this.toggleAwaitingResponse();
    this.toggleDisableForm();
    try {
      await query();
      this.checkForAppliedFilter();
      if (this.state.filterMenuRendered) this.toggleFilterMenu();
      this.toggleAwaitingResponse();
      this.toggleDisableForm();
    } catch (error) {
      this.toggleShowErrorMessage();
    };
  };

  createObservableInput = (name, value) => {
    let newState = this.state.inputs;
    newState[name] = value;
    this.setState({ inputs: newState });
  };

  createObservableDropdown = (name, selectedValue) => {
    let newState = this.state.dropdownComponents;
    newState[name] = { selectedValue: selectedValue, rendered: false };
    this.setState({ dropdownComponents: newState });
  };

  changeSelectedOption = (name, newSelectedValue) => {
    let newState = this.state.dropdownComponents;
    newState[name].selectedValue = newSelectedValue;
    this.setState({ dropdownComponents: newState });
  };

  toggleDropdowns = nameOfClickedDropdown => {
    let dropdownComponentsNames = this.dropdownComponentsNameArray();
    let newState = this.state.dropdownComponents;
    dropdownComponentsNames.forEach(name => {
      name = Number(name);
      if (newState[name].rendered === true) {
        newState[name].rendered = false;
      }
      else if (name === nameOfClickedDropdown && newState[name].rendered === false) {
        newState[name].rendered = true;
      }
    });
    this.setState({ dropdownComponents: newState });
  };

  dropdownComponentsNameArray = () => {
    let dropdownCmpNames = [];
    for (let name in this.state.dropdownComponents) dropdownCmpNames.push(name);
    return dropdownCmpNames;
  };

  dropdownFilterApplied = () => {
    const dropdownComponentsNameArray = this.dropdownComponentsNameArray();
    return dropdownComponentsNameArray.some(name => this.state.dropdownComponents[name].selectedValue != 'All');
  };

  inputComponentsNameArray = () => {
    let inputCmpNames = [];
    for (let name in this.state.inputs) inputCmpNames.push(name);
    return inputCmpNames;
  };

  inputFilterApplied = () => {
    const inputComponentsNameArray = this.inputComponentsNameArray();
    return inputComponentsNameArray.some(name => this.state.inputs[name] != '' || this.state.inputs[name] === null);
  };

  checkForAppliedFilter = () => {
    if (this.inputFilterApplied() || this.dropdownFilterApplied() || this.checkQueryParamsForAppliedFilter()) this.setState({ filterApplied: true });
  };

  checkQueryParamsForAppliedFilter = () => {
    const queryParamaters = getQueryParamaters();
    if(queryParamaters.media || (queryParamaters.videoPlayer && queryParamaters.videoPlayer !== 'all' && queryParamaters.videoPlayer !== 'All' )) return true;
    return false;
  }

  toggleOffFilterApplied = () => this.setState({ filterApplied: false });

  resetDropdowns = () => {
    const dropdownCmpNames = this.dropdownComponentsNameArray();
    let newState = this.state.dropdownComponents;
    dropdownCmpNames.forEach(name => {
      newState[name].selectedValue = 'All';
    });
    this.setState({ dropdownComponents: newState });
  };

  resetInputs = () => {
    let newState = this.state.inputs;
    for (let name in this.state.inputs) {
      newState[name] = this.state.defaultInputValue;
    };
    this.setState({ inputs: newState });
  };

  applyButtonClicked = async e => {
    preventPopupDismissalPropagation(e)
    const newParams = await this.props.store.filterRecords();
    const { skip, filterParams } = newParams;
    const callback = () => this.filterRecords(() => this.props.store.tableQuery({ skip, ...this.state.filterParams }));
    this.setState({ filterParams }, callback);
    if (this.state.applyButtonClicked === false) this.toggleApplyButtonClicked();
    if (this.state.showErrorMessage) this.toggleShowErrorMessage();
  };

  clearButtonClicked = async () => {
    await this.resetDropdowns();
    await this.resetInputs();
    this.applyButtonClicked();
    this.toggleOffFilterApplied();
  };

  renderFilterOptions = filterOptions => {
    const queryParamaters = getQueryParamaters();
    let queryParamaterOption;

    return filterOptions.map((filterOption, i) => {
      const type = Object.keys(filterOption).shift();
      const config = filterOption[type];

      switch (type) {
        case 'input':
          return <FilterInput
            store={this.state}
            createObservableInput={this.createObservableInput}
            key={i} id={i}
            inputLabel={config.label}
            inputPlaceholder={config.placeholder}
            iconName={`fas fa-${config.icon}`}
          />;
        case 'dropdown':
          if(config.label === 'Player' && queryParamaters.videoPlayer) queryParamaterOption = queryParamaters.videoPlayer[0].toUpperCase() + queryParamaters.videoPlayer.substring(1);

          return <FilterDropdown
            store={this.state}
            queryParamaterOption={queryParamaterOption}
            key={i} id={i}
            createObservableDropdown={this.createObservableDropdown}
            toggleDropdowns={this.toggleDropdowns}
            changeSelectedOption={this.changeSelectedOption}
            options={config.options}
            label={config.label}
            class='dropdown'
          />;
      };
    });
  };

  render() {
    const { title, title2, store, tableRecords, minHeight, maxHeight, contentHeight } = this.props;
    const { shouldShowScrollbar } = this.state;
    const isLoading = store && store.isLoading;
    const tablePagination = store && store.tablePagination ? store.tablePagination : null;
    const tableFilterOptions = store && store.tableFilterOptions ? store.tableFilterOptions : null;
    const DEFAULT_MAXIMUM_TABLE_HEIGHT = 500;
    const DEFAULT_MINIMUM_TABLE_HEIGHT = 200;
    let pages;
    if (tablePagination) pages = tablePagination();

    return (
      <Div width='100%'>
        {title &&
          <TableHeader title={title} title2={title2}>
            {tableFilterOptions &&
              <Filter
                selectedValue={this.state.dropdownComponents && this.state.dropdownComponents['1'] && this.state.dropdownComponents['1'].selectedValue}
                toggleFilterMenu={this.toggleFilterMenu}
                clearButtonClicked={this.clearButtonClicked}
                applyButtonClicked={this.applyButtonClicked}
                filterApplied={this.state.filterApplied}
                awaitingResponse={this.state.awaitingResponse}
                showErrorMessage={this.state.showErrorMessage}
                filterMenuRendered={this.state.filterMenuRendered}
              >
                {this.renderFilterOptions(tableFilterOptions())}
              </Filter>
            }
          </TableHeader>
        }
        { tableRecords.length > 0 && !isLoading &&
          <OverlayScrollbarsComponent
            options={{
              resize: 'none', className: 'os-theme-thick-dark table-scroller',
              scrollbars: { clickScrolling: true },
              callbacks: { onOverflowChanged: args => this.setState({ shouldShowScrollbar: args.yScrollable }) }
            }}
            style={{
              height: contentHeight || 'auto',
              minHeight: minHeight || DEFAULT_MINIMUM_TABLE_HEIGHT,
              maxHeight: maxHeight || DEFAULT_MAXIMUM_TABLE_HEIGHT,
              paddingRight: shouldShowScrollbar ? '15px' : 0
            }}
          >
            <Div>
              { tableRecords.map((item, i) => this.props.row(item, i)) }
            </Div>
          </OverlayScrollbarsComponent>
        }
        {
          isLoading
            ?
              <StaticMessageContainer>
                <StaticMessage>Loading...</StaticMessage>
              </StaticMessageContainer>
            :
            !tableRecords.length
            &&
            <StaticMessageContainer>
              <StaticMessage>No Results Found</StaticMessage>
              {
                this.state.filterApplied &&
                <React.Fragment>
                  <P fontSize={12} marginLeft='5px'>(</P>
                  <ClearButton onClick={this.clearButtonClicked} />
                  <P fontSize={12}>).</P>
                </React.Fragment>
              }
            </StaticMessageContainer>
        }
        {pages && pages.currentPage &&
          <Pagination
            isLoading={isLoading}
            pagination={pages}
            renderNextPage={this.renderNextPage}
            renderPageNumber={this.renderPageNumber}
            renderPreviousPage={this.renderPreviousPage}
          />
        }
      </Div>
    );
  };
};

export const ClearButton = ({ onClick }) => {
  return (
    <Link to={getQueryString({ videoPlayer: 'All' })}>
      <P onClick={onClick} fontSize={12} color={colors.red} cursor='pointer'>clear filter</P>
    </Link>
  );
};

export default Table;
