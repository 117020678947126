import config from '../../../../config/config';
export const PUBLISHER_SOLUTION_IFRAME_ID = 'receiver';

export const loadScript = src => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  document.body.appendChild(script);
  return new Promise((resolve, reject) => {
    script.onload = resolve;
    script.onerror = reject;
  });
};

const performActionOnOneVideo = action => {
  const iFrame = document.getElementById(PUBLISHER_SOLUTION_IFRAME_ID);
  iFrame.contentWindow.postMessage(action, config.publisherSolutionDomain);
};

export const readyPostMessageReceived = startAtSecond => {
  performActionOnOneVideo('mute');
  performActionOnOneVideo('play');
  iFrameSeekTo(startAtSecond);
};

export const playingPostMessageReceived = () => performActionOnOneVideo('pause');

export const iFrameSeekTo = seconds => performActionOnOneVideo({ command: 'seekTo', arg: seconds });
