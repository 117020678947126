import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import config from '../../../config/config';
import { copyTextToClipboard } from '../../../util/TextUtil';
import { Div, P, H5, DivComponent } from '../../../style/Global';
import Auth from '../../../components/shared/AuthCmp';
import { colors } from '../../../style/Constants';
import { Bold, Grey, Red, RedUnderline, SmallText } from '../../../style/Typography';
import { MessageCmp } from '../../../style/StyledCmps';
import { SubpageDiv, GreyLine, ToggleDiv } from '../SettingsStyle';
import Table from '../../../components/shared/table/TableCmp';
import { RowDiv } from '../../../components/shared/table/Helpers';
import Icon from '../../../components/shared/FontAwesomeIconCmp';
import { RefreshKeyModal, DisableAllKeysModal } from './KeysModals';
import ToggleCmp from '../../../components/shared/ToggleCmp';

@inject('keys', 'modal')
@observer
class KeysPage extends Component {
  constructor(props) {
    super();
    this.openRefreshKeyModal = this.openRefreshKeyModal.bind(this);
    this.openDisableAllKeysModal = this.openDisableAllKeysModal.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
    props.keys.getKeys();
  }

  componentWillUnmount() {
    this.props.keys.clearErrorMessage();
  }

  openRefreshKeyModal(keyIndex) {
    const { modal } = this.props;
    modal.open({
      title: 'Refresh api key',
      component: () => <RefreshKeyModal keyIndex={keyIndex} />
    });
  }

  openDisableAllKeysModal() {
    const { modal } = this.props;
    modal.open({
      title: 'Disable API access',
      component: DisableAllKeysModal
    });
  }

  onToggleClick() {
    const { enabled, enableKeys } = this.props.keys;
    if (enabled){
      return this.openDisableAllKeysModal();
    }
    enableKeys();
  }

  render() {
    const { keys, enabled, errorMessage } = this.props.keys;
    return (
      <SubpageDiv>
        <H5>Generate api keys for your account.</H5>
        <ToggleDiv>
          <ToggleCmp checked={enabled} onChange={this.onToggleClick} icons={false} />
          <p>{ enabled ? <Bold> Enabled</Bold> : ' Disabled'}</p>
        </ToggleDiv>

        {
          errorMessage
            &&
            <MessageCmp type='error'>
              <p>{errorMessage}</p>
            </MessageCmp>
        }

        <GreyLine />

        { enabled ? <EnabledContent keys={keys} openRefreshKeyModal={this.openRefreshKeyModal}/> : <DisabledContent /> }
      </SubpageDiv>
    );
  }
}

const DisabledContent = () => (
  <div>
    <p>Keys are disabled for your account by default and will appear here once you enable them.
      <br/><br/>
    Find out more in the <a href={config.documentationUrl.api} target='_blank'><RedUnderline>api documentation</RedUnderline></a>.</p>
  </div>
);

const EnabledContent = ({ keys, openRefreshKeyModal }) => (
  <div>
    <Table
      title='API Keys'
      minHeight='auto'
      tableRecords={keys}
      row={(apiKey, i) => <Row apiKey={apiKey} openRefreshKeyModal={openRefreshKeyModal} i={i} key={i}
      />}
    />
    <P paddingTop={25}>These keys are tied to your username meaning you are solely responsible for their safekeeping. Treat them as password equivalents.
      <br/><br/>
    You only need one key to use the api. We give you rotational keys to make it easy for you to update your implementations should a primary key be compromised.
      <br/><br/>
    Find out more in the <a href={config.documentationUrl.api} target='_blank'><RedUnderline>api documentation</RedUnderline></a>.</P>
  </div>
);

const Row = ({ apiKey, openRefreshKeyModal, i }) => {
  const keyIndex = i + 1;
  return (
    <RowDiv isEven={i%2} justifyContent='space-between'>
      <RowLeft>
        <p>{keyIndex}</p>
      </RowLeft>
      <RowRight>
        <P padding={12} fontFamily="'Courier New', courier, mono" maxWidth={550} wordWrap='break-word'><Grey>{apiKey}</Grey></P>
        <Div display='flex' justifyContent='space-between' width={85}>
          <Div display='flex' alignItems='center' cursor='pointer' onClick={() => copyTextToClipboard(apiKey)}>
            <Div marginTop={-2} marginRight={5}><Icon name='far fa-copy' color={colors.red}/></Div>
            <SmallText><Red>copy</Red></SmallText>
          </Div>
          <Icon name='fa-sync' color={colors.grey} cursor='pointer' onClick={() => openRefreshKeyModal(keyIndex)}/>
        </Div>
      </RowRight>
    </RowDiv>
  );
};

// styled components for the API Keys table
const RowLeft = DivComponent({
  width: 29,
  borderRight: `1px solid ${colors.lightGreyBorder}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const RowRight = DivComponent({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 12,
  minWidth: 440
});

export default Auth(KeysPage);
