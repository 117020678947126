import config from '../config/config';
import mockAccountManager from './AccountManagerMock';
import mockKeys from './KeysMock';
import mockTeam from './TeamMock';
import { createMediaMappings, mockMediaMapping } from './MediaMappingMock';
import mockMediaOffsetData from './SyncMediaOffsetMock';
import { createVideos } from './VideoMock';
import { MOCK_API_RESPONSE_TIMEOUT } from '../config/TimeoutConfig';

const apiMockConfig = {
  login: false,
  getPublisher: false,
  account: false,
  apiKeys: false,
  team: true,
  media: false
};

// ===================================== responses =============================================

class MockResponses {
  connectToApp(app) {
    this.app = app;
  }

  request(response) {
    return new Promise(resolve => {
      this.app.requestStarted();
      setTimeout(() => {
        this.app.requestDone();
        resolve(response);
      }, MOCK_API_RESPONSE_TIMEOUT);
    });
  }

  async login({ email, password }) {
    let response;
    if (email !== 'abc@123.com') response = { status: 401 };
    if (password !== 'abc123') response = { status: 401 };
    response = {
      status: 200,
      data: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhIjoicG9ydGFsIiwiZGIiOiJkb2N1bWVudCIsInAiOlt7ImFwcCI6InBvcnRhbCIsInRhcmdldCI6IjVhYzZjNTM3Njk4NDIzM2EwNGFjNTNjNyIsInJvbGUiOiJhZG1pbiJ9LHsiYXBwIjoicG9ydGFsIiwidGFyZ2V0IjoiNWFjNmM1Mzc2OTg0MjMzYTA0YWM1M2M3Iiwicm9sZSI6Im93bmVyIn1dLCJkIjp7ImlkIjoiNTdkODQyMjAyMzg5ZjY5ZDFmOWYyYTkyIiwiZmlyc3RuYW1lIjoiVGltIiwic3VybmFtZSI6Ild1IiwiZW1haWwiOiJ0aW1AdGFnZmxpeC5jb20ifSwiaWF0IjoxNTI3MDI4MDkzLCJleHAiOjI1Mjc4OTIwOTN9.Imcb6NJ-jDW15Gn9oOKY5P55N3TNnsGV7QlxYdImetY'
    };
    return await this.request(response);
  }

  async getPublisher() {
    return await this.request({
      status: 200,
      data: {
        publisherName: 'Tagflix Inc.',
        accountManager: mockAccountManager
      }
    });
  }

  async getMaxVideoLabelCount() {
    return await this.request({
      status: 200,
      data: 5
    });
  }

  async getApiKeyCount() {
    return await this.request({
      status: 200,
      data: { activeKeys: 2 }
    });
  }

  async getApiKey(keyIndex) {
    return await this.request({
      status: 200,
      data: { key: mockKeys[keyIndex-1] }
    });
  }

  async deleteApiKey() {
    return await this.request({
      status: 204,
      data: { activeKeys: 2 }
    });
  }

  async getTeam() {
    return await this.request({
      status: 200,
      data: mockTeam
    });
  }

  async inviteTeammate() {
    return await this.request({ status: 204 });
  }

  async acceptInvite() {
    return await this.request({
      status: 200,
      data: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhIjoicG9ydGFsIiwiZGIiOiJkb2N1bWVudCIsInAiOlt7ImFwcCI6InBvcnRhbCIsInRhcmdldCI6IjVhYzZjNTM3Njk4NDIzM2EwNGFjNTNjNyIsInJvbGUiOiJhZG1pbiJ9LHsiYXBwIjoicG9ydGFsIiwidGFyZ2V0IjoiNWFjNmM1Mzc2OTg0MjMzYTA0YWM1M2M3Iiwicm9sZSI6Im93bmVyIn1dLCJkIjp7ImlkIjoiNTdkODQyMjAyMzg5ZjY5ZDFmOWYyYTkyIiwiZmlyc3RuYW1lIjoiVGltIiwic3VybmFtZSI6Ild1IiwiZW1haWwiOiJ0aW1AdGFnZmxpeC5jb20ifSwiaWF0IjoxNTI3MDI4MDkzLCJleHAiOjE1Mjc4OTIwOTN9.wrCEUYgWVaz7ll3B_w5DX2XKRXq7B_2CCTbdQh8s3rI'
    });
  }

  async changeTeammateRole(data) {
    return await this.request({ status: 200, data });
  }

  async removeTeammate() {
    return await this.request({ status: 204 });
  }

  async changePassword() {
    return await this.request({
      status: 200,
      data: {
        _id: '5b05d4f1bcec3ba0c1d64d72',
        email: 'tim@tagflix.com',
        surname: 'Wu',
        firstname: 'Tim',
        permissions: [
          {
            app: 'consumerweb',
            role: 'user'
          },
          {
            app: 'tagging',
            role: 'admin'
          },
          {
            app: 'portal',
            target: '5ac6c5376984233a04ac53c7',
            role: 'owner'
          }
        ]
      }
    });
  }

  async getVideos(numOfVideos) {
    return await this.request({
      status: 200,
      data: createVideos(numOfVideos)
    });
  }

  async getMediaMappings() {
    return await this.request({
      status: 200,
      data: createMediaMappings()
    });
  }

  async searchMedia() {
    return await this.request({
      status: 200,
      data: [
        { media: { name: 'Coldplay - Hymn for the Weekend', id: '72d8ce90-8990-46d2-97e6-a63a975e5167' } },
        { media: { name: 'Coldplay - Him for the Weekend', id: '62d8ce90-8990-46d2-97e6-a63a975e5167' } },
        { media: { name: 'Coldplay - Butts for the Weekend', id: '82d8ce90-8990-46d2-97e6-a63a975e5167' } }
      ]
    });
  }

  async getMediaOffset() {
    return await this.request({
      status: 200,
      data: mockMediaOffsetData
    });
  }

  async updateVideo(data) {
    return await this.request({ status: 200, data });
  }

  async deleteVideo() {
    return this.request({ status: 204 });
  }

  async createMediaMapping() {
    return await this.request({
      status: 200,
      data: mockMediaMapping
    });
  }

  async updateMediaMapping() {
    return await this.request({
      status: 200,
      data: mockMediaMapping
    });
  }

  async deleteMediaMapping() {
    return this.request({ status: 204 });
  }
}

export const mockResponses = new MockResponses;

// ===================================== requests ===============================================

const requestsToMock = () => {
  const requests = { ...apiMockConfig };
  if (!config.mockRequests) for (let i in requests) requests[i] = false;
  return requests;
};

export const mockRequests = requestsToMock();
