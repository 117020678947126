import VideosPage from '../pages/videos/VideosPage';
import MediaMappingPage from '../pages/mediaMapping/MediaMappingPage';
import SettingsPage from '../pages/settings/SettingsPage';
import SupportPage from '../pages/support/SupportPage';
import ReportPage from '../pages/dashboard/ReportPage';
import SettingsRedirectPage from '../pages/settings/SettingsRedirectPage';

const routes = [
  {
    path: '/videos',
    component: VideosPage
  },
  {
    path: '/media-mapping/:id?/:action?',
    component: MediaMappingPage
  },
  {
    path: '/report/:type',
    component: ReportPage
  },
  // {
  //   path: '/history',
  //   component: HistoryPage
  // },
  {
    path: '/settings/:page',
    component: SettingsPage
  },
  {
    path: '/settings',
    component: SettingsRedirectPage,
    exact: true
  },
  // {
  //   path: '/team',
  //   component: SettingsPage,
  // },
  {
    path: '/support',
    component: SupportPage
  }
];

export default routes;
