export default {
  team: [
    {
      role: 'owner',
      firstname: 'Tim',
      surname: 'Wu',
      email: 'tim@tagflix.com',
      id: '57d842202389f69d1f9f2a92'
    },
    {
      role: 'admin',
      firstname: 'Steve',
      surname: 'Malone',
      email: 'steve.m@youtube.com',
      id: '589f69d1f0f2c967d8522023'
    },
    {
      role: 'read only',
      firstname: 'Jamie',
      surname: 'Zarako',
      email: 'jamie.z@youtube.com',
      id: '569d1f0f2c967d852202389f'
    }
  ]
};
