import { colors } from '../../style/Constants';
import { DivComponent, HeaderComponent, ImgComponent, ExtendComponentStyles, PComponent, FooterComponent } from '../../style/Global';

export const InteriorDiv = DivComponent({
  width: '100%',
  height: '100%',
  position: 'relative'
});

export const InteriorHeader = HeaderComponent({
  width: '100%',
  height: 75,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 20,
  overflow: 'hidden',
  boxShadow: '-0.5px 0.5px 1px 0 #c7c7c7'
});

export const HeaderImg = ImgComponent({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '102%',
  objectFit: 'cover',
  zIndex: -1
});

export const LogoIcon = ImgComponent({
  height: 33,
  marginRight: 10
});

export const InteriorBody = DivComponent({
  display: 'flex',
  minHeight: 'calc("100vh - 75px")'
});

export const SectionDiv = DivComponent(({ reportPage }) => ({
  position: 'relative',
  margin: '-55px 20px 55px 0',
  flexGrow: 1,
  minWidth: reportPage ? 758 : 600,
  width: reportPage && '100%',
  maxWidth: reportPage ? null : 1000,
  height: '100%',
  backgroundColor: colors.white,
  boxShadow: '0.5px 0.5px 5px 0.5px rgba(52, 48, 77, 0.2)'
}));

export const SectionHeader = HeaderComponent({
  position: 'relative',
  width: '100%',
  backgroundColor: colors.lightPink,
  overflow: 'hidden'
});

export const SectionBody = DivComponent({ lineHeight: 1.4 });

export const SectionBackdrop = ExtendComponentStyles(HeaderImg)({
  zIndex: 1,
  width: 360,
  left: 'auto',
  right: 0
});

export const SectionText = PComponent({
  paddingTop: 28,
  lineHeight: 1.4
});

export const SectionFooter = FooterComponent({
  position: 'absolute',
  bottom: -27,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between'
});

export const TinyLogo = ImgComponent({ height: 16 });
