import React from 'react';
import { Div, P, DivComponent } from '../../style/Global';
import { Bold } from '../../style/Typography';
import { colors } from '../../style/Constants';
import { observer, inject } from 'mobx-react';
import { formatBytes } from '../../util/UnitConversions';

@inject('video', 'app')
@observer
class StorageCapacity extends React.Component {
  constructor() {
    super();
  }

  render() {
    const { videoStorageCapacityBytes } = this.props.app.publisherVideoAssetsConfig;
    const usedStorageBytes = this.props.video.usedStorageBytes;
    const percentUsed = (usedStorageBytes / videoStorageCapacityBytes ) * 100;
    if(usedStorageBytes > videoStorageCapacityBytes) return <StorageCapacityExceededBar usedStorageBytes={usedStorageBytes} videoStorageCapacityBytes={videoStorageCapacityBytes}/>;
    return <StorageCapacityBar percentUsed={percentUsed} usedStorageBytes={usedStorageBytes} videoStorageCapacityBytes={videoStorageCapacityBytes}/>;
  }
}

const StorageCapacityBar = ({ percentUsed, usedStorageBytes, videoStorageCapacityBytes }) => (
  <Container>
    <Header usedStorageBytes={usedStorageBytes} videoStorageCapacityBytes={videoStorageCapacityBytes} color={colors.darkGreyText} />
    <Div
      width='300px'
      height='6px'
      backgroundImage={`linear-gradient(to right, #d8d8d8, #d8d8d8 ${percentUsed}%, rgba(0,0,0,0) ${percentUsed}%, rgba(0,0,0,0))`}
      border={`1px solid ${colors.lightGreyText}`}
    />
  </Container>
);

const StorageCapacityExceededBar = ({ usedStorageBytes, videoStorageCapacityBytes }) => (
  <Container>
    <Header usedStorageBytes={usedStorageBytes} videoStorageCapacityBytes={videoStorageCapacityBytes} color={colors.warningRed} />
    <Div display='flex' flexDirection='row' alignItems='center'>
      <Div width='276px' height='6px' backgroundColor='#d8d8d8' border={`solid 1px ${colors.warningRed}`} />
      <Div width='22px' height='4px' backgroundColor={colors.warningRed} />
      <Div width='2px' height='6px' backgroundColor={colors.warningRed} />
    </Div>
  </Container>
);

const Header = ({ usedStorageBytes, videoStorageCapacityBytes, color }) => (
  <P
    fontSize='11px'
    textAlign='right'
    marginBottom='4px'
    color={color}
  >
      Using <Bold>{formatBytes(usedStorageBytes)}</Bold> of {formatBytes(videoStorageCapacityBytes)}
  </P>
);

const Container = DivComponent({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  marginBottom: '3px'
});

export default StorageCapacity;
