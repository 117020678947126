export const colors = {
  red: '#ee0d4e',
  warningRed: '#d0021b',
  lightPink: '#fef3f6',
  mediumPink: 'rgba(238, 13, 78, 0.1)',
  darkPink: '#f686a6',
  deletedPink: '#fde6ed',
  green: '#b8e986',
  white: '#ffffff',
  lightGreen: '#ddffbb',
  superLightGrey: '#fafafa',
  lightGrey: '#f2f2f2',
  grey: '#979797',
  borderGrey: '#d2d2d2',
  superLightGreyText: '#eeeeee',
  lightGreyText: '#cacaca',
  mediumGreyText: '#8483a0',
  greyText: '#9b9b9b',
  darkGreyText: '#4a4a4a',
  lightGreyBorder: '#e0e0e0',
  lightSage: '#b8e986',
  lightBeige: '#fcf7ba',
  darkGrassGreen: '#417505'
};

export const fontSizes = {
  h1: '24px',
  h2: '18px',
  h3: '16px',
  h4: '15px',
  p: '14px',
  small: '12px',
  tiny: '10px',
  superTiny: '8px'
};

export const fontWeight = {
  light: 300,
  semiBold: 600
};

export const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export const flexSpaceBetween = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};
